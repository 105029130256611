import { styled, alpha } from '@mui/material/styles';
import { Card, Hidden, Container, Box, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';

import Page from '../../components/Page';
import Logo from '../../components/Logo';

const RootStyle = styled(Page)(({ theme }) => ({
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "100vh",
  overflow: "scroll",
  backgroundImage: `linear-gradient(180deg, ${theme.palette.common.white}, ${theme.palette.common.white} 30%, ${alpha(theme.palette.common.white, 0.3)}),url(/static/landing_hero_small_bg.jpg)`,
  [theme.breakpoints.up('md')]: {
    backgroundImage: `linear-gradient(180deg, ${theme.palette.common.white}, ${theme.palette.common.white} 30%, ${alpha(theme.palette.common.white, 0.3)}),url(/static/landing_hero_big_bg.jpg)`,
  },
  [theme.breakpoints.down('sm')]: {
    backgroundImage: `none`,
  },
}));

const ContentStyle = styled('div')({
  maxWidth: 480,
  width: "100%",
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginBottom: 40
});

export default function AuthLayout({ 
  children, 
  pageTitle, 
  navBarLeftSide = null
 }) {
  return (
    <RootStyle title={pageTitle}>
      <Container>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pt={3}
          pb={{ xs: 10, sm: 7, md: 7 }}
        >
          <Box ml={-1.5}>
            <Link to="/">
              <Logo />
            </Link>
          </Box>
          {navBarLeftSide}
        </Box>
      </Container>
      <Hidden smUp>
        <Container maxWidth="sm">
          <ContentStyle>{children}</ContentStyle>
        </Container>
      </Hidden>
      <Hidden smDown>
        <ContentStyle>
          <Card sx={{ boxShadow: theme => theme.customShadows.z16 }}>
            <CardContent>
              {children}
            </CardContent>
          </Card>
        </ContentStyle>
      </Hidden>
    </RootStyle>
  );
}
