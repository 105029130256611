import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useDropzone } from 'react-dropzone';
import axios from "axios";
import { useCallback, useState } from "react";
import roundAddAPhoto from '@iconify/icons-ic/round-add-a-photo';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Typography, Paper, CircularProgress } from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { cloudinaryConfig } from "../../config";

const CLOUDINARY_KEY = cloudinaryConfig.cloudinaryKey;
const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/my247/upload`;

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  width: 144,
  height: 144,
  margin: 'auto',
  borderRadius: '50%',
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`
}));

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9
    }
  }
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&:hover': { opacity: 0.72 }
}));

const LoadingStyle = styled('div')(({ theme }) => ({
  zIndex: 99,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.grey[900], 0.72)
}));

// ----------------------------------------------------------------------

UploadAvatar.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sx: PropTypes.object
};

export default function UploadAvatar({ error, file, setPhotoValue, onUploadSuccessFul, uploadPreset, tags, sx, ...other }) {
  const isMountedRef = useIsMountedRef();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [networkError, setNetworkError] = useState([]);


  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {

        setPhotoValue(URL.createObjectURL(file));
        try {
          setUploadProgress(0);
          setNetworkError([]);
          setIsUploading(true);
          const formData = new FormData();
          formData.append('file', file);
          formData.append('upload_preset', uploadPreset);
          tags.forEach(element => {
            formData.append('tags', element)
          });
          formData.append('api_key', CLOUDINARY_KEY);
          axios
            .post(CLOUDINARY_URL, formData, {
              headers: { 'X-Requested-With': 'XMLHttpRequest' },
              onUploadProgress: event => {
                const progressRatio = event.loaded / file.size;
                setUploadProgress(progressRatio * 100)
              }
            })
            .then((response) => {
              const { data } = response;
              if (isMountedRef.current) {
                onUploadSuccessFul(data);
                setIsUploading(false);
              }
            });
        } catch (error) {
          setNetworkError([{
            file,
            errors: [{
              code: "network",
              message: error.message
            }]
          }])
        }
      }
    },

    // eslint-disable-next-line
    [setPhotoValue]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    onDrop: handleDrop,
    accept: "image/*",
    maxSize: 3145728,
    ...other
  });

  const errorArrays = [...networkError, ...fileRejections];


  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        my: 2,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {errorArrays.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.alias} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <>
      <RootStyle sx={sx}>
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter'
            })
          }}
        >
          <input {...getInputProps()} />

          {file && (
            <Box
              component="img"
              alt="avatar"
              src={isString(file) ? file : file.preview}
              sx={{ zIndex: 8, objectFit: 'cover' }}
            />
          )}

          {isUploading && (
            <LoadingStyle>
              <Box position="relative" display="inline-flex">
                <CircularProgress
                  variant={uploadProgress === 0 ? "indeterminate" : "determinate"}
                  value={uploadProgress}
                  size={120}
                  thickness={1}
                  sx={{ color: theme => theme.palette.warning.lighter }} />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ color: theme => theme.palette.warning.lighter }} >
                    {`${Math.round(uploadProgress >= 100 ? 100 : uploadProgress)}%`}
                  </Typography>
                </Box>
              </Box>
            </LoadingStyle>
          )}

          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...(file && {
                opacity: 0,
                color: 'common.white',
                bgcolor: 'grey.900',
                '&:hover': { opacity: 0.72 }
              })
            }}
          >
            <Box component={Icon} icon={roundAddAPhoto} sx={{ width: 24, height: 24, mb: 1 }} />
            <Typography variant="caption">{file ? 'Update photo' : 'Upload photo'}</Typography>
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>

      <Typography
        variant="caption"
        sx={{
          mt: 2,
          mx: 'auto',
          display: 'block',
          textAlign: 'center',
          color: 'text.secondary'
        }}
      >Allowed *.jpeg, *.jpg, *.png, *.gif
        <br /> max size of {fData(3145728)}
      </Typography>

      {errorArrays.length > 0 && <ShowRejectionItems />}
    </>
  );
}
