import alertCircleFill from '@iconify/icons-eva/alert-circle-outline';
import { useRef, useState } from 'react';
import {
    Box,
    Typography,
    Container,
    Skeleton,
    Stack,
    AvatarGroup,
    Avatar,
    Collapse,
    CircularProgress,
    Link,
    Button
} from '@mui/material';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, useParams, useLocation } from 'react-router-dom';

import { Roles, PlatformUserStatus } from '../../../utils/constant';
import useCurrentPlatformDetails from '../../../hooks/useCurrentPlatformDetails';
import Logo from '../../../components/Logo';
import DownloadApp from '../../../components/DownloadApp';

export default function UnauthorizedLandingPage() {
    const { platform, platformLoading } = useCurrentPlatformDetails();
    const userPlatformData = platform?.community_user;
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { communityCode } = useParams();

    if (platformLoading) {
        return (
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}><CircularProgress /></Box>
        )
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Box sx={{
                backgroundSize: 'cover',
                backgroundPosition: "center",
                overflow: "scroll",
                height: "100vh",
                // display: "flex",
                alignItems: "center",
                backgroundImage: `url(/static/overlay.svg), url(${platform?.banner?.resize_url || platform?.logo?.resize_url || '/static/landing_hero_big_bg.jpg'})`
            }}>
                <Container sx={{ py: 5 }}>
                    <RouterLink to="/">
                        <Logo height={40} />
                    </RouterLink>
                </Container >
                <Box sx={{ mt: 10 }}>
                    <Container >
                        {platformLoading ? (
                            <Box sx={{ opacity: 0.2 }} display="flex" flexDirection={{ xs: "column", md: "row" }}>
                                <Box>
                                    <Skeleton animation="wave" variant="circular" width={70} height={70} />
                                </Box>
                                <Stack
                                    spacing={1}
                                    sx={{
                                        width: "100%",
                                        ml: { xs: 0, md: 2 },
                                        mt: { xs: 3, md: 0 }
                                    }}>
                                    <Skeleton animation="wave" height={20} width="40%" />
                                    <Skeleton animation="wave" height={20} />
                                    <Skeleton animation="wave" height={20} width="80%" />
                                </Stack>
                            </Box>
                        ) : (
                            <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
                                <Box>
                                    <Typography variant="h2" color="white">
                                        {platform.name}
                                    </Typography>
                                    <Typography variant="body2" color='grey.500'>
                                        @{platform.alias}
                                    </Typography>
                                    <Typography variant="body1" color='grey.300' sx={{ mt: 5, maxWidth: 540 }}>
                                        {platform.description}
                                    </Typography>
                                    <Box display="flex" alignItems="center" mt={3}>
                                        <AvatarGroup sx={{ justifyContent: "start", mr: 2 }} max={50}>
                                            {platform.users_banners?.map((avatar) => (
                                                <Avatar key={avatar.id} src={avatar.display_image.resize_url} sx={{ height: 24, width: 24, color: "white" }}>OT</Avatar>
                                            ))}
                                        </AvatarGroup>
                                        <Typography variant="body1" color="grey.500">{platform.active_member} member{platform.active_member > 1 ? `s` : ``}</Typography>
                                    </Box>
                                    <Box sx={{ mt: 5 }} display="flex" alignItems="center">
                                        <Button
                                            size="large"
                                            variant="contained"
                                            ref={anchorRef}
                                            onClick={handleOpen}
                                            // component={Link}
                                            // href={platform.public_link}
                                            sx={{
                                                color: 'common.black',
                                                bgcolor: 'common.white',
                                                width: "max-content",
                                                "&:hover": {
                                                    bgcolor: 'rgba(255,255,255, .8)'
                                                },
                                            }}
                                        >
                                            Open in App
                                        </Button>
                                        {/* <AccessControl permitedRoles={[Roles.SETTLEMENT_ADMIN]} hide>
                                            <Button
                                                size="large"
                                                variant="contained"
                                                component={Link}
                                                href={`${ROOTS_COMMUNITY}/${platform.id}/admin`}
                                                sx={{
                                                    color: 'common.white',
                                                    bgcolor: 'common.black',
                                                    width: "max-content",
                                                    ml: 3,
                                                    "&:hover": {
                                                        bgcolor: 'rgba(0,0,0, .8)'
                                                    },
                                                }}
                                            >
                                                Admin Dashboard
                                            </Button>
                                        </AccessControl> */}
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Container>
                </Box>
            </Box>
            <Collapse
                in={userPlatformData?.status === PlatformUserStatus.PENDING}
            >
                <Box sx={{
                    color: 'info.darker',
                    backgroundColor: 'info.lighter',
                    py: 2
                }}>
                    <Container>
                        <Box display="flex" alignItems="center">
                            <Icon icon={alertCircleFill} />
                            <Typography
                                variant="body2"
                                sx={{ px: 2, flex: 1 }}
                            >{userPlatformData?.invited_by?.id
                                ? `You have a pending invite from ${userPlatformData?.invited_by?.firstname} ${userPlatformData?.invited_by?.lastname}. Click "Accept Invite" to continue`
                                : "Your request is pending. An admin will have to approve your request"}
                            </Typography>
                        </Box>
                    </Container>
                </Box>
            </Collapse>
            <DownloadApp
                open={open}
                onClose={handleClose}
                urlScheme={userPlatformData?.status === PlatformUserStatus.ACTIVE
                    ? `communely://community/${communityCode}/community`
                    : `communely://community/${communityCode}`}
                ref={anchorRef}
            />
        </ >
    );
}
