import { useForm, Controller } from 'react-hook-form';
// material
import {
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Select,
  MenuItem,
  Alert,
  IconButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';

import { DialogAnimate } from '../animate';
import useZones from '../../hooks/useZones';
import { updatePlatform as updatePlatformApi } from '../../services/platform';
import { createStreet as createStreetApi, updateStreet as updateStreetApi, getStreetById } from '../../services/zones';
import useCurrentPlatformDetails from '../../hooks/useCurrentPlatformDetails';
import { RegistrationStages } from '../../utils/constant';

export default function UpsertStreetModal({
  open,
  onClose,
  defaultSelectZone,
  streetId,
  onSuccess
}) {
  const isEditMode = typeof streetId === "string";
  const defaultValues = {
    zone_id: defaultSelectZone || '',
    name: '',
    description: ''
  };

  const { refetchPlatform, platform } = useCurrentPlatformDetails();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { handleSubmit, control, reset } = useForm({
    defaultValues
  });
  const { zones } = useZones();

  useQuery({
    queryKey: ['street', { streetId }],
    enabled: isEditMode,
    queryFn: getStreetById,
    onSuccess: data => {
      const { name, description } = data.data.data;
      reset({
        ...defaultValues,
        name, description
      });
    }
  });

  const {
    mutate: updatePlatformMutation,
    isLoading: updateIsLoading
  } = useMutation(updatePlatformApi, {
    onSuccess: () => {
      handleCloseForm();
      refetchPlatform();
    },
    onError: error => {
      enqueueSnackbar(error.message, {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });

    }
  });

  const mutationConfig = {
    onSuccess: () => {
      if (
        !platform.prerequisite_stages?.includes(RegistrationStages.MANAGEMENT)
      ) {
        updatePlatformStage();
      } else {
        handleCloseForm();
      };
      enqueueSnackbar(`Street was ${isEditMode ? "updated" : "created"} successfully`, {
        variant: 'success',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
      onSuccess?.();
    }
  };

  const { mutate: streetMutation, ...streetMutationFeedback } = useMutation(
    createStreetApi, mutationConfig
  );

  const { mutate: updateStreetMutation, ...updateStreetMutationFeedback } = useMutation(
    updateStreetApi, mutationConfig
  );

  const isLoading = streetMutationFeedback.isLoading || updateStreetMutationFeedback.isLoading;
  const error = streetMutationFeedback.error || updateStreetMutationFeedback.error;
  const isError = streetMutationFeedback.isError || updateStreetMutationFeedback.isError;

  const updatePlatformStage = () => {
    const updateData = {
      prerequisite_stages: [
        ...(platform.prerequisite_stages || []),
        RegistrationStages.MANAGEMENT
      ]
    };
    updatePlatformMutation({
      platformId: platform.id,
      data: updateData
    });
  };

  const handleCloseForm = () => {
    onClose();
    reset(defaultValues);
  };

  const onSubmit = (values) => {
    if (isEditMode) {
      updateStreetMutation({ ...values, streetId });
      return;
    }
    streetMutation(values);
  };

  return (
    <DialogAnimate open={open} onClose={onClose}>
      <DialogTitle>{isEditMode ? "Edit" : "Add"} Street</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ pb: 0, overflowY: 'unset' }}>
          {isError && (
            <Alert severity="error" sx={{ mb: 5 }}>
                   <strong>{error?.message}</strong>
                    {(typeof error.details === "object") && Object.keys(error.details).map((el, i) => <p key={i}>{el}{" "}{error.details[el]}</p>)}
            </Alert>
          )}
          <Controller
            name="zone_id"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Zone is required'
              }
            }}
            render={({ field: { ...fields } }) => (
              <Select
                {...fields}
                displayEmpty
                sx={{ mb: 3 }}
                fullWidth
                required
                placeholder="Select zone"
                inputProps={{ 'aria-label': 'Select' }}
              >
                <MenuItem value="" disabled>
                  Select zone
                </MenuItem>
                {zones.map((zone) => (
                  <MenuItem key={zone.id} value={zone.id}>
                    {zone.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Controller
            name="name"
            control={control}
            rules={{
              required: { value: true, message: 'Street name is required' }
            }}
            render={({ field: { ref, ...fields }, fieldState: { error } }) => (
              <TextField
                {...fields}
                label="Street"
                variant="outlined"
                required
                fullWidth
                inputRef={ref}
                sx={{ mb: 3 }}
                error={Boolean(error?.message)}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{
              required: { value: true, message: 'Description is required' }
            }}
            render={({ field: { ref, ...fields }, fieldState: { error } }) => (
              <TextField
                {...fields}
                label="Describe this street"
                variant="outlined"
                required
                multiline
                rows={4}
                fullWidth
                inputRef={ref}
                error={Boolean(error?.message)}
                helperText={error?.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="outlined"
            color="inherit"
            onClick={handleCloseForm}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isLoading || updateIsLoading}
          >
            {isEditMode ? "Update" : "Add"}
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogAnimate >
  );
}
