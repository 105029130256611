import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function revertCurrencyValToNum(value = "0") {
  const numValue = value.toString().split(",").join("");
  return Number(numValue);
};

export function formatCurrencyInput(value) {
  // Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return Number(value).toLocaleString()
}
