import { useEffect } from "react";
import { Controller, useForm } from 'react-hook-form';
import {
    IconButton,
    Switch,
    Typography, Box
} from '@mui/material';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';

import useCurrentPlatformDetails from "../../../hooks/useCurrentPlatformDetails";
import CreateWrapper from "../../create/CreateWrapper";
import AssetUpload from "../../create/AssetUpload";
import useUpsertPlatform from "../../../hooks/useUpsertPlatform";
import { getPlatformFormResetFields } from "../../../utils/createFormUtils";
import { PlatformStatus, defaultPlatformFormValues } from "../../../utils/constant";

export default function AdditionalInformation() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { refetchPlatform } = useCurrentPlatformDetails();
    const {
        onUpsertPlatform,
        platform,
        upsertPlatformError,
        upsertPlatformLoading
    } = useUpsertPlatform({
        onSuccess: () => {
            refetchPlatform();
            enqueueSnackbar('Platform was updated successfully', {
                variant: 'success',
                action: (key) => (
                    <IconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </IconButton>
                )
            });
        }
    });
    const useFormUtils = useForm({
        defaultValues: defaultPlatformFormValues
    });

    const { handleSubmit, reset, control } = useFormUtils;

    useEffect(() => {
        reset(getPlatformFormResetFields(platform))
    }, [platform?.alias]);


    const onSubmit = (values) => {
        onUpsertPlatform(values);
    };

    return (
        <CreateWrapper
            error={upsertPlatformError}
            isLoading={upsertPlatformLoading}
            noBackBtn
            actionBtnText="Save"
            actionBtnAction={handleSubmit(onSubmit)}>
            <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                <Typography>Community active</Typography>
                <Controller
                    name="status"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Switch
                            checked={value === PlatformStatus.ACTIVE}
                            onChange={event => {
                                onChange(
                                    value === PlatformStatus.ACTIVE
                                        ? PlatformStatus.IN_ACTIVE
                                        : PlatformStatus.ACTIVE
                                )
                            }}
                        />
                    )}
                />
            </Box>
            <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                <Typography>Promotion enabled</Typography>
                <Controller
                    name="promotion"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Switch
                            checked={value === "allow"}
                            onChange={event => {
                                onChange(
                                    value === "allow"
                                        ? "disallow"
                                        : "allow"
                                )
                            }}
                        />
                    )}
                />
            </Box>
            <AssetUpload
                name="display_image"
                errorMessage="Display image is required"
                label="Change display image"
                useFormUtils={useFormUtils}
            />
        </CreateWrapper>
    );
}
