/* eslint-disable */
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
// material
import { useTheme } from '@mui/material/styles';
import { Card, Typography, Stack, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
import { BaseOptionChart } from '../../charts';
import useCurrentPlatformDetails from '../../../hooks/useCurrentPlatformDetails';
import { getResidentOverview } from '../../../services/dashboard';
import { Roles } from '../../../utils/constant';

export default function BusinessWidgets() {
  const theme = useTheme();
  const { platform } = useCurrentPlatformDetails();
  const { enqueueSnackbar } = useSnackbar();

  const CHART_SIZE = { width: 86, height: 86 };

  const { data: { data: residentsData } = {}, isLoading: residentsIsloading } = useQuery({
    queryKey: ['residentsOverview', { settlement_id: platform.id, role: [Roles.SETTLEMENT_DWELLER] }],
    enabled: !!platform.id,
    queryFn: getResidentOverview,
    onError: () => {
      enqueueSnackbar('Unable to fetch residents overview', {
        variant: 'error',
      });
    }
  });
  const LABEL = ['Pending', 'In-active', 'Active'];

  const residentsBreakdownByStatus = residentsData?.data?.breakdown_by_status || {};

  const { active, pending, in_active } = residentsBreakdownByStatus;

  const reducer = (accumulator, currentValue) => Number(accumulator) + Number(currentValue);
  const sum = Object.values(residentsBreakdownByStatus)?.reduce(reducer, 0);
  const getPercent = val => ((val / sum) * 100);

  const RESIDENTS_BY_STATUS = [
    {
      status: LABEL[2],
      quantity: active,
      color: "success",
      value: getPercent(active)
    },
    {
      status: LABEL[0],
      quantity: pending,
      color: "warning",
      value: getPercent(pending)
    },
    {
      status: LABEL[1],
      quantity: in_active,
      color: "error",
      value: getPercent(in_active)
    },
  ];



  const chartOptionsCheckIn = merge(BaseOptionChart(), {
    chart: { sparkline: { enabled: true } },
    grid: {
      padding: {
        top: -9,
        bottom: -9
      }
    },
    legend: { show: false },
    plotOptions: {
      radialBar: {
        hollow: { size: '70%' },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: theme.typography.subtitle2.fontSize
          }
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title="Members" />
      <Stack direction={{ xs: 'column', sm: 'row' }} p={2}>
        {RESIDENTS_BY_STATUS.map((el, index) => (
          <Stack key={index} direction="row" alignItems="center" spacing={1} sx={{ width: 1, py: 3 }}>
            <ReactApexChart
              type="radialBar"
              series={[!!el.value ? el.value.toFixed(1) : 0]}
              options={{
                ...chartOptionsCheckIn,
                colors: [theme.palette[el.color].light]
              }}
              {...CHART_SIZE} />
            <div>
              <Typography variant="h6" >
                {fNumber(el.quantity)}
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.72 }}>
                {el.status}
              </Typography>
            </div>
          </Stack>
        ))}

        {/* <Stack direction="row" alignItems="center" spacing={1} sx={{ width: 1, py: 3 }}>
          <ReactApexChart
            type="radialBar"
            series={CHART_DATA_CHECK_OUT}
            options={chartOptionsCheckOut}
            {...CHART_SIZE}
          />
          <div>
            <Typography variant="h6" >
              {fNumber(TOTAL_CHECK_OUT)}
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.72 }}>
              Pending
            </Typography>
          </div>
        </Stack> */}


        {/* <Stack direction="row" alignItems="center" spacing={1} sx={{ width: 1, py: 3 }}>
          <ReactApexChart
            type="radialBar"
            series={CHART_DATA_CHECK_OUT}
            options={chartOptionsCheckOut}
            {...CHART_SIZE}
          />
          <div>
            <Typography variant="h6" >
              {fNumber(TOTAL_CHECK_OUT)}
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.72 }}>
              In active
            </Typography>
          </div>
        </Stack> */}
      </Stack>
    </Card>
  );
}
