// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function Logo({ color = "blue", height, ...other }) {
  return (
    <Box
      component="img"
      alt="logo"
      src={`/static/brand/logo_single_${color}.svg`}
      height={height || 54}
      {...other}
    />
  );
}
