/* eslint-disable camelcase */
import { banks } from "../hooks/useBanks";
import { defaultPlatformFormValues, defaultBusinessFormValues, defaultOfferFormValues } from "./constant";

export const getBusinessFormResetFields = (business = {}) => {
  const {
    name,
    description,
    phone_number,
    whatsapp_number,
    state,
    lga,
    email,
    type,
    display_image,
    bank_information,
    address,
    logo,
    category
  } = business;
  const bank = banks.find((bank) => bank.name === bank_information?.bank_name);
  return {
    ...defaultBusinessFormValues,
    address,
    phone_number,
    whatsapp_number,
    description,
    display_image,
    name,
    logo,
    lga,
    state,
    bank,
    type,
    email,
    category,
    account_number: bank_information?.account_number,
    account_holder: bank_information?.account_holder
  };
};

export const getPlatformFormResetFields = (platform = {}) => {
  const {
    name,
    description,
    phone_number,
    state,
    lga,
    alias,
    // bank_information,
    address,
    promotion,
    email,
    status
  } = platform;
  // const bank = banks.find((bank) => bank.name === bank_information?.bank_name);
  return {
    ...defaultPlatformFormValues,
    address,
    phone_number,
    description,
    email,
    promotion,
    name,
    lga,
    state,
    status,
    // bank,
    alias,
    // account_number: bank_information?.account_number,
    // account_holder: bank_information?.account_holder
  };
};

export const getOfferFormResetFields = (offer = {}) => {
  const {
    name,
    description,
    price,
    duration,
    display_image,
  } = offer;
  return {
    ...defaultOfferFormValues,
    description,
    display_image,
    name,
    price,
    duration
  };
};
