import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// material
import { Box, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: { padding: theme.spacing(5, 5, 0) }
}));

// ----------------------------------------------------------------------

export default function Page500({ title, desc, img }) {
  return (
    <RootStyle title="Error | Communely">
      <HeaderStyle>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </HeaderStyle>

      <Container>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <Typography variant="h3" gutterBottom>
            {title || "500 Internal Server Error"}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {desc || "There was an error, please try again later."}
          </Typography>

          <Box
            component="img"
            alt="500"
            src={img || "/static/illustrations/illustration_500.svg"}
            sx={{ width: '100%', maxHeight: 200, my: { xs: 5, sm: 10 } }}
          />

          {/* <Button
            to="/"
            size="large"
            variant="contained"
            component={RouterLink}
          >
            Go to Home
          </Button> */}
        </Box>
      </Container>
    </RootStyle>
  );
}
