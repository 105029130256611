/* eslint-disable no-empty-pattern */
import { useQuery } from 'react-query';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import { IconButton } from '@mui/material';

import { getBusinessAdmins } from '../services/business';

export default function useFetchBusinessAdmins(queryParams = {}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { data: { data } = {}, isLoading, refetch } = useQuery({
    queryKey: ['business_admins', { ...queryParams }],
    queryFn: getBusinessAdmins,
    onError: () => {
      enqueueSnackbar('Unable to fetch business admins', {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    }
  });

  const { data: admins, ...pagination } = data || {};

  return {
    admins: admins || [],
    adminsPagination: pagination,
    adminsLoading: isLoading,
    refetchAdmins: refetch
  };
}
