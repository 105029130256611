import { useForm, Controller } from 'react-hook-form';
// material
import {
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';

import { DialogAnimate } from '../animate';
import { ActivitiesScope, ActivitiesType, Roles } from '../../utils/constant';
import useCurrentPlatformDetails from '../../hooks/useCurrentPlatformDetails';
import { registerPlatformUser as registerPlatformUserApi } from '../../services/platform';
import userCurrentPlatformDetails from '../../hooks/useCurrentPlatformUserDetails';
import useUpsertActivity from '../../hooks/useUpsertActivity';

export default function AddPersonelModal({
  open,
  onClose,
  onSuccess,
  role,
  title,
  activityType
}) {
  const defaultValues = { email: '', role: Roles.SETTLEMENT_DWELLER };
  const { handleSubmit, control, reset } = useForm({ defaultValues }); 
  const { platform } = useCurrentPlatformDetails();

  const handleCloseForm = () => {
    onClose();
  };

  const {
    mutate: registerPlatformUserMutation,
    isLoading: registerPlatformUserIsLoading,
    error: registerPlatformUserError,
    isError: registerPlatformUserIsError
  } = useMutation(registerPlatformUserApi, {
    onSuccess: (data) => {
      const payload = data.data.data;
      handleCloseForm();
      reset(defaultValues);
      onSuccess?.();
    }
  });

  const onSubmit = (values) => {
    registerPlatformUserMutation({
      ...values,
      email: values.email.toLowerCase(),
      id: platform.id
    });
  };

  return (
    <DialogAnimate open={open} onClose={onClose}>
      <DialogTitle>{title || "Add Admin"}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ pb: 0, overflowY: 'unset' }}>
          {registerPlatformUserIsError && (
            <Alert severity="error" sx={{ mb: 5 }}>
              <strong>{registerPlatformUserError?.message}</strong>
              {(typeof registerPlatformUserError.details === "object") && Object.keys(registerPlatformUserError.details).map((el, i) => <p key={i}>{el}{" "}{registerPlatformUserError.details[el]}</p>)}
            </Alert>
          )}

          <Box mb={3}>
            <Typography variant="body2" sx={{ mb: 2 }}>Choose role</Typography>
            <Controller
              name="role"
              control={control}
              rules={{
                required: 'Role is required'
              }}
              render={({ field: { value, onChange } }) => (
                <ToggleButtonGroup
                  color="primary"
                  value={value}
                  exclusive
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                >
                  <ToggleButton color="primary" value={Roles.SETTLEMENT_DWELLER}>Member</ToggleButton>
                  <ToggleButton color="primary" value={Roles.SETTLEMENT_ADMIN}>Admin</ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          </Box>
          <Controller
            name="email"
            control={control}
            rules={{
              required: { value: true, message: 'Email is required' },
              pattern: {
                value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: 'Provide a valid email address'
              }
            }}
            render={({ field: { ref, ...fields }, fieldState: { error } }) => (
              <TextField
                {...fields}
                fullWidth
                label="Email"
                inputRef={ref}
                sx={{ mb: 3 }}
                error={Boolean(error?.message)}
                helperText={error?.message}
              />
            )}
          />

        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="outlined"
            color="inherit"
            size="large"
            onClick={handleCloseForm}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            loading={registerPlatformUserIsLoading}
          >
            Invite
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogAnimate >
  );
}
