import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {
  useLocation,
  matchPath,
  useParams,
  Link,
} from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
// material
import {
  Box,
  List,
  Drawer,
  Hidden,
  Typography,
  useTheme
} from '@mui/material';
// routes
import MyAvatar from '../../../components/MyAvatar';
import Scrollbar from '../../../components/Scrollbar';
import MenuLinks from './SidebarConfig';
import SidebarItem from './SidebarItem';
import useCurrentPlatformDetails from '../../../hooks/useCurrentPlatformDetails';
import { ROOTS_COMMUNITY } from '../../../routes/paths';

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { communityCode } = useParams();
  const { platform } = useCurrentPlatformDetails();
  const userPlatformData = platform.community_user; // userCurrentPlatformDetails();
  const theme = useTheme();

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderNavigateToMember = (
    <Link
      to={`${ROOTS_COMMUNITY}/${communityCode}/member/overview`}
      style={{ textDecoration: "none" }}>
      <Box
        sx={{
          borderTop: `1px solid ${theme.palette.action.selected}`,
          px: 3,
          py: 2,
          textAlign: "center",
          bgcolor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          )
        }}>
        <Typography variant="body2" sx={{ color: 'primary.main', cursor: "pointer" }}>
          Community landing page
        </Typography>
      </Box>
    </Link>
  );

  const renderEstateName = (
    <Link style={{color: "inherit", textDecoration: "none"}} to={`${ROOTS_COMMUNITY}/${platform.id}`}>
      <AccountStyle>
        <MyAvatar photoURL={platform.display_image} alt={platform.name} sx={{ bgcolor: "warning.main" }} />
        <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle1" sx={{ color: 'text.primary', maxWidth: "120px", mb: -1 }} noWrap>
            {platform.name}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            @{platform.alias}
          </Typography>
        </Box>
      </AccountStyle>
    </Link>
  )

  const renderContent = (
    <Scrollbar>
      {MenuLinks(communityCode, userPlatformData?.role).map((list, index) => (
        <List disablePadding key={index}>
          {renderSidebarItems({
            items: list.items,
            pathname
          })}
        </List>
      ))}
      <Box sx={{ height: 100 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderEstateName}
          {renderContent}
          {renderNavigateToMember}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderEstateName}
          {renderContent}
          {renderNavigateToMember}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
  alignItems: "center",
}));

// ----------------------------------------------------------------------

function reduceChild({ array, item, pathname, level }) {
  const key = item.href + level;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    return [
      ...array,
      <SidebarItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        accessNavBar={item.accessNavBar}
        open={Boolean(match)}
      >
        {renderSidebarItems({
          pathname,
          level: level + 1,
          items: item.items
        })}
      </SidebarItem>
    ];
  }
  return [
    ...array,
    <SidebarItem
      key={key}
      level={level}
      href={item.href}
      icon={item.icon}
      info={item.info}
      title={item.title}
      accessNavBar={item.accessNavBar}
      label={item.label}
    />
  ];
}

function renderSidebarItems({ items, pathname, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChild({ array, item, pathname, level }),
        []
      )}
    </List>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};
