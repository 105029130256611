import { v4 as uuid } from 'uuid';

export const fieldTypes = Object.freeze({
  SHORT_ANSWER: 'short_answer',
  LONG_ANSWER: 'long_answer',
  SINGLE_ANSWER: 'single_option',
  MULTIPLE_ANSWER: 'multiple_options',
});

export const fieldLabels = {
  [fieldTypes.SHORT_ANSWER]: 'Short answer',
  [fieldTypes.LONG_ANSWER]: 'Long answer',
  [fieldTypes.SINGLE_ANSWER]: 'Single option',
  [fieldTypes.MULTIPLE_ANSWER]: 'Multiple options',
};

const options = [{ label: 'Option 1' }, { label: 'Option 2' }, { label: 'Option 3' }];

const createConfig = (type, specialConfig = {}) => ({
  type,
  key: uuid(),
  label: fieldLabels[type],
  required: false,
  ...specialConfig
});

export const fieldConfigs = [
  createConfig(fieldTypes.SHORT_ANSWER),
  createConfig(fieldTypes.LONG_ANSWER),
  createConfig(fieldTypes.SINGLE_ANSWER, { options }),
  createConfig(fieldTypes.MULTIPLE_ANSWER, { options }),
];
