/* eslint-disable */
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import {
  useTheme,
  styled
} from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import { useQuery } from 'react-query';

import { Card, Typography, Box, Stack, CardContent, CardHeader, IconButton } from '@mui/material';
// utils
import { fNumber } from '../../utils/formatNumber';
import { BaseOptionChart } from '../charts';
import useCurrentPlatformDetails from '../../hooks/useCurrentPlatformDetails';
import { getResidentOverview } from '../../services/dashboard';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)({
  height: "100%"
});

export default function ResidentsWidgets1() {
  const theme = useTheme();
  const { platform } = useCurrentPlatformDetails();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { data: { data: residentsData } = {}, isLoading: residentsIsloading } = useQuery({
    queryKey: ['residentsOverview', { settlement_id: platform.id }],
    enabled: !!platform.id,
    queryFn: getResidentOverview,
    onError: () => {
      enqueueSnackbar('Unable to fetch residents overview', {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    }
  });

  const residentsBreakdownByStatus = residentsData?.data?.breakdown_by_status || {};

  const { active, pending, in_active } = residentsBreakdownByStatus;
  const LABEL = ['Pending', 'In-active', 'Active'];

  const reducer = (accumulator, currentValue) => Number(accumulator) + Number(currentValue);
  const sum = Object.values(residentsBreakdownByStatus)?.reduce(reducer, 0);
  const getPercent = val => ((val / sum) * 100);

  const RESIDENTS_BY_STATUS = [
    {
      status: LABEL[2],
      quantity: active,
      color: "success",
      value: getPercent(active)
    },
    {
      status: LABEL[0],
      quantity: pending,
      color: "warning",
      value: getPercent(pending)
    },
    {
      status: LABEL[1],
      quantity: in_active,
      color: "error",
      value: getPercent(in_active)
    },
  ];

  const chartOptions = merge(BaseOptionChart(), {
    chart: { sparkline: { enabled: true } },
    legend: { show: false },
    plotOptions: {
      radialBar: {
        hollow: { size: '78%' },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: theme.typography.subtitle2.fontSize
          }
        }
      }
    }
  });

  return (
    <RootStyle>
      <CardHeader title="Businesses" />
      <CardContent>
        <Stack spacing={1}>
          {RESIDENTS_BY_STATUS.map((data, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <ReactApexChart
                type="radialBar"
                series={[ 0]}
                options={{
                  ...chartOptions,
                  colors: [theme.palette[data.color].light]
                }}
                width={70}
                height={70}
              />
              <Box sx={{ ml: 3 }}>
                <Typography variant="h6"> {fNumber(0)}</Typography>
                <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                  {data.status}
                </Typography>
              </Box>
            </Box>))}
        </Stack>
      </CardContent>
    </RootStyle>
  );
}
