import axios from '../utils/axios';

export function getZones({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(
    `/zones?settlement_id=${queryParams.settlementId}`
  );
  return response;
}

export function getZoneById({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/zones/${queryParams.zoneId}`);
  return response;
}

export function createZone(data) {
  const response = axios.post('/zones', data);
  return response;
}

export function updateZone({ zoneId, ...data }) {
  const response = axios.patch(`/zones/${zoneId}`, data);
  return response;
}

export function createStreet(data) {
  const response = axios.post('/streets', data);
  return response;
}

export function updateStreet({ streetId, ...data }) {
  const response = axios.patch(`/streets/${streetId}`, data);
  return response;
}

export function getStreetById({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/streets/${queryParams.streetId}`);
  return response;
}

export function getStreets({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/streets?zone_id=${queryParams.zoneId}`);
  return response;
}

export function getBlocks({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/blocks?street_id=${queryParams.streetId}`);
  return response;
}
