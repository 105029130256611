import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import BackOfficeSidebar from './BackOfficeSidebar';
import useAuth from '../../hooks/useAuth';
import LoadingScreen from '../../components/LoadingScreen';
import Page404 from '../../views/Page404';

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: 24,
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

DocsLayout.propTypes = {
  children: PropTypes.node
};

export default function DocsLayout({ children }) {
  const [open, setOpen] = useState(false);
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return (
      <LoadingScreen />
    )
  };

  if (user?.type !== "admin") {
    return <Page404 />;
  }

  return (
    <Box sx={{ display: 'flex', minHeight: '100%', overflow: 'hidden' }}>
      <BackOfficeSidebar
        onCloseSidebar={() => setOpen(false)}
        isOpenSidebar={open}
      />
      <MainStyle>{children}</MainStyle>
    </Box>
  );
}
