/* eslint-disable camelcase */
import { sentenceCase } from 'change-case';
import {
  Box,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import Label from '../Label';
import ResidentUserActions from './ResidentUserActions';
import { PlatformUserStatus } from '../../utils/constant';

export default function ResidentTableBodyRow({
  refetchUsers,
  showLessContent,
  name,
  zoneName,
  status,
  resident,
  pathUrl
}) {
  const history = useHistory();

  const handleClick = () => {
    history.push(pathUrl);
  };

  const residentStatusColors = {
    [PlatformUserStatus.ACTIVE]: "success",
    [PlatformUserStatus.PENDING]: "info",
    [PlatformUserStatus.IN_ACTIVE]: "error",
  };

  return (
    <TableRow
      hover
      tabIndex={-1}
      role="checkbox"
      onClick={handleClick}
    >
      <TableCell
        align="left"
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="left">
        <Label
          variant='ghost'
          color={residentStatusColors[status]}
        >
          {sentenceCase(status || '')}
        </Label>
      </TableCell>

      <TableCell align="right" onClick={e => e.stopPropagation()}>
        <ResidentUserActions resident={resident} refetchUsers={refetchUsers} />
      </TableCell>
    </TableRow>
  );
}
