/* eslint-disable camelcase */
import {
  Box,
  Button,
  Grid,
  Alert,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function CreateWrapper({
  actionBtnAction,
  error,
  isLoading,
  disableSubmitBtn,
  noBackBtn,
  actionBtnText,
  description: formDescription,
  onBackStep,
  children,
  actionBtnProps = {},
  leftSideContent
}) {
  return (
    <Box mt={3} style={{ minHeight: 300 }}>
      <form>
        <Grid container>
          <Grid item sm={12} md={12} sx={{ width: '100%', }}>
            <Box sx={{ maxWidth: 520, margin: "auto" }}>
              {formDescription && (
                <Typography variant="subtitle1" sx={{ mb: 4 }}>
                  {formDescription}
                </Typography>
              )}
              {(!!error?.message || typeof error?.details === "object") && (
                <Alert severity="error" sx={{ mb: 5 }}>
                  <strong>{error.message || "An error occured"}</strong>
                  {(typeof error.details === "object") && Object.keys(error.details).map((el, i) => <p key={i}>{el}{" "}{error.details[el]}</p>)}
                </Alert>
              )}
              {children}
              <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
                {leftSideContent}
                <Box sx={{ flex: 1 }} />
                {!noBackBtn && !!onBackStep && <Button variant="text" color="inherit" onClick={onBackStep} sx={{ mr: 2, textTransform: "none" }}>Go Back</Button>}
                <LoadingButton
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={actionBtnAction}
                  loading={isLoading}
                  disabled={disableSubmitBtn}
                  {...actionBtnProps}
                >
                  {actionBtnText || 'Continue'}
                </LoadingButton>
              </Box>
            </Box>

          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
