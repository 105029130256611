import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import {
    Box, Typography, useTheme, IconButton,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button,
    CircularProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Check as CheckIcon, Clear as CancelIcon } from '@mui/icons-material';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';

import { varFadeInUp, MotionInView, DialogAnimate } from '../../../components/animate';
import Label from '../../../components/Label';
import useBusiness from '../../../hooks/useBusiness';
import useFetchBusinessAdmin from '../../../hooks/useFetchBusinessAdmin';
import { deleteBusinessAdmin, updateBusinessAdmin } from '../../../services/business';
import { PATH_PAGE } from '../../../routes/paths';
import { BusinessAdminStatus } from '../../../utils/constant';

export default function AcceptAdminInvite({ admin }) {
    const theme = useTheme();
    const [openDeclineInvite, setOpenDeclineInvite] = useState(false);
    const history = useHistory();
    const { business } = useBusiness({ businessId: admin.business.id });
    const { admin: invitedByAdmin } = useFetchBusinessAdmin({ adminId: admin.invited_by });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const mutationConfig = {
        onSuccess: () => {
            history.push(PATH_PAGE.myCommunely)
        },
        onError: (error) => {
            enqueueSnackbar(error.message, {
                variant: 'error',
                autoHideDuration: 10000,
                action: (key) => (
                    <IconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </IconButton>
                )
            });
        }
    };


    const {
        mutate: deleteBusinessAdminMutation,
        isLoading: deleteBusinessAdminIsLoading,
    } = useMutation(deleteBusinessAdmin, mutationConfig);

    const {
        mutate: updateBusinessOfferMutation,
        ...updateBusinessOfferFeedback
    } = useMutation(updateBusinessAdmin, {
        ...mutationConfig,
        onSuccess: () => {
            window.location?.reload()
        }
    });

    const handleDeleteBusinessProfile = () => {
        deleteBusinessAdminMutation({
            adminId: admin.id
        });
    };

    const handleUpdateBusinessProfile = () => {
        updateBusinessOfferMutation({
            data: {
                status: BusinessAdminStatus.ACTIVE
            },
            adminId: admin.id
        });
    };

    return (
        <>
            <Box sx={{ textAlign: 'center' }}>
                <Box
                    component="img"
                    alt={business.name}
                    src={business.display_image}
                    sx={{ width: 100, height: 100, borderRadius: "50%", margin: "auto" }}
                />
                <Typography variant="h4" sx={{ my: 3 }}>{business.name}</Typography>
                <MotionInView
                    variants={varFadeInUp}
                    sx={{ height: "100%", width: "100%" }}
                >
                    <Box sx={{ textAlign: "center" }} >
                        <Typography variant="body2">
                            <b>{`${invitedByAdmin.user?.firstname} ${invitedByAdmin.user?.lastname}`}</b> has invited you to be an <Label color="primary"><span style={{ fontSize: "1.2em" }}>admin</span></Label> at {business.name}.
                        </Typography>
                        <br />

                        <Typography variant="body2">As an <Label color="primary"><span style={{ fontSize: "1.2em" }}>admin</span></Label>, you will be able to execute administrative duties within the business.</Typography>
                    </Box>

                    <Box mt={3} display="flex" justifyContent="center">
                        <IconButton
                            onClick={() => setOpenDeclineInvite(true)}
                            variant="outlined"
                            title="Decline invite"
                            sx={{ border: `1px solid ${theme.palette.grey[300]}`, mr: 1 }}
                        >
                            <CancelIcon style={{ fontSize: 30, color: theme.palette.error.main }} />
                        </IconButton>
                        <IconButton
                            onClick={handleUpdateBusinessProfile}
                            variant="outlined"
                            disabled={updateBusinessOfferFeedback.isLoading}
                            title="Accept invite"
                            sx={{ border: `1px solid ${theme.palette.grey[300]}` }}
                        >
                            {
                                updateBusinessOfferFeedback.isLoading
                                    ? <CircularProgress />
                                    : <CheckIcon style={{ fontSize: 30, color: theme.palette.success.main }} />
                            }
                        </IconButton>
                    </Box>
                </MotionInView>
            </Box>
            <DialogAnimate
                open={openDeclineInvite}
                onClose={() => setOpenDeclineInvite(false)}
            >
                <DialogTitle>Decline invite</DialogTitle>
                <DialogContent sx={{ pb: 0, mt: 3, overflowY: 'unset' }}>
                    By declining invite, you won't be able to access this business.
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        variant="outlined"
                        color="inherit"
                        onClick={() => setOpenDeclineInvite(false)}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={deleteBusinessAdminIsLoading}
                        color="error"
                        onClick={handleDeleteBusinessProfile}
                    >
                        Decline
                    </LoadingButton>
                </DialogActions>
            </DialogAnimate>
        </>
    )
}
