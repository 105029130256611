import axios from 'axios';
import { isAuthenticated } from '../services/authJwt';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}api/v1`
});

axiosInstance.interceptors.request.use(
  (config) => {
    // This adds an authorization key to config object if a token exists.
    if (isAuthenticated()) {
      config.headers.common.Authorization = `Bearer ${window.localStorage.getItem('accessToken')}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

export default axiosInstance;
