/* eslint-disable */
import React from 'react';
import {
  Card,
  Stack,
  Box,
  Typography,
  Avatar,
  CircularProgress,
  useTheme
} from '@mui/material';
import { Link } from "react-router-dom";

import EmptyContent from './EmptyContent';
import { parseActivity } from "../utils/activities";

export function Activities({ user, noCard, data, isLoading, pagination }) {
  const Wrapper = noCard ? React.Fragment : Card;

  return (
    <Stack spacing={2}>
      {data.map((activity) => parseActivity({
        activity,
        user
      })).map((activity) => {
        const { description, time, icon, id, avatarColor } = activity;
        return (
          <Wrapper key={id} sx={{ p: noCard ? 0 : 2 }}>
            <ActivityItem
              name={description}
              displayImage={null}
              email={time}
              icon={icon}
              avatarColor={avatarColor}
            />
          </Wrapper>
        )
      })}
      {isLoading && (
        <Box sx={{ py: theme => theme.spacing(15), textAlign: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {pagination.total_count === 0 && (
        <Card sx={{ py: 5 }}>
          <EmptyContent title="No activities - yet" />
        </Card>
      )}
    </Stack>
  );
}


export function ActivityItem({
  name,
  displayImage,
  email,
  icon,
  avatarColor,
  linkTo
}) {
  const theme = useTheme();
  const Icon = icon;
  return (
    <Box
      sx={{
        display: 'flex',
      }}>
      <Avatar
        alt={name}
        src={displayImage}
        sx={{
          width: 35,
          height: 35,
          bgcolor: theme.palette[avatarColor].lighter,
          color: theme.palette[avatarColor].main
        }}
      >
        {icon && <Icon style={{ fontSize: 20 }} />}
      </Avatar>
      <Box sx={{
        flex: 1, pl: 2,
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
      }}>
        <Box sx={{ textAlign: "start" }}>
          <Box>
            <Typography {...(linkTo && {
              component: Link,
              to: linkTo
            })}
              variant="subtitle2"
              sx={{ color: "inherit" }}
            >
              {name}
            </Typography>
          </Box>
          <Typography variant="caption" color="textSecondary" noWrap>
            {email}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
