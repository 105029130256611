/* eslint-disable */
import {
    FormGroup,
    Radio,
    Checkbox,
    TextField,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    FormControl,
    FormHelperText
} from '@mui/material';

export const InputShortAnswer = ({ field, value, onChange, error }) => (
    <TextField
        fullWidth
        value={value}
        onChange={event => onChange(event.target.value)}
        label={field.label}
        error={Boolean(error?.message)}
        helperText={error?.message} />
);

export const InputLongAnswer = ({ field, value, onChange, error }) => (
    <TextField
        multiline
        fullWidth
        maxRows={3}
        value={value}
        minRows={3}
        label={field.label}
        placeholder={field.placeholder}
        onChange={event => onChange(event.target.value)}
        error={Boolean(error?.message)}
        helperText={error?.message}
    />
);

export const InputSingleAnswer = ({ field, value, onChange, error }) => (
    <FormControl component="fieldset">
        <FormLabel component="legend">{field.label}</FormLabel>
        <RadioGroup
            aria-label={field.label}
            row
            name="radio-buttons-group"
            value={value}
            onChange={event => onChange(event.target.value)}
        >
            {field.options.map((option, index) => (
                <FormControlLabel
                    key={index}
                    value={option.label}
                    control={<Radio />}
                    label={option.label}
                />
            ))}
        </RadioGroup>
        {!!error?.message && <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>}
    </FormControl>
);

export const InputMultipleAnswers = ({ field, value, onChange, error }) => {
    let _value = Array.isArray(value) ? [...value] : [];

    const handleChange = (event) => {
        const elementIndex = _value.indexOf(event.target.name)
        if (elementIndex >= 0) {
            _value.splice(elementIndex, 1);
        } else {
            _value.push(event.target.name);
        };
        onChange(_value);
    };

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{field.label}</FormLabel>
            <FormGroup row>
                {field.options.map((option) => (
                    <FormControlLabel
                        key={option.label}
                        control={
                            <Checkbox
                                checked={_value.includes(option.label)}
                                onChange={handleChange}
                                name={option.label}
                            />}
                        label={option.label}
                    />
                ))}
            </FormGroup>
            {!!error?.message && <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>}
        </FormControl>
    )
};
