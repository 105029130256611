import jwtDecode from 'jwt-decode';
import axios from '../utils/axios';

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  // console.log("data=== ", decoded)

  return decoded.exp > currentTime;
};

export function setSession(accessToken) {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
}

// export function verifyToken({ queryKey }) {
//   const [, queryParams] = queryKey;
//   const response = axios.post('/auth/verify-token', {
//     token: queryParams.token
//   });
//   return response;
// }

export function verifyToken(data) {
  const response = axios.post('/auth/users/verify-token/', data);
  return response;
}

export function forgotPassword(data) {
  const response = axios.post('/auth/users/reset-password/', data);
  return response;
}

export function resendForgotPasswordMail({ email }) {
  const response = axios.post('/auth/resend-forgot-password-mail/', {
    email
  });
  return response;
}

export function register(values) {
  const response = axios.post('/auth/users/', values);
  return response;
}

export function resendRegistrationMail({ email }) {
  const response = axios.post('/auth/resend-user-reg-mail/', {
    email
  });
  return response;
}

export function updateUser({ userId, data }) {
  const response = axios.patch(`/auth/users/${userId}`, data);
  return response;
}

export function resetPassword(values) {
  const response = axios.post('/auth/users/create-password/', values);
  return response;
}

export function login({ email, password }) {
  const response = axios.post('/auth/login/', {
    email,
    password
  });
  return response;
}

export function logout() {
  const response = axios.delete('/auth/logout/');
  return response;
}

// ----------------------------------------------------------------------

export function isAuthenticated() {
  const accessToken = window.localStorage.getItem('accessToken');

  return accessToken && isValidToken(accessToken);
}

export function getCurrentUser() {
  const accessToken = window.localStorage.getItem('accessToken');


  if (accessToken && isValidToken(accessToken)) {
    const decoded = jwtDecode(accessToken);

    const response = axios.get(`/auth/users/${decoded.user_id}/`);
    return response;
  };
  return null;
}
