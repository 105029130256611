import PropTypes from 'prop-types';
// material
import {
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel
} from '@mui/material';

UserListHead.propTypes = {
  headLabel: PropTypes.array
};

export default function UserListHead({ headLabel }) {
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => {
          if (!headCell.isVisible) return null;
          return (
            <TableCell
              key={headCell.id}
              align={headCell.alignRight ? 'right' : 'left'}
            >
              <TableSortLabel hideSortIcon noWrap>
                <b>{headCell.label}</b>
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  );
}
