import { useForm, Controller } from 'react-hook-form';
// material
import {
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Select,
  MenuItem,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';

import { DialogAnimate } from '../animate';
import useCurrentPlatformDetails from '../../hooks/useCurrentPlatformDetails';
import { registerPlatformUser as registerPlatformUserApi } from '../../services/platform';
import useZones from '../../hooks/useZones';
import useStreets from '../../hooks/useStreets';
import { ActivitiesScope, ActivitiesType, Roles } from '../../utils/constant';
import usePlatformUsers from '../../hooks/usePlatformUsers';
import userCurrentPlatformDetails from '../../hooks/useCurrentPlatformUserDetails';
import useUpsertActivity from '../../hooks/useUpsertActivity';

export default function AddUsersModal({ open, onClose, disabled }) {
  const defaultValues = {
    email: '',
    zone_id: '',
    street_id: ''
  };
  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues
  });

  const { refetchUsers } = usePlatformUsers();
  // eslint-disable-next-line camelcase
  const { zone_id } = watch();
  const { zones } = useZones();
  const { streets } = useStreets({ zoneId: zone_id });
  const { platform } = useCurrentPlatformDetails({
    role: Roles.SETTLEMENT_DWELLER
  });
  const { userPlatformData } = userCurrentPlatformDetails();
  const { onUpsertActivity } = useUpsertActivity({ platformId: platform.id });

  const handleCloseForm = () => {
    onClose();
    reset(defaultValues);
    refetchUsers();
  };

  const {
    mutate: registerPlatformUserMutation,
    isLoading: registerPlatformUserIsLoading,
    error: registerPlatformUserError,
    isError: registerPlatformUserIsError
  } = useMutation(registerPlatformUserApi, {
    onSuccess: (data) => {
      const payload = data.data.data;
      handleCloseForm();
      onUpsertActivity({
        "scope": ActivitiesScope.SETTLEMENT,
        "type": ActivitiesType.DWELLER_INVITE,
        "object_user_id": payload.user.id,
        "object_community_user_id": payload.id
      })
    }
  });

  const onSubmit = (values) => {
    registerPlatformUserMutation({
      ...values,
      email: values.email.toLowerCase(),
      settlement_id: platform.id,
      role: Roles.SETTLEMENT_DWELLER,
      invited_by: userPlatformData.id
    });
  };

  return (
    <DialogAnimate open={open} onClose={onClose}>
      <DialogTitle>Add Resident</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ pb: 0, overflowY: 'unset' }}>
          {registerPlatformUserIsError && (
            <Alert severity="error" sx={{ mb: 3 }}>
              <strong>{registerPlatformUserError?.message}</strong>
              {(typeof registerPlatformUserError.details === "object") && Object.keys(registerPlatformUserError.details).map((el, i) => <p key={i}>{el}{" "}{registerPlatformUserError.details[el]}</p>)}
            </Alert>
          )}
          {disabled && (
            <Alert severity="error" sx={{ mb: 3 }}>
              <strong>Your estate is inactive</strong><br />
              Members can only be added when your estate becomes active
            </Alert>
          )}
          <Controller
            name="email"
            control={control}
            rules={{
              required: { value: true, message: 'Email is required' },
              pattern: {
                value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: 'Provide a valid email address'
              }
            }}
            render={({ field: { ref, ...fields } }) => (
              <TextField
                {...fields}
                fullWidth
                label="Email"
                inputRef={ref}
                sx={{ mb: 3 }}
              />
            )}
          />
          <Controller
            name="zone_id"
            control={control}
            render={({ field: { ...fields } }) => (
              <Select
                {...fields}
                displayEmpty
                sx={{ mb: 3 }}
                fullWidth
                inputProps={{ 'aria-label': 'Select' }}
              >
                <MenuItem value="" disabled>
                  Select zone
                </MenuItem>
                {zones.map((zone) => (
                  <MenuItem key={zone.id} value={zone.id}>
                    {zone.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Controller
            name="street_id"
            control={control}
            render={({ field: { ...fields } }) => (
              <Select
                {...fields}
                displayEmpty
                sx={{ mb: 3 }}
                fullWidth
                inputProps={{ 'aria-label': 'Select' }}
              >
                <MenuItem value="" disabled>
                  Select street
                </MenuItem>
                {streets.map((street) => (
                  <MenuItem key={street.id} value={street.id}>
                    {street.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />

          {/* <Controller
            name="block"
            control={control}
            render={({ field: { ...fields }, fieldState: { error } }) => (
              <BlockAutocomplete
                {...fields}
                // eslint-disable-next-line camelcase
                streetId={street_id}
                error={error}
              />
            )}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="outlined"
            color="inherit"
            onClick={handleCloseForm}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={disabled}
            loading={registerPlatformUserIsLoading}
          >
            Add
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogAnimate >
  );
}
