/* eslint-disable */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import LoadingScreen from '../../../components/LoadingScreen';
import useAuth from '../../../hooks/useAuth';
import useBusiness from '../../../hooks/useBusiness';
import useFetchBusinessAdmins from '../../../hooks/useFetchBusinessAdmins';
import { Roles, BusinessAdminStatus } from '../../../utils/constant';
import AuthLayout from '../../authentication';
import AcceptAdminInvite from './AcceptAdminInvite';
import Page500 from '../../../views/Page500';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 72;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(5),
  background: theme.palette.grey[100],
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

BusinessDashboardLayout.propTypes = {
  children: PropTypes.node
};

export default function BusinessDashboardLayout({ children }) {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const { businessId } = useParams()
  const { businessLoading } = useBusiness({ businessId })
  const { admins, adminsLoading } = useFetchBusinessAdmins({ user_id: user.id, business_id: businessId })

  const currentUserAdmin = admins.find(admin => admin.user.id === user.id);

  if (businessLoading || adminsLoading) {
    return (
      <LoadingScreen />
    )
  };

  if (
    currentUserAdmin?.role === Roles.BUSINESS_ADMIN &&
    currentUserAdmin?.status === BusinessAdminStatus.ACTIVE
  ) {
    return (
      <RootStyle>
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
        <MainStyle>{children}</MainStyle>
      </RootStyle>
    );
  }

  if (
    currentUserAdmin?.role === Roles.BUSINESS_ADMIN &&
    currentUserAdmin?.status === BusinessAdminStatus.PENDING
  ) {
    return (
      <AuthLayout pageTitle="Join | communely">
        <AcceptAdminInvite
          admin={currentUserAdmin}
          // renderGoBackBtn={renderGoBackBtn}
        />
      </AuthLayout>
    );
  }

  if (currentUserAdmin?.role !== Roles.BUSINESS_ADMIN) {
    return (
      <Page500
        title="Unauthorized"
        desc="You need the right authorization to access this page."
      />
    );
  }

  return null;
}
