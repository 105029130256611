import { TextField, Autocomplete } from '@mui/material';
import { Controller } from 'react-hook-form';

import useBanks from '../../hooks/useBanks';

export default function MainBankInformation({ useFormUtils }) {
  const { isLoading, banks } = useBanks();
  const { control } = useFormUtils;

  return (
    <>
      <Controller
        name="bank"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Select a bank'
          }
        }}
        render={({
          field: { value, onChange },
          fieldState: { error }
        }) => (
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              onChange(newValue);
            }}
            loading={isLoading}
            id="bank-name"
            options={banks}
            getOptionLabel={(option) => option.name}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Bank name"
                variant="outlined"
                autoFocus
                sx={{ mb: 3 }}
                error={Boolean(error?.message)}
                helperText={error?.message}
              />
            )}
          />
        )}
      />
      <Controller
        name="account_number"
        control={control}
        rules={{ required: 'Account number is needed' }}
        render={({ field: { ref, ...fields }, fieldState: { error } }) => (
          <TextField
            {...fields}
            fullWidth
            label="Account number"
            inputRef={ref}
            sx={{ mb: 3 }}
            error={Boolean(error?.message)}
            helperText={error?.message || 'Enter your NUBAN account number'}
          />
        )}
      />
      <Controller
        name="account_holder"
        control={control}
        rules={{ required: 'Account name is needed' }}
        render={({ field: { ref, ...fields }, fieldState: { error } }) => (
          <TextField
            {...fields}
            fullWidth
            label="Account name"
            inputRef={ref}
            error={Boolean(error?.message)}
            helperText={error?.message}
          />
        )}
      />
    </>
  );
}
