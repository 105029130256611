
import axios from '../utils/axios';
import { parseComplexQueryParams } from '../utils/parseQuery';

export function getPaymentOverview({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/general-overview/payment`, {
    params: parseComplexQueryParams(queryParams)
  });
  return response;
};

export function getResidentOverview({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/general-overview/residents`, {
    params: parseComplexQueryParams(queryParams)
  });
  return response;
};

// export function updatePlatformUser({ userId, data }) {
//   const response = axios.patch(`/settlement-users/${userId}`, data);
//   return response;
// }

// export function getPlatformUserById({ queryKey }) {
//   const [, queryParams] = queryKey;
//   const response = axios.get(`/settlement-users/${queryParams.userId}`);
//   return response;
// }
