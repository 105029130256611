import { useQuery } from 'react-query';
import { useNetworkStatus } from "use-network-status";
// redux
import { getCurrentUser, isAuthenticated } from '../services/authJwt';

export default function useAuth() {
  const isOnline = useNetworkStatus();
  
  const { data, isLoading, remove, refetch } = useQuery({
    queryKey: 'userDetails',
    queryFn: getCurrentUser,
    enabled: isOnline && Boolean(isAuthenticated()),
    config: {
      cacheTime: 3600 * 100,
      staleTime: 3600 * 100,
    }
  });

  const userData = data?.data || {};

  // console.log("data== ", userData)

  return {
    user: {
      photoURL: userData.profile_image?.resize_url, // || '/static/mock-images/avatars/avatar_default.jpg',
      ...userData,
      communities: userData.community,
      displayName: userData.display_name // `${userData.firstname} ${userData.lastname}`
    },
    isLoading,
    isAuthenticated: Boolean(isAuthenticated()),
    refetchUser: refetch,
    clearUserData: remove
  };
}
