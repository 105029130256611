import {
    Box,
    Typography,
    Divider,
    Avatar,
    AppBar,
    Toolbar,
    IconButton,
    Grid,
    Hidden
} from '@mui/material';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { styled, alpha } from '@mui/material/styles';
import searchIcon from '@iconify/icons-fluent/search-12-regular';
import { Icon } from '@iconify/react';

import useCurrentPlatformDetails from '../../../hooks/useCurrentPlatformDetails';
import AccountPopover from '../../../layouts/AccountPopover';

const AppBarStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    height: 64,

    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
}));

export default function FullWidthCustomNavBar({
    title,
    hideSearch,
    hideMenu
}) {
    const { platform } = useCurrentPlatformDetails();

    return (
        <AppBarStyle>
            <Toolbar sx={{ p: 0, height: "100%" }}>
                <Grid
                    sx={{
                        // height: "100%",
                        marginLeft: {
                            lg: "280px",
                            xs: 0
                        }
                    }}
                    container
                >
                    <Grid item sx={{ flex: 1 }}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            px={{
                                xs: 3,
                                sm: 0
                            }}
                        >
                            <Hidden lgDown>
                                {title || (
                                    <Typography variant="subtitle1"
                                        sx={{ fontWeight: t => t.typography.h6.fontWeight, color: "black" }}>Home</Typography>
                                )}
                            </Hidden>
                            <Hidden lgUp>
                                {title || (
                                    <>
                                        <Avatar sx={{height: 30, width: 30}} src={platform.display_image} />
                                        <Typography variant="subtitle1"
                                            sx={{ fontWeight: t => t.typography.h6.fontWeight, color: "black" }} noWrap>{platform.name}</Typography>
                                    </>
                                )}
                            </Hidden>
                            <Box
                                display="flex"
                                alignItems="center"
                            >

                                {/* {!hideSearch && (
                                    <IconButton sx={{ mr: 2 }}>
                                        <Icon icon={searchIcon} width={16} height={16} />
                                    </IconButton>
                                )} */}
                                {!hideMenu && (
                                    <AccountPopover
                                        icon={<Icon icon={menu2Fill} />}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    {/* <Hidden smDown>
                        <Grid item sx={{ width: 390 }} />
                    </Hidden> */}
                </Grid>
            </Toolbar>
            <Divider sx={{py: 0, my: 0}} />
        </AppBarStyle>
    );
}

