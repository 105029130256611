/* eslint-disable */
import { useState, useEffect } from 'react';
import {
  styled, Grid, Stack, Typography, DialogTitle, Box,
  DialogContent,
  Button,
  DialogActions,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { v4 as uuid } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { DialogAnimate } from '../animate';
import CustomFormField from './CustomFormField';
import CustomFormOptions from './CustomFormOptions';

const CustomFormBuilder = ({ onCreateForm, formFields, isLoading }) => {
  const [fields, setFields] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentDraggable, setCurrentDraggable] = useState('');

  useEffect(() => {
    if (Array.isArray(formFields)) {
      setFields(formFields);
    }
  }, [formFields]);

  const handleAddField = (selected) => {
    setFields([...fields, selected]);
  };

  const updateField = (newValues, id) => {
    const newFields = fields.map((field) => {
      if (field.id === id) return { ...field, ...newValues };
      return field;
    });
    setFields(newFields);
  };

  const handleDuplicate = (field) => {
    const uniqueId = uuid();
    const parentIndex = fields.indexOf(field);
    const _fields = [...fields];
    const removed = _fields.splice(parentIndex + 1, _fields.length);
    setFields([..._fields, { ...field, id: uniqueId, key: uniqueId }, ...removed]);
  };

  const handleDelete = (id) => {
    setFields(fields.filter((f) => f.id !== id));
  };

  const handleCreateForm = (status) => {
    onCreateForm({
      status,
      form_fields: fields
    })();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    setFields(reorder(fields, result.source.index, result.destination.index));
  };

  return (
    <>
      <Stack direction="row" spacing={5}>
        <Box item xs={4} sx={{ width: "100%", maxWidth: 320 }}>
          <Stack spacing={2}>
            <CustomFormOptions onAddField={handleAddField} />
            <LoadingButton loading={isLoading} color="inherit" variant="contained" onClick={() => handleCreateForm("DRAFT")}>Save as draft</LoadingButton>
            <LoadingButton loading={isLoading} color="primary" variant="contained" onClick={() => setOpen(true)}>Publish</LoadingButton>
          </Stack>
        </Box>
        <Box item xs={8} sx={{ flex: 1 }}>
          <Typography variant="h6">Form fields</Typography>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="customFormFields">
              {(provided) => (
                <Wrapper ref={provided.innerRef} {...provided.droppableProps}>
                  <FormFields>
                    {fields.map((field, index) => (
                      <Draggable
                        key={field.id}
                        isDragDisabled={!(currentDraggable === field.id)}
                        index={index}
                        draggableId={field.id}
                      >
                        {(_provided) => (
                          <div
                            ref={_provided.innerRef}
                            {..._provided.draggableProps}
                            {..._provided.dragHandleProps}
                          >
                            <CustomFormField
                              field={field}
                              updateField={updateField}
                              onDuplicate={handleDuplicate}
                              onDelete={handleDelete}
                              setCurrentDraggable={setCurrentDraggable}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </FormFields>
                </Wrapper>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Stack>
      <DialogAnimate open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirm Publish Form</DialogTitle>
        <DialogContent sx={{ pb: 0, mt: 3, overflowY: 'unset' }}>
          <Typography variant="body2" color="text.secondary">
            Publishing this form mean you won't be able to edit the form again. You can only delete it.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="outlined"
            color="inherit"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => handleCreateForm("PUBLISHED")}
            variant="contained"
            loading={isLoading}
          >
            Publish
          </LoadingButton>
        </DialogActions>
      </DialogAnimate >
    </>
  );
};

export default CustomFormBuilder;

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: 450
}));

const FormFields = styled('div')(({ theme }) => ({
  width: "100%",
  maxWidth: 606,
  '& > *': {
    marginTop: 16
  },
  '& > :first-child': {
    marginTop: 0
  }
}));
