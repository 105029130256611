/* eslint-disable */
import {
  Box,
  Typography,
  Divider,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Link } from "react-router-dom";
import { Close } from '@mui/icons-material';
import { useMutation, useQuery } from 'react-query';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';

import { getPlatformServiceCharges, registerPlatformUser } from '../../../services/platform';
import { approvePlatformUser } from '../../../services/users';
import { fCurrency } from '../../../utils/formatNumber';
import useAuth from '../../../hooks/useAuth';
import { ChargesTypes, Roles } from '../../../utils/constant';
import { PATH_PAGE } from '../../../routes/paths';

export default function JoinCommunityMainContent({
  handleSuccess,
  acceptInvite,
  settlementUserId,
  platform
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user } = useAuth();

  const { data: { data: serviceChargeData } = {} } = useQuery({
    queryKey: ['serviceCharges', {
      settlement_id: platform.id,
      type: ChargesTypes.SERVICE
    }],
    queryFn: getPlatformServiceCharges,
    enabled: !!platform.id
  });

  const serviceCharges = serviceChargeData?.data || [];
  const reducer = (accumulator, currentValue) => Number(accumulator) + Number(currentValue.value);
  const utilitySum = serviceCharges?.reduce(reducer, 0);

  const {
    mutate: approvePlatformUserMutation,
    isLoading: updatePlatformUserIsLoading,
  } = useMutation(approvePlatformUser, {
    onSuccess: () => {
      handleSuccess?.();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 10000,
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    }
  });

  const {
    mutate: createPlatformUserMutation,
  } = useMutation(registerPlatformUser, {
    onSuccess: () => {
      handleSuccess();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 10000,
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    }
  });

  const handleJoinCommunity = () => {
    if (acceptInvite) {
      approvePlatformUserMutation({
        id: settlementUserId
      });
      return;
    };
    createPlatformUserMutation({
      email: user.email,
      settlement_id: platform.id,
      role: Roles.SETTLEMENT_DWELLER
    });
  };

  const renderJoinContent = () => (
    <Box>
      <Box sx={{ display: 'flex', alignItems: "flex-start" }}>
        <Box sx={{ display: 'flex', alignItems: "flex-start", flex: 1 }}>
          <Box
            component="img"
            alt={platform.name}
            src={platform.display_image || '/static/mock-images/avatars/avatar_default.jpg'}
            sx={{ width: 48, height: 48, borderRadius: "50%" }}
          />
          <Box sx={{ flex: 1, ml: 1.5 }}>
            <Typography variant="body1" ><b>{platform.name}</b></Typography>
            <Typography variant="body2" >{platform.description}</Typography>
          </Box>
        </Box>
      </Box>
      {!acceptInvite && (
        <Box sx={{ padding: 2, bgcolor: theme => theme.palette.grey[500_8], borderRadius: 1, mt: 2 }}>
          <Typography variant="body2">{platform.name} will either approve or decline this request as they deem fit. We'd notify you, when they respond.</Typography>
        </Box>
      )}

      <Box sx={{ padding: 2, bgcolor: theme => theme.palette.grey[500_8], borderRadius: 1, mt: 2 }}>
        <Typography variant="subtitle2"  >Charges per month</Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>You will be obligated to pay the service charges on joining the community.</Typography>
        {serviceCharges?.map((charge, index) => (
          <>
            <Box key={index} display="flex" alignItems="center" justifyContent="space-between" >
              <Typography variant="body2" >{charge.name}</Typography>
              <Typography variant="body2"><b>&#8358;{fCurrency(charge.value)}</b></Typography>
            </Box>
            <Divider key={index} sx={{ borderStyle: 'dashed', my: 1 }} />
          </>
        ))}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" ><b>Sum</b></Typography>
          <Typography variant="body2"><b>&#8358;{fCurrency(utilitySum)}</b></Typography>
        </Box>
      </Box>
    </Box>
  );

  const renderJoinAction = () => (
    <>
      <Divider sx={{ borderStyle: 'dashed', my: 2 }} />
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="caption">By pressing join, you agree to pay the charges per month</Typography>
        <LoadingButton
          sx={{ ml: 2 }}
          variant="contained"
          color="warning"
          onClick={handleJoinCommunity}
          loading={updatePlatformUserIsLoading}
        >{acceptInvite ? "Accept" : "Join"}
        </LoadingButton>
      </Box>
    </>
  );

  return (
    <>
      {renderJoinContent()}
      {renderJoinAction()}
    </>
  );
}
