// material
import { useRef, useState } from 'react';
// material
import {
  Menu,
  MenuItem,
  ListItemText,
  Divider,
  IconButton,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Select,
  useTheme
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';
import { Icon } from '@iconify/react';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

import { DialogAnimate } from '../animate';
import Label from '../Label';
import useZones from '../../hooks/useZones';
import useStreets from '../../hooks/useStreets';
import { ActivitiesScope, ActivitiesType, PlatformUserStatus } from '../../utils/constant';
import { approvePlatformUser, cancelInvite, declinePlatformUser, updatePlatformUser } from '../../services/users';
import useUpsertActivity from '../../hooks/useUpsertActivity';
import useCurrentPlatformDetails from '../../hooks/useCurrentPlatformDetails';

const ACTIONS = {
  APPROVE: "APPROVE",
  DECLINE: "DECLINE",
  RESTORE: "RESTORE",
  ADD_ZONE: "ADD_ZONE",
  ADD_STREET: "ADD_STREET",
  REMOVE: "REMOVE",
  CANCEL_INVITE: "CANCEL_INVITE"
}

export default function ResidentUserActions({ resident, refetchUsers }) {
  const ref = useRef(null);
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const { platform } = useCurrentPlatformDetails();
  const [selectedAction, setSelectedAction] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const residentName = `${resident.user.firstname} ${resident.user.lastname}`;

  const mutationConfig = {
    onSuccess: () => {
      refetchUsers();
      setSelectedAction(null);
      enqueueSnackbar(`${residentName} was updated succssfully`, {
        variant: 'success',
        autoHideDuration: 10000,
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 10000,
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    }
  };

  const {
    mutate: updatePlatformUserMutation,
    isLoading: updatePlatformUserIsLoading,
  } = useMutation(updatePlatformUser, mutationConfig);

  const {
    mutate: approvePlatformUserMutation,
    isLoading: approvePlatformUserIsLoading,
  } = useMutation(approvePlatformUser, mutationConfig);

  const {
    mutate: declinePlatformUserMutation,
    isLoading: declinePlatformUserIsLoading,
  } = useMutation(declinePlatformUser, mutationConfig);

  const {
    mutate: cancelInviteMutation,
    isLoading: cancelInviteIsLoading,
  } = useMutation(cancelInvite, mutationConfig);

  const isLoading = updatePlatformUserIsLoading || approvePlatformUserIsLoading || declinePlatformUserIsLoading || cancelInviteIsLoading;

  const OPTIONS = [
    {
      activityType: ActivitiesType.APPROVE_USER,
      dialogTitle: `Approve ${residentName}`,
      dialogContent: <Typography variant="body2"><Typography variant="body2" component="span" color="primary">{residentName}</Typography> will become a member of your community, hence, be able to to participate.</Typography>,
      text: 'Approve',
      show: resident.status === PlatformUserStatus.PENDING && !resident.invited_by,
      action: ACTIONS.APPROVE,
    },
    {
      activityType: ActivitiesType.DECLINE_USER,
      dialogTitle: `Decline ${residentName}`,
      dialogContent: <Typography variant="body2"><Typography variant="body2" component="span" color="primary">{residentName}</Typography> will be removed from your community, hence, will not be able to to participate.</Typography>,
      text: 'Decline',
      show: resident.status === PlatformUserStatus.PENDING && !resident.invited_by,
      actionBtnColor: theme.palette.error.main,
      btnColor: "error",
      action: ACTIONS.DECLINE
    },
    {
      activityType: ActivitiesType.CANCEL_INVITE,
      dialogTitle: `Cancel invite`,
      dialogContent: <Typography variant="body2"><Typography variant="body2" component="span" color="primary">{residentName}</Typography> will not be able to join your community, hence, will not be able to to participate.</Typography>,
      text: 'Cancel invite',
      show: resident.status === PlatformUserStatus.PENDING && !!resident.invited_by,
      actionBtnColor: theme.palette.error.main,
      btnColor: "error",
      action: ACTIONS.CANCEL_INVITE
    },
    // {
    //   activityType: ActivitiesType.ADD_USER_ZONE,
    //   dialogTitle: `Add ${residentName} to zone`,
    //   dialogContent: <Typography variant="body2"><Typography variant="body2" component="span" color="primary">{residentName}</Typography> will not become a member of your estate, and hence, will not be able to to participate.</Typography>,
    //   text: 'Add to zone',
    //   show: resident.status === PlatformUserStatus.ACTIVE && !resident.zone_id,
    //   actionStatus: PlatformUserStatus.IN_ACTIVE,
    //   action: ACTIONS.ADD_ZONE
    // },
    // {
    //   activityType: ActivitiesType.ADD_USER_STREET,
    //   dialogTitle: `Add ${residentName} to street`,
    //   dialogContent: <Typography variant="body2"><Typography variant="body2" component="span" color="primary">{residentName}</Typography> will not become a member of your estate, and hence, will not be able to to participate.</Typography>,
    //   text: 'Add to street',
    //   show: resident.status === PlatformUserStatus.ACTIVE && !!resident.zone_id && !resident.street_id,
    //   actionStatus: PlatformUserStatus.IN_ACTIVE,
    //   action: ACTIONS.ADD_STREET
    // },
    // {
    //   activityType: ActivitiesType.SUSPEND_USER,
    //   dialogTitle: `Remove ${residentName}`,
    //   dialogContent: <Typography variant="body2"><Typography variant="body2" component="span" color="primary">{residentName}</Typography> will be removed from your community, hence, will not be able to to participate.</Typography>,
    //   text: 'Remove',
    //   show: resident.status === PlatformUserStatus.ACTIVE,
    //   actionBtnColor: theme.palette.error.main,
    //   actionStatus: PlatformUserStatus.IN_ACTIVE,
    //   action: ACTIONS.CANCEL_INVITE,
    //   btnColor: "error",
    // },

    // {
    //   activityType: ActivitiesType.RESTORE_USER,
    //   dialogTitle: `Restore ${residentName}`,
    //   dialogContent: <Typography variant="body2"><Typography variant="body2" component="span" color="primary">{residentName}</Typography> will become a member of your estate, and hence, be able to to participate.</Typography>,
    //   text: 'Restore',
    //   show: resident.status === PlatformUserStatus.IN_ACTIVE,
    //   actionBtnColor: theme.palette.error.main,
    //   actionStatus: PlatformUserStatus.ACTIVE,
    //   action: ACTIONS.RESTORE
    // },
  ];

  const handleUpdateUser = () => {
    if (selectedAction.action === ACTIONS.APPROVE) {
      approvePlatformUserMutation({
        communityId: platform.id,
        community_user: resident.id,
      });
      return;
    };

    if (selectedAction.action === ACTIONS.CANCEL_INVITE) {
      cancelInviteMutation({
        communityId: platform.id,
        community_user: resident.id
      });
      return;
    };

    if (selectedAction.action === ACTIONS.DECLINE) {
      declinePlatformUserMutation({
        communityId: platform.id,
        community_user: resident.id,
      });
      return;
    };

    updatePlatformUserMutation({
      userId: resident.id,
      data: {
        status: selectedAction.actionStatus
      }
    });
  }

  return (
    <>
      <IconButton
        size="large"
        variant="contained"
        ref={ref}
        // style={{ zIndex: 100, position:"absolute" }}
        onClick={() => {
          // event.stopPropagation();
          setIsOpen(true);
        }}
      >
        <Icon width={20} height={20} icon={moreVerticalFill} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {OPTIONS.filter(el => el.show).map((item) => (
          <>
            <MenuItem
              key={item.text}
              onClick={() => {
                setSelectedAction(item);
                setIsOpen(false);
              }}
              sx={{ typography: 'body', py: 1.2, px: 2.5, minWidth: 150 }}
            >
              <ListItemText
                primary={item.text}

                primaryTypographyProps={{ variant: 'body2', color: item.actionBtnColor }}
              />
            </MenuItem>
            <Divider
              key={item.text}
              sx={{
                bgcolor: "grey.500_8",
                margin: "0 !important",
                "&:last-child": {
                  display: "none",
                }
              }}
            />
          </>
        ))}
      </Menu>
      <DialogAnimate
        open={[ACTIONS.APPROVE, ACTIONS.DECLINE, ACTIONS.REMOVE, ACTIONS.CANCEL_INVITE, ACTIONS.RESTORE].includes(selectedAction?.action)}
        onClose={() => setSelectedAction(null)}
      >
        <DialogTitle>{selectedAction?.dialogTitle}</DialogTitle>
        <DialogContent sx={{ pb: 0, mt: 3, overflowY: 'unset' }}>
          {selectedAction?.dialogContent}
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="outlined"
            color="inherit"
            onClick={() => setSelectedAction(null)}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            color={selectedAction?.btnColor || "primary"}
            onClick={handleUpdateUser}
          >
            {selectedAction?.text}
          </LoadingButton>
        </DialogActions>
      </DialogAnimate>
    </>
  );
}
