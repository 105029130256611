import { useForm, Controller } from 'react-hook-form';
// material
import {
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { DialogAnimate } from '../animate';

export default function UpsertHouseModal({ open, onClose }) {
  const { handleSubmit, control } = useForm();

  const handleCloseForm = () => {
    onClose();
  };

  return (
    <DialogAnimate open={open} onClose={onClose}>
      <DialogTitle>Add House</DialogTitle>
      <form onSubmit={handleSubmit(() => null)}>
        <DialogContent sx={{ pb: 0, overflowY: 'unset' }}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: { value: true, message: 'Street is required' }
            }}
            render={({ field: { ref, ...fields }, fieldState: { error } }) => (
              <TextField
                {...fields}
                label="Street"
                variant="outlined"
                required
                fullWidth
                inputRef={ref}
                sx={{ mb: 3 }}
                error={Boolean(error?.message)}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field: { ref, ...fields } }) => (
              <TextField
                {...fields}
                label="Describe this street"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                inputRef={ref}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="outlined"
            color="inherit"
            onClick={handleCloseForm}
          >
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" pending={false}>
            Add
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogAnimate >
  );
}
