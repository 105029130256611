import { useForm, Controller } from 'react-hook-form';
// material
import {
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  DialogActions,
  Alert
} from '@mui/material';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQuery } from 'react-query';

import { DialogAnimate } from '../animate';
import useZones from '../../hooks/useZones';
import { createZone as createZoneApi, updateZone as updateZoneApi, getZoneById } from '../../services/zones';
import useCurrentPlatformDetails from '../../hooks/useCurrentPlatformDetails';

export default function UpserZoneModal({ open, onClose, zoneId }) {
  const isEditMode = typeof zoneId === "string";
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: "",
      description: ""
    }
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { refetchZones } = useZones();
  const { platform } = useCurrentPlatformDetails();

  const zoneMutationConfig = {
    onSuccess: () => {
      handleCloseForm();
      refetchZones();
      enqueueSnackbar(`Zone was ${isEditMode ? "updated" : "created"} successfully`, {
        variant: 'success',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    },
  };

  const handleCloseForm = () => {
    onClose();
  };

  useQuery({
    queryKey: ['zone', { zoneId }],
    enabled: isEditMode,
    queryFn: getZoneById,
    onSuccess: data => {
      const { name, description } = data.data.data;
      reset({
        name, description
      });
    }
  });

  const { mutate: zoneMutation, ...zoneMutationFeedback } = useMutation(
    createZoneApi,
    zoneMutationConfig
  );

  const { mutate: updateZoneMutation, ...updateZoneMutationFeedback } = useMutation(
    updateZoneApi,
    zoneMutationConfig
  );

  const isLoading = zoneMutationFeedback.isLoading || updateZoneMutationFeedback.isLoading;
  const error = zoneMutationFeedback.error || updateZoneMutationFeedback.error;
  const isError = zoneMutationFeedback.isError || updateZoneMutationFeedback.isError;

  const onSubmit = (values) => {
    if (isEditMode) {
      updateZoneMutation({ ...values, zoneId });
      return;
    }
    zoneMutation({ ...values, settlement_id: platform.id });
  };

  return (
    <DialogAnimate open={open} onClose={onClose}>
      <DialogTitle>{isEditMode ? "Edit" : "Add"} zone</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ pb: 0, overflowY: 'unset' }}>
          {isError && (
            <Alert severity="error" sx={{ mb: 5 }}>
                   <strong>{error?.message}</strong>
                    {(typeof error.details === "object") && Object.keys(error.details).map((el, i) => <p key={i}>{el}{" "}{error.details[el]}</p>)}
            </Alert>
          )}
          <Controller
            name="name"
            control={control}
            rules={{
              required: { value: true, message: 'Zone name is required' }
            }}
            render={({ field: { ref, ...fields }, fieldState: { error } }) => (
              <TextField
                {...fields}
                label="Zone name"
                variant="outlined"
                required
                fullWidth
                inputRef={ref}
                sx={{ mb: 3 }}
                error={Boolean(error?.message)}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{
              required: { value: true, message: 'Description is required' }
            }}
            render={({ field: { ref, ...fields }, fieldState: { error } }) => (
              <TextField
                {...fields}
                label="Describe this zone"
                variant="outlined"
                required
                multiline
                rows={4}
                fullWidth
                inputRef={ref}
                error={Boolean(error?.message)}
                helperText={error?.message}
                sx={{ mb: (theme) => theme.spacing(3) }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="outlined"
            color="inherit"
            onClick={handleCloseForm}
          >
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            {isEditMode ? "Update" : "Add"}
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogAnimate >
  );
}
