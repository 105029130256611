import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {
  useLocation,
  matchPath,
  useParams,
} from 'react-router-dom';
// material
import {
  styled
} from '@mui/material/styles';
import {
  Box,
  List,
  Drawer,
  Hidden,
  Typography,
  ListSubheader,
} from '@mui/material';
// routes
import MyAvatar from '../../../components/MyAvatar';
import Scrollbar from '../../../components/Scrollbar';
import MenuLinks from './SidebarConfig';
import SidebarItem from './SidebarItem';
import useBusiness from '../../../hooks/useBusiness';

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { businessId } = useParams();
  const { business } = useBusiness({businessId});

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar>
        <AccountStyle>
          <MyAvatar photoURL={business.logo} alt={business.name} sx={{bgcolor: "warning.main"}} />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle1" sx={{ color: 'text.primary', maxWidth: "120px", mb: -1 }} noWrap>
              {business.name}
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              admin
            </Typography>
          </Box>
        </AccountStyle>

        {MenuLinks(businessId).map((list, index) => (
          <List
            disablePadding

            key={index}
            subheader={
              <ListSubheader
                disableSticky
                disableGutters
                sx={{
                  mt: 3,
                  mb: 2,
                  pl: 5,
                  color: 'text.primary',
                  typography: 'overline'
                }}
              >
                {list.subheader}
              </ListSubheader>
            }
          >
            {renderSidebarItems({
              items: list.items,
              pathname
            })}
          </List>
        ))}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(5, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
  alignItems: "center",
}));

function reduceChild({ array, item, pathname, level }) {
  const key = item.href + level;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    return [
      ...array,
      <SidebarItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        accessNavBar={item.accessNavBar}
        open={Boolean(match)}
      >
        {renderSidebarItems({
          pathname,
          level: level + 1,
          items: item.items
        })}
      </SidebarItem>
    ];
  }
  return [
    ...array,
    <SidebarItem
      key={key}
      level={level}
      href={item.href}
      icon={item.icon}
      info={item.info}
      title={item.title}
      accessNavBar={item.accessNavBar}
    />
  ];
}

function renderSidebarItems({ items, pathname, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChild({ array, item, pathname, level }),
        []
      )}
    </List>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};
