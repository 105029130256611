/* eslint-disable */
import { v4 as uuid } from 'uuid';
import { Typography, styled, Box, Button, Stack, Card } from '@mui/material';
import { fieldConfigs as options } from './constants';

const CustomFormOptions = ({ onAddField }) => {
  const handleClick = (option) => () => {
    const uniqueId = uuid();
    onAddField({
      options: [],
      ...option,
      id: uniqueId,
      key: uniqueId
    });
  };
  return (
      <Box bgcolor="white" p={2} sx={{ width: 1 }}>
        <Stack spacing={2}>
          <Typography>Fields</Typography>

          {options.map((option) => (
            <Option key={option.id} onClick={handleClick(option)}>
              <Card sx={{ p: 2, width: 1 }}>
                <Typography variant="body2">{option.label}</Typography>
              </Card>
            </Option>
          ))}
        </Stack>
      </Box>
  );
};

export default CustomFormOptions;

const Option = styled(Button)(({ theme }) => ({
  padding: 0
}));
