/* eslint-disable no-nested-ternary */
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation } from 'react-query';
import closeFill from '@iconify/icons-eva/close-fill';
import { LoadingButton } from '@mui/lab';
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  ListItemIcon,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@mui/material';

import useAuth from '../hooks/useAuth';
import { MIconButton } from '../components/@material-extend';
import MyAvatar from '../components/MyAvatar';
import MenuPopover from '../components/MenuPopover';
import { logout as logoutApi, setSession } from '../services/authJwt';
import { PATH_BUSINESS, PATH_PAGE, ROOTS_COMMUNITY } from '../routes/paths';
import { Roles } from '../utils/constant';

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'My Communely',
    icon: personFill,
    linkTo: PATH_PAGE.myCommunely
  },
  // {
  //   label: 'Services',
  //   icon: personFill,
  //   linkTo: PATH_BUSINESS.busniesses
  // },
];

const Community = ({ settlement }) => {
  const routePath = `${ROOTS_COMMUNITY}/${settlement.id}`;

  return (
    <ListItem
      button
      to={routePath}
      disableGutters
      component={RouterLink}
      sx={{
        py: 1,
        px: 2.5,
        '&:not(:last-of-type)': { mb: '1px' },
        bgcolor: 'action.selected'
      }}
    >
      <ListItemAvatar>
        <Avatar
          alt={settlement.name}
          src={settlement.community_logo?.resize_url || "/static/community.png"}
          sx={{ height: 32, width: 32 }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="subtitle2" noWrap>
            {settlement.name}
          </Typography>
        }
        // secondary={
        //   <Typography variant="caption" color="text.secondary" noWrap>
        //     @{settlement.alias}
        //   </Typography>
        // }
      />
    </ListItem>
  )
}

export default function AccountPopover({ icon }) {
  const anchorRef = useRef(null);
  const { user, clearUserData } = useAuth();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // const { mutate: logoutMutation, isLoading } = useMutation(logoutApi, {
  //   onSuccess: () => {
  //     handleClose();
  //     setSession(null);
  //     window.location?.reload()
  //     clearUserData()
  //   },
  //   onError: (error) => {
  //     enqueueSnackbar(error.message, {
  //       variant: 'error',
  //       action: (key) => (
  //         <MIconButton size="small" onClick={() => closeSnackbar(key)}>
  //           <Icon icon={closeFill} />
  //         </MIconButton>
  //       )
  //     });
  //   }
  // });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    try {
      // logoutMutation({});
      handleClose();
      setSession(null);
      clearUserData()
      window.location?.reload()
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: !icon && 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => !icon && alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        {icon || <MyAvatar photoURL={user.photoURL} alt={user.dsplayName} />}
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: { md: 320, xs: 280 } }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            @{user.username}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <List disablePadding>
          {MENU_OPTIONS.map((option) => (
            <ListItem
              button
              to={option.linkTo}
              disableGutters
              key={option.label}
              component={RouterLink}
              sx={{
                py: 1.5,
                px: 2.5,
                '&:not(:last-of-type)': { mb: '1px' },
              }}
            >
              <ListItemIcon>
                <Icon icon={option.icon} />
              </ListItemIcon>
              <ListItemText primary={
                <Typography variant="subtitle2" noWrap>
                  {option.label}
                </Typography>
              } />
            </ListItem>
          ))}
        </List>

        <Box pt={1} sx={{ maxHeight: 200, overflow: "scroll" }}>
          <List disablePadding>
            {user.community?.map((settlement) => (
              <Community settlement={settlement} key={settlement.id} />
            ))}
          </List>
        </Box>
        <Box sx={{ p: 2 }}>
          <LoadingButton
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          // loading={isLoading}
          >
            Logout
          </LoadingButton>
        </Box>
      </MenuPopover>
    </>
  );
}
