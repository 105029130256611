import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import copyFill from '@iconify/icons-eva/copy-fill';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { alpha, styled } from '@mui/material/styles';
import { Box, AppBar, Hidden, Toolbar, IconButton, Stack } from '@mui/material';

import { QuickActions } from '../../../components/overview/dashboard';
import NotificationsPopover from '../../NotificationsPopover';
import { RegistrationStages, PlatformStatus } from '../../../utils/constant';
import AccountPopover from '../../AccountPopover';
import StatusPopover from '../../StatusPopover';
import CopyClipboard from '../../../components/CopyClipboard';
import useCurrentPlatformDetails from '../../../hooks/useCurrentPlatformDetails';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { platform } = useCurrentPlatformDetails();
  const inviteLink = `${window.location.origin}/join?code=${platform.alias}`;
  const isRegComplete = platform.prerequisite_stages?.includes(RegistrationStages.PAYMENT);

  return (
    <RootStyle>
      <ToolbarStyle>
        <Hidden lgUp>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </Hidden>
        <StatusPopover status={platform.status} />

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          spacing={1}
          direction="row"
          sx={{ alignItems: 'center' }}>
          {!!platform.alias && isRegComplete && (
            <CopyClipboard
              component={
                <IconButton title="Copy invite link">
                  <Icon icon={copyFill} width={24} height={24} />
                </IconButton>
              }
              disabled={platform.status !== PlatformStatus.ACTIVE}
              copy={inviteLink} />
          )}
          <NotificationsPopover />
          <AccountPopover />
          <QuickActions />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
