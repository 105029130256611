/* eslint-disable no-nested-ternary */
import { Icon } from '@iconify/react';
import { useRef, forwardRef } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Divider,
    ListItemIcon,
    Typography,
    Link,
    List,
    ListItem,
    ListItemText,
    Popover
} from '@mui/material';

function MenuPopover({ children, sx, ...other }) {
    return (
        <Popover
            keepMounted
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            PaperProps={{
                sx: {
                    overflow: 'inherit',
                    boxShadow: (theme) => theme.shadows[12],
                    border: (theme) => `solid 1px ${theme.palette.grey[500_8]}`,
                    width: 200,
                    ...sx
                }
            }}
            {...other}
        >
            {/* <ArrowStyle /> */}

            {children}
        </Popover>
    );
}

export const MobileAppTargetOptions = ({ urlScheme }) => {
    const MENU_OPTIONS = [
        {
            label: 'Already downloaded the Communely app? Open app',
            linkTo: urlScheme
        },
        {
            label: 'Download on Appstore',
            linkTo: 'https://apps.apple.com/us/app/communely/id1612055811'
        },
        {
            label: 'Download on Play store',
            linkTo: "https://play.google.com/store/apps/details?id=com.communely.upd"
        },
    ];

    return (
        <List disablePadding>
            {MENU_OPTIONS.map((option) => (
                <ListItem
                    button
                    // href={option.linkTo}
                    disableGutters
                    key={option.label}
                    onClick={() => {
                        window.open(option.linkTo, "_blank")
                    }}
                    // component={Link}
                    sx={{
                        py: 1.5,
                        px: 2.5,
                        '&:not(:last-of-type)': { mb: '1px' },
                    }}
                    // target='_blank'
                >
                    <ListItemText primary={
                        <Typography variant="body1">
                            {option.label}
                        </Typography>
                    } />
                </ListItem>
            ))}
        </List>
    )
}

const DownloadApp = forwardRef(({ open, onClose, urlScheme }, ref) => {
    const MENU_OPTIONS = [
        {
            label: 'Open Communely app',
            linkTo: urlScheme
        },
        {
            label: 'Download on Appstore',
            linkTo: 'https://apps.apple.com/us/app/communely/id1612055811'
        },
        {
            label: 'Download on Play store',
            linkTo: "https://play.google.com/store/apps/details?id=com.communely.upd"
        },
    ];

    return (
        <MenuPopover
            open={open}
            onClose={onClose}
            anchorEl={ref.current}
            sx={{ width: { md: 320, xs: 280 } }}
        >
            <MobileAppTargetOptions urlScheme={urlScheme} />
        </MenuPopover>
    )
})

export default DownloadApp;
