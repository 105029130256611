import Page500 from '../views/Page500';
import LoadingScreen from './LoadingScreen';
import { PlatformUserStatus } from '../utils/constant';
import useCurrentPlatformDetails from '../hooks/useCurrentPlatformDetails';

export default function AccessControl({ permitedRoles = [], children, errorMsg, errorTitle, hide }) {
  const { platform, platformLoading } = useCurrentPlatformDetails();
  const userPlatformData = platform?.community_user || {};

  if (userPlatformData.status !== PlatformUserStatus.ACTIVE) return null;

  if (platformLoading && !hide) return <LoadingScreen />

  if (!permitedRoles.includes(userPlatformData.role)) {
    if (hide) return null;
    return <Page500 title={errorTitle || "Unauthorized"} desc={errorMsg || "You don't have the right permissions to access this page"} />
  }

  return <>{children}</>;
}
