import { lazy } from 'react';

import BusinessLayout from '../layouts/business/home';
import { PATH_EXPERIENCE } from './paths';

const ExperienceRoutes = {
  path: PATH_EXPERIENCE.root,
  layout: BusinessLayout,
  routes: [
    {
      exact: true,
      path: PATH_EXPERIENCE.experience,
      component: lazy(() => import('../views/experiences/Experience'))
    },
  ]
};

export default ExperienceRoutes;
