// material
import { Box, Typography, Card, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-outline';
import {
  styled,
  useTheme,
} from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export default function AddStreetBtn({ onOpenModal }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Button
      classes={{ label: classes.btnLabel }}
      onClick={onOpenModal}
      style={{
        width: '100%',
        padding: 0,
        height: '100%',
        background: 'transparent'
      }}
    >
      <RootStyle style={{ width: '100%', height: '100%' }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          <Icon
            icon={plusFill}
            style={{
              fontSize: 30,
              color: theme.palette.grey
            }}
          />
          <Typography variant="body2">Add Street</Typography>
        </Box>
      </RootStyle>
    </Button>
  );
}
const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  padding: theme.spacing(3)
}));

const useStyles = makeStyles({
  btnLabel: {
    height: '100%'
  }
});
