import { useEffect } from "react";
import { useForm } from 'react-hook-form';

import CreateWrapper from '../CreateWrapper';
import useUpsertPlatform from '../../../hooks/useUpsertPlatform';
import MainBankInformation from '../MainBankInformation';
import { getBusinessFormResetFields } from '../../../utils/createFormUtils';
import { defaultBusinessFormValues, RegistrationStages } from '../../../utils/constant';

export default function BankInformation({ 
  onBackStep, 
  description, 
  onRegistrationComplete, 
  noBackBtn, 
  actionBtnText, 
  ...extraProps 
}) {
  const useFormUtils = useForm({ defaultValues: defaultBusinessFormValues });
  const { handleSubmit, reset } = useFormUtils;

  useEffect(() => {
    reset(getBusinessFormResetFields(platform))
  }, [platform?.alias]);

  const {
    onUpsertPlatform,
    platform,
    upsertPlatformError,
    upsertPlatformLoading
  } = useUpsertPlatform({
    onSuccess: () => {
      onRegistrationComplete();
    }
  });

  const onSubmit = (values) => {
    const stage = platform.prerequisite_stages || [];
    onUpsertPlatform({
      ...values,
      bank_information: {
        account_holder: values.account_holder,
        account_number: values.account_number,
        bank_name: values.bank?.name
      },
      prerequisite_stages: [...stage, RegistrationStages.BASIC_INFORMATION]
    });
  };

  return (
    <CreateWrapper
      error={upsertPlatformError}
      isLoading={upsertPlatformLoading}
      description={description}
      noBackBtn={noBackBtn}
      onBackStep={onBackStep}
      actionBtnText={actionBtnText}
      actionBtnAction={handleSubmit(onSubmit)}
      {...extraProps}
    >
      <MainBankInformation useFormUtils={useFormUtils} />
    </CreateWrapper>
  );
}
