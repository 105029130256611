/* eslint-disable no-empty-pattern */
import { useQuery } from 'react-query';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import { IconButton } from '@mui/material';

import { getStreets } from '../services/zones';

export default function useStreets({ zoneId }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { data: { data } = {}, isLoading, refetch } = useQuery({
    queryKey: ['streets', { zoneId }],
    enabled: !!zoneId,
    queryFn: getStreets,
    onError: () => {
      enqueueSnackbar('Unable to fetch zones', {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    }
  });

  const { data: streets, ...pagination } = data || {};

  return {
    streets: streets || [],
    streetsLoading: isLoading,
    streetsPagination: pagination,
    refetchStreets: refetch
  };
}
