/* eslint-disable */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNetworkStatus } from "use-network-status";

import useCurrentPlatformDetails from '../../../hooks/useCurrentPlatformDetails';
import LoadingScreen from '../../../components/LoadingScreen';
import UnauthorizedLandingPage from './UnauthorizedLandingPage';

CommunityMemberDashboardLayout.propTypes = {
  children: PropTypes.node
};

export default function CommunityMemberDashboardLayout() {
  const { platformLoading } = useCurrentPlatformDetails();

  if (platformLoading) {
    return (
      <LoadingScreen />
    )
  }


  return <UnauthorizedLandingPage />;
}
