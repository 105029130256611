import businessCenter from '@iconify/icons-ic/outline-business-center';
import volumeIcon from '@iconify/icons-eva/volume-up-outline';
import activity from '@iconify/icons-fluent/shifts-activity-24-regular';
import { Icon } from '@iconify/react';

import { ROOTS_COMMUNITY } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { formatedRoles } from '../../../utils/constant';

const getIcon = (name, folder = 'navbar') => (
  <SvgIconStyle
    src={`/static/icons/${folder}/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const ICONS = {
  map: getIcon('ic_map'),
  money: getIcon('ic_money'),
  business: getIcon('business'),
  page: getIcon('ic_page'),
  user: getIcon('ic_user'),
  users: getIcon('ic_people-community'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  settings: getIcon('settings', 'controls')
};

const sidebarConfig = (communityCode, role) => {
  console.log("rolesssssssss ", role)
  const basePath = `${ROOTS_COMMUNITY}/${communityCode}/admin`;
  const isAdmin = Object.values(formatedRoles).filter(el => el.isAdmin).map(el => el.role).includes(role);
  return [
    {
      items: [
        {
          title: 'overview',
          href: `${basePath}/overview`,
          icon: ICONS.analytics,
          accessNavBar: true
        },
        {
          title: 'Personnel',
          icon: ICONS.users,
          accessNavBar: isAdmin,
          href: `${basePath}/personnel`,
          items: [
            {
              title: 'Members',
              href: `${basePath}/personnel/members`,
              accessNavBar: isAdmin
            },
            {
              title: 'Admins',
              href: `${basePath}/personnel/admins`,
              accessNavBar: isAdmin
            },
            // {
            //   title: 'Security',
            //   href: `${basePath}/personnel/securities`,
            //   accessNavBar: isAdmin
            // }
          ]
        },
        {
          title: 'businesses',
          href: `${basePath}/businesses`,
          icon: <Icon icon={businessCenter} width={24} height={24} />,
          accessNavBar: isAdmin
        },
        {
          title: 'Cash flow',
          href: `${basePath}/cash-flow`,
          icon: ICONS.money,
          accessNavBar: true
        },
        // {
        //   title: 'Activities',
        //   href: `${basePath}/activities`,
        //   icon: <Icon icon={activity} width={24} height={24} />,
        //   accessNavBar: true
        // },
        // {
        //   title: 'Announcements',
        //   href: `${basePath}/announcements`,
        //   icon: <Icon icon={volumeIcon} width={24} height={24} />,
        //   accessNavBar: true
        // },
        // {
        //   title: 'Integrations',
        //   icon: <Icon icon={plugIcon} width={24} height={24} />,
        //   accessNavBar: isAdmin,
        //   href: `${basePath}/integrations`,
        //   items: [
        //     {
        //       title: 'CCTVs',
        //       href: `${basePath}/integrations/cctv`,
        //       accessNavBar: isAdmin,
        //       label: "coming soon"
        //     },
        //     {
        //       title: 'Barriers',
        //       href: `${basePath}/integrations/barriers`,
        //       accessNavBar: isAdmin,
        //       label: "coming soon"
        //     },
        //     // {
        //     //   title: 'Alarms',
        //     //   href: `${basePath}/integrations/alarms`,
        //     //   accessNavBar: isAdmin
        //     // },
        //     {
        //       title: 'Card Readers',
        //       href: `${basePath}/integrations/readers`,
        //       accessNavBar: isAdmin,
        //       label: "coming soon"
        //     }
        //   ]
        // },
        {
          title: 'Management',
          icon: ICONS.settings,
          accessNavBar: isAdmin,
          href: `${basePath}/management`,
          items: [
            // {
            //   title: 'Zones',
            //   href: `${basePath}/management/zones`,
            //   accessNavBar: isAdmin
            // },
            {
              title: 'Account',
              href: `${basePath}/management/account`,
              accessNavBar: isAdmin
            }
          ]
        }
      ]
    }
  ];
};

export default sidebarConfig;
