import { useState, useEffect } from 'react';
import { Card } from '@mui/material';

import ResidentsTable from './ResidentsTable';
import usePlatformUsers from '../../hooks/usePlatformUsers';
import { ROOTS_COMMUNITY } from '../../routes/paths';
import useCurrentPlatformDetails from '../../hooks/useCurrentPlatformDetails';
import { PlatformUserStatus } from '../../utils/constant';

export default function ResidentsList({
  moreFilterParams = {},
  showLessContent,
  baseUrl
}) {
  const { platform } = useCurrentPlatformDetails();
  const [filterParams, setFilterParams] = useState({
    status: PlatformUserStatus.ACTIVE,
    page: 1,
    page_size: 10,
    search: "",
    ...moreFilterParams
  });
  const _baseUrl = baseUrl || `${ROOTS_COMMUNITY}/${platform.alias}/admin/personnel`;

  const { users, usersPagination, refetchUsers, usersLoading } = usePlatformUsers(filterParams);

  const handleChangeSort = (changeSet) => {
    setFilterParams((prevState) => ({
      ...prevState,
      ...changeSet
    }));
  };

  useEffect(() => {
    setFilterParams((prevState) => ({
      ...prevState,
      ...moreFilterParams
    }));
  }, Object.values(moreFilterParams));

  return (
    <Card>
      <ResidentsTable
        showLessContent={showLessContent}
        users={users}
        baseUrl={_baseUrl}
        pagination={usersPagination}
        filterParams={filterParams}
        onChangePageSize={handleChangeSort}
        loading={usersLoading}
        refetchUsers={refetchUsers} />
    </Card>
  );
};