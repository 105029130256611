import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const CreatePostContext = createContext({});

const CreatePostProvider = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <CreatePostContext.Provider
      value={{
        open: openDrawer,
        onClose: () => setOpenDrawer(false),
        onOpen: () => setOpenDrawer(true),
      }}
    >
      {children}
    </CreatePostContext.Provider>
  );
};

CreatePostProvider.propTypes = {
  children: PropTypes.node
};

export default CreatePostProvider;
