// material
import { Box, Typography, Card, CardContent, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

export default function OverallSummary() {
  return (
    <Card>
      <CardContent>
        <DwellersCount>
          <Box sx={{ flex: 1, textAlign: 'center' }}>
            <Typography variant="h3">7</Typography>
            <Typography variant="subtitle1">Streets</Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ flex: 1, textAlign: 'center' }}>
            <Typography variant="h3">39</Typography>
            <Typography variant="subtitle1">Residents</Typography>
          </Box>
        </DwellersCount>
      </CardContent>
    </Card>
  );
}

const DwellersCount = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_8]
}));
