import PropTypes from 'prop-types';
// redux
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

JwtProvider.propTypes = {
  children: PropTypes.node
};

export default function JwtProvider({ children }) {
  useAuth();
  return <>{children}</>;
}
