
import axios from '../utils/axios';
import { parseComplexQueryParams } from '../utils/parseQuery';

export function getPlatformUsers({ queryKey }) {
  const [, { id, ...queryParams }] = queryKey;
  const params = parseComplexQueryParams(queryParams);
  const response = axios.get(`/community/${id}/users`, {
    params
  });
  return response;
}

export function getUsers({ queryKey }) {
  const [, queryParams] = queryKey;
  const params = parseComplexQueryParams(queryParams);
  const response = axios.get(`/users`, {
    params
  });
  return response;
}

export function updateUser({ userId, data }) {
  const response = axios.patch(`/users/${userId}`, data);
  return response;
}

export function updatePlatformUser({ userId, data }) {
  const response = axios.patch(`/settlement-users/${userId}`, data);
  return response;
}

export function getPlatformUserById({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/community/${queryParams.userId}/details/`);
  return response;
}

export function approvePlatformUser({ communityId, ...data }) {
  const response = axios.post(`/community/${communityId}/community-user/approve/`, data);
  return response;
}

export function acceptCommunityInvite(id) {
  const response = axios.post(`/community/${id}/accept-invite/`);
  return response;
}

export function joinCommunityRequest(id) {
  const response = axios.post(`/community/${id}/join-community/`);
  return response;
}

export function declinePlatformUser({ communityId }) {
  const response = axios.post(`/community/${communityId}/reject-invite/`);
  return response;
}

export function cancelInvite({ communityId, ...data }) {
  const response = axios.post(`/community/${communityId}/community-user/cancel-invitation/`, data);
  return response;
}
