import { useRef, useState } from 'react';
import { Box, Typography, Button, Link } from '@mui/material';

import MenuPopover from './MenuPopover';

export function OauthButton() {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link href='https://apps.apple.com/us/app/communely/id1612055811' sx={{ mr: 2 }}>
                <Box
                    component="img"
                    alt="empty content"
                    src="/static/apple_button.svg"
                />
            </Link>
            <Link href='https://play.google.com/store/apps/details?id=com.communely.upd'>
                <Box
                    component="img"
                    alt="empty content"
                    src="/static/google_button.svg"
                />
            </Link>
        </Box>
    );
}

export default function GetStartedButton({ btnTheme, sx = {}, text, ...btnProps }) {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                size="large"
                variant="contained"
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    color: btnTheme === "dark" ? 'common.white' : 'common.black',
                    bgcolor: btnTheme === "dark" ? 'common.black' : 'common.white',
                    width: "max-content",
                    "&:hover": {
                        bgcolor: btnTheme === "dark" ? 'rgba(0,0,0, .8)' : 'rgba(255,255,255, .8)'
                    },
                    ...sx
                }}
                {...btnProps}
            >
                {text || "Get Started"}
            </Button>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: "max-content" }}

            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        Get the app
                    </Typography>
                    <OauthButton />
                </Box>
            </MenuPopover>
        </>
    );
}
