import CircleIcon from '@mui/icons-material/Circle';
import { Typography, Box } from '@mui/material';

export default function StatusPopover({ status, color }) {
    return (
        <Box
            display="flex"
            alignItems="center"
            sx={{
                color: 'grey.500',
                borderRadius: '25px',
                px: 1,
                py: .5,
                border: theme => `1px solid ${theme.palette.grey[300]}`
            }}
        >
            <Typography variant="caption" sx={{ mr: .5 }}>
                {status?.split("_").join("-").toLowerCase()}
            </Typography>
            <CircleIcon sx={{ fontSize: 12, color }} />
        </Box>
    );
}
