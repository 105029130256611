import axios from '../utils/axios';
import { parseComplexQueryParams } from '../utils/parseQuery';

export function getPlatforms({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/settlements`, {
    params: parseComplexQueryParams(queryParams)
  });
  return response;
}

export function getPlatformCustomForms({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/forms/`, {
    params: parseComplexQueryParams(queryParams)
  });
  return response;
}

export function getCustomFormResponses({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/custom-form-response`, {
    params: parseComplexQueryParams(queryParams)
  });
  return response;
}

export function getPlatformCustomForm({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/forms/${queryParams.formId}`);
  return response;
}

export function createPlatformCustomForm(data) {
  const response = axios.post('/forms/', data);
  return response;
}

export function createPlatformCustomFormResponse(data) {
  const response = axios.post('/custom-form-response', data);
  return response;
}

export function updatePlatformCustomForm({ formId, ...data }) {
  const response = axios.patch(`/forms/${formId}/`, data);
  return response;
}

export function deletePlatformCustomForm({ formId }) {
  const response = axios.delete(`/forms/${formId}/`);
  return response;
}

export function createPlatform(data) {
  const response = axios.post('/community/basic/', data);
  return response;
}

// export function createPlatform(data) {
//   const response = axios.post('/settlements', data);
//   return response;
// }

export function addServiceCharge(data) {
  const response = axios.post('/service-charges', data);
  return response;
}

export function updateServiceCharge({ chargeId, ...data }) {
  const response = axios.patch(`/service-charges/${chargeId}`, data);
  return response;
}

export function getPlatformServiceCharges({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/service-charges/`, {
    params: queryParams
  });
  return response;
}

export function getTransactionLogs({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/transaction-logs/`, {
    params: queryParams
  });
  return response;
}

export function deleteServiceCharge({ chargeId }) {
  const response = axios.delete(`/service-charges/${chargeId}`);
  return response;
}

export function updatePlatform({ platformId, data }) {
  const response = axios.patch(`/community/${platformId}/`, data);
  return response;
}

export function getPlatformById({ queryKey }) {
  const [, queryParams] = queryKey;

  if (!queryParams.platformId) {
    throw new Error("Settlement ID is required");
  }

  const response = axios.get(`/community/${queryParams.platformId}`);
  return response;
}

export function getPlatformByCode({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/community/${queryParams.alias}/info/`);
  return response;
}

export function registerMultiplePlatformUsers(data) {
  const response = axios.post('/settlement-users/json-bulk-upload', data);
  return response;
}

export function registerPlatformUser({ id, ...data }) {
  const response = axios.post(`/community/${id}/invite-user/`, data);
  return response;
}
