/* eslint-disable no-empty-pattern */

import { useQuery } from 'react-query';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import { IconButton } from '@mui/material';

import useCurrentPlatformDetails from './useCurrentPlatformDetails';
import { getZones } from '../services/zones';
import useAuth from './useAuth';
import { PlatformStatus } from '../utils/constant';

export default function useZones() {
  const { platform } = useCurrentPlatformDetails();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    user: { settlements = [] }
  } = useAuth();
  const activeSettlement = settlements.find(
    (settlement) => settlement.alias === platform.alias
  );

  const { data: { data } = {}, isLoading, refetch } = useQuery({
    queryKey: ['zones', { settlementId: platform?.id }],
    enabled: activeSettlement?.community_user_status === PlatformStatus.ACTIVE,
    queryFn: getZones,
    onError: () => {
      enqueueSnackbar('Unable to fetch zones', {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    }
  });

  const { data: zones, ...pagination } = data || {};

  return {
    zones: zones || [],
    zonesPagination: pagination,
    zonesLoading: isLoading,
    refetchZones: refetch
  };
}
