/* eslint-disable no-empty-pattern */
import { useParams, useLocation } from 'react-router-dom';

import useGetPlatformByCode from './useGetPlatformByCode';
import useGetPlatformById from './useGetPlatformById';

export default function useCurrentPlatformDetails(queryArgs = {}) {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const _communityCode = urlParams.get('communityCode');
  const { communityCode } = useParams();

  const code = communityCode || _communityCode;

  return useGetPlatformById({ platformId: code })
}
