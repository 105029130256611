import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import HomeNavbar from './HomeNavbar';

HomeLayout.propTypes = {
  children: PropTypes.node
};

export default function HomeLayout({ children }) {
  return (
    <Box sx={{ height: '100%' }}>
      <HomeNavbar />
      <Box
        sx={{
          height: '100%',
          zIndex: -2,
          marginTop: { xs: "64px", md: "76px" }
        }}>{children}</Box>
    </Box>
  );
}
