import { MAvatar } from './@material-extend';

export default function MyAvatar({ photoURL, alt, ...other }) {
  return (
    <MAvatar
      src={photoURL}
      alt={alt}
      {...other}
    />
  );
}
