import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import HomeNavbar from './HomeNavbar';

BusinessLayout.propTypes = {
  children: PropTypes.node
};

export default function BusinessLayout({ children }) {
  return (
    <Box sx={{ height: '100%' }}>
      <HomeNavbar />
      <Box sx={{ height: '100%', zIndex: -2 }}>{children}</Box>
    </Box>
  );
}
