// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  CardHeader,
  Typography,
  CardContent,
  Divider
} from '@mui/material';

export default function DwellersCountSummary() {
  return (
    <Card>
      <CardHeader title="Top Authors" />
      <CardContent>
        <DwellersCount>
          <Box sx={{ flex: 1, textAlign: 'center' }}>
            <Typography variant="h4">230</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Follower
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ flex: 1, textAlign: 'center' }}>
            <Typography variant="h4">29</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Following
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ flex: 1, textAlign: 'center' }}>
            <Typography variant="h4">29</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Following
            </Typography>
          </Box>
        </DwellersCount>
      </CardContent>
    </Card>
  );
}

const DwellersCount = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_8]
}));
