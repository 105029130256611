import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { Card, Box, Typography, CardContent, Grid, Stack } from '@mui/material';

import { fNumber } from '../../utils/formatNumber';
import { BaseOptionChart } from '../charts';
import useZones from '../../hooks/useZones';

const CHART_DATA = [0];

export default function ResidentsByZone() {
  const theme = useTheme();
  const { zones } = useZones();
  const labels = zones.map(zone => zone.name);
  const colors = BaseOptionChart().colors.map(color => color);

  const chartOptions = merge(BaseOptionChart(), {
    colors,
    labels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center', show: false },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `#${seriesName}`
        }
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            value: {
              formatter: (val) => fNumber(val)
            },
            total: {
              formatter: (w) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return fNumber(sum);
              }
            }
          }
        }
      }
    }
  });

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1" sx={{ pb: 3 }}>Members by zone</Typography>
            <Stack>
              {labels.map((label, index) => (
                <Box
                  display="flex"
                  alignItems="center"
                  key={index}
                  mb={theme.spacing(2)}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      height: 10,
                      width: 10,
                      borderRadius: 10,
                      background: colors[index]
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ ml: theme.spacing(3), opacity: 0.7 }}
                  >
                    {label}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <ReactApexChart
              type="donut"
              series={CHART_DATA}
              options={chartOptions}
              height={280}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
