import businessCenter from '@iconify/icons-ic/outline-business-center';
import { Icon } from '@iconify/react';

import { ROOTS_BUSINESS } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name, folder = 'navbar') => (
  <SvgIconStyle
    src={`/static/icons/${folder}/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const ICONS = {
  money: getIcon('ic_money'),
  business: getIcon('business'),
  page: getIcon('ic_page'),
  user: getIcon('ic_user'),
  users: getIcon('ic_people-community'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  settings: getIcon('settings', 'controls')
};

const sidebarConfig = (businessId) => {
  const basePath = `${ROOTS_BUSINESS}/${businessId}/dashboard`;
  return [
    {
      items: [
        {
          title: 'overview',
          href: `${basePath}/overview`,
          icon: ICONS.analytics,
          accessNavBar: true
        },
        {
          title: 'Orders',
          href: `${basePath}/orders`,
          icon: ICONS.users,
          accessNavBar: true
        },
        {
          title: 'offers',
          href: `${basePath}/offerings`,
          icon: <Icon icon={businessCenter} width={24} height={24} />,
          accessNavBar: true
        },
        {
          title: 'communities',
          href: `${basePath}/community`,
          icon: ICONS.analytics,
          accessNavBar: true
        },
        {
          title: 'Cash flow',
          href: `${basePath}/cash-flow`,
          icon: ICONS.money,
          accessNavBar: true
        },
        {
          title: 'Management',
          icon: ICONS.settings,
          accessNavBar: true,
          href: `${basePath}/management`,
          items: [
            {
              title: 'Personels',
              href: `${basePath}/management/personels`,
              accessNavBar: true
            },
            {
              title: 'Account',
              href: `${basePath}/management/account`,          
              accessNavBar: true
            }
          ]
        }
      ]
    }
  ];
};

export default sidebarConfig;
