// material
import { Box, Typography, Card, Skeleton, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import mapFillIcon from '@iconify/icons-eva/pin-fill';
import menu2Fill from '@iconify/icons-eva/edit-2-fill';
import {
  styled,
  useTheme
} from '@mui/material/styles';

export default function SummaryCard({ title, text1, text2, url, isLoading, iconBtnAction }) {
  const theme = useTheme();

  if (isLoading) {
    return <Skeleton sx={{ flex: 1, height: 200 }} />;
  }

  return (
    <RootStyle>
      {/* <Link to={url} style={{ color: 'inherit', textDecoration: 'none', width: "100%" }}> */}
        <Box sx={{ width: "100%" }}>
          <Typography variant="subtitle1" pb={theme.spacing(1.5)}>
            {title}
          </Typography>
          <Typography color="GrayText" variant="subtitle2">
            {text1}
          </Typography>
        </Box>
        {/* <IconStyle icon={mapFillIcon} /> */}
      {/* </Link> */}
      {/* {!!iconBtnAction && <IconButton
        size="medium"
        variant="contained"
        onClick={iconBtnAction}
        sx={{
          position: "absolute",
          top: 0,
          padding: 2,
          right: 0,
          // zIndex: 10
        }}
      >
        <Icon icon={menu2Fill} />
      </IconButton>} */}
    </RootStyle>
  );
}
const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: 'none',
  height: "100%",
  border: `solid 1px ${theme.palette.grey[500_32]}`
}));

const IconStyle = styled(Icon)(({ theme }) => ({
  width: 90,
  height: 90,
  opacity: 0.12,
  position: 'absolute',
  right: theme.spacing(-1),
  color: theme.palette.grey[500_32]
}));
