import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNetworkStatus } from "use-network-status";
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { Icon } from '@iconify/react';
// material
import { Box, Typography, Button, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import useCurrentPlatformDetails from '../../../hooks/useCurrentPlatformDetails';
import LoadingScreen from '../../../components/LoadingScreen';
import useAuth from '../../../hooks/useAuth';
import { formatedRoles, PlatformUserStatus, RegistrationStages, Roles } from '../../../utils/constant';
import Page500 from '../../../views/Page500';
import { getPlatformUserById } from '../../../services/users';
import { varFadeInUp, MotionInView } from '../../../components/animate';
import CopyClipboard from '../../../components/CopyClipboard';
import AuthLayout from '../../authentication';
import AcceptInvite from '../../../components/authentication/register/AcceptInvite';
import { ROOTS_COMMUNITY } from '../../../routes/paths';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 72;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(5),
  background: theme.palette.grey[100],
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default function DashboardLayout({ children }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const isOnline = useNetworkStatus();
  const { platform, platformLoading, refetchPlatform } = useCurrentPlatformDetails();
  const userPlatformDetails = platform?.community_user; // user?.community?.filter(el => el.alias?.toLowerCase() === platform.alias)[0];
  const isAdmin = userPlatformDetails?.role === Roles.SETTLEMENT_ADMIN; // Object.values(formatedRoles).filter(el => el.isAdmin).map(el => el.role).includes(userPlatformDetails?.role);

  const isRegComplete = platform.prerequisite_stages?.includes(RegistrationStages.BASIC_INFORMATION);



  // useEffect(() => {
  //   if (platform.alias) {
  //     if (!isRegComplete) {
  //       history.push(`${ROOTS_COMMUNITY}/create?communityCode=${platform.alias}`);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [isRegComplete, pathname]);

  // useEffect(() => {
  //   if (isOnline) {
  //     refetchPlatform();
  //   }
  //   // eslint-disable-next-line
  // }, [isOnline]);

  // const { data: { data: settlementUserData } = {}, isLoading: settlementUserDataLoading } = useQuery({
  //   queryKey: ['settlementUser', { userId: userPlatformDetails?.community_user_id }],
  //   enabled: !!userPlatformDetails?.community_user_id,
  //   queryFn: getPlatformUserById,
  // });

  if (platformLoading) {
    return (
      <LoadingScreen />
    )
  }

  const renderGoBackBtn = () => (
    <Button
      sx={{ mt: 3 }}
      variant="text"
      color="inherit"
      onClick={() => history.goBack()}
      startIcon={
        <Icon
          icon={arrowIosBackFill}
          style={{ color: theme.palette.text.primary }}
        />
      }
    >
      Go Back
    </Button>
  );

  const renderAcceptInvite = () => (
    <AuthLayout pageTitle="Join | communely">
      <AcceptInvite
        settlementUser={userPlatformDetails}
        renderGoBackBtn={renderGoBackBtn} />
    </AuthLayout>
  )

  const renderPending = () => (
    <AuthLayout pageTitle="Join | communely">
      <Box sx={{ textAlign: 'center', }}>
        <Box
          component="img"
          alt="Pending illustration"
          src="/static/loading.svg"
          sx={{ width: 100, height: 100, margin: "auto" }}
        />
        <Typography variant="h4" sx={{ my: 3 }}>Your request is pending</Typography>
        <MotionInView
          variants={varFadeInUp}
          sx={{ height: "100%", width: "100%" }}
        >
          <Box sx={{ textAlign: "center" }} >
            {/* <Box my={3} sx={{ textAlign: "center" }}>
              <CopyClipboard text={`Member code: ${settlementUserData?.data?.identification_code}`} copy={settlementUserData?.data?.identification_code} />
              </Box> */}
            <Typography>Visit <b>{userPlatformDetails?.name}</b>'s admin in person to approve your residency.</Typography>

            <Typography>This measure is put in place to ensure a safe and liveable community for all resident.</Typography>
          </Box>
          {renderGoBackBtn()}
        </MotionInView>
      </Box>
    </AuthLayout>
  );


  if (userPlatformDetails?.status === PlatformUserStatus.ACTIVE && isAdmin) {
    return (
      <RootStyle>
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
          isRegComplete={isRegComplete}
        />
        <MainStyle>{children}</MainStyle>
      </RootStyle>
    );
  }


  if (!isOnline) {
    return <Page500 title="Network error" desc="Seems like there is an issue with your internet connection." />;
  };

  if (userPlatformDetails?.status === PlatformUserStatus.IN_ACTIVE || !isAdmin) {
    return <Redirect to={`${ROOTS_COMMUNITY}/${platform.alias}`} />;
  };

  if (userPlatformDetails?.status === PlatformUserStatus.PENDING) {
    if (userPlatformDetails?.invited_by?.id) {
      return renderAcceptInvite();
    };
    return renderPending();
  }

  return null;
}
