// ----------------------------------------------------------------------

export default function Timeline(theme) {
  return {
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },

    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.divider
        }
      }
    },

    MuiTimelineItem: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none",
            padding: 0
          }
        },
      }
    },
  };
}
