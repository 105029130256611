/* eslint-disable no-nested-ternary */
import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    ListItemIcon,
    Typography,
    List,
    ListItem,
    ListItemText,
    IconButton
} from '@mui/material';

import MenuPopover from '../components/MenuPopover';
import { PATH_BUSINESS, PATH_AUTH } from '../routes/paths';

const MENU_OPTIONS = [
    {
        label: 'Home',
        icon: homeFill,
        linkTo: '/'
    },
    {
        label: 'Services',
        icon: personFill,
        linkTo: PATH_BUSINESS.busniesses
    },
];

export default function MoreMenu() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                edge="end"
                sx={{ ml: 2 }}
                onClick={handleOpen}>
                <MenuIcon />
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 220 }}
            >
                <List disablePadding>
                    {MENU_OPTIONS.map((option) => (
                        <ListItem
                            button
                            to={option.linkTo}
                            disableGutters
                            key={option.label}
                            component={RouterLink}
                            sx={{
                                py: 1.5,
                                px: 2.5,
                                '&:not(:last-of-type)': { mb: '1px' },
                            }}
                        >
                            <ListItemIcon>
                                <Icon icon={option.icon} />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography variant="subtitle2" noWrap>
                                    {option.label}
                                </Typography>
                            } />
                        </ListItem>
                    ))}
                </List>


                <Box sx={{ p: 2 }}>
                <LoadingButton
                        fullWidth
                        variant="contained"
                        component={RouterLink}
                        to={`${PATH_AUTH.login}`}
                        color="inherit"
                        sx={{ mb: 1 }}
                    >
                        sign in
                    </LoadingButton>

                    <LoadingButton
                        fullWidth
                        variant="outlined"
                        color="inherit"
                        component={RouterLink}
                        to={`${PATH_AUTH.register}`}
                    >
                        sign up
                    </LoadingButton>
                </Box>
            </MenuPopover>
        </>
    );
}
