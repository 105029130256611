/* eslint-disable */
import moment from "moment";
import {
    PersonAddAlt,
    ManageAccountsOutlined,
    CancelScheduleSendOutlined,
    PauseCircleOutlineOutlined,
    ThumbDownAltOutlined,
    ThumbUpAltOutlined,
    ChangeCircleOutlined
} from '@mui/icons-material';

import { ActivitiesType } from "./constant";

const activityTypeText = {
    [ActivitiesType.ADMIN_INVITE]: ({ actorName, objectName }) => <>{actorName} invited {objectName} to be an admin</>,
    [ActivitiesType.SECURITY_INVITE]: ({ actorName, objectName }) => <>{actorName} invited {objectName} to be a security</>,
    [ActivitiesType.DWELLER_INVITE]: ({ actorName, objectName }) => <>{actorName} invited {objectName} to be a dweller</>,



    [ActivitiesType.SETTLEMENT_APPROVE]: ({ }) => <>Community approved </>,
    [ActivitiesType.CREATE_SETTLEMENT]: ({ actorName }) => <>{actorName} created this community</>,
    [ActivitiesType.SETTLEMENT_REVIEW]: ({ actorName }) => <>{actorName} change community status to "in review"</>,


    [ActivitiesType.APPROVE_USER]: ({ actorName, objectName }) => <>{actorName} approved {objectName}'s request to be a member</>,
    [ActivitiesType.DECLINE_USER]: ({ actorName, objectName }) => <>{actorName} declined {objectName}'s request to be a member</>,
    [ActivitiesType.SUSPEND_USER]: ({ actorName, objectName }) => <>{actorName} suspended {objectName}'s membership</>,
    [ActivitiesType.RESTORE_USER]: ({ actorName, objectName }) => <>{actorName} restored {objectName}'s membership from suspension</>,
    [ActivitiesType.CANCEL_INVITE]: ({ actorName, objectName }) => <>{actorName} canceled invite sent to {objectName}</>,
    [ActivitiesType.ADD_USER_ZONE]: ({ actorName, objectName }) => <>{actorName} changed {objectName}'s zone</>,
    [ActivitiesType.ADD_USER_STREET]: ({ actorName, objectName }) => <>{actorName} changed {objectName}'s street</>,
};

const activityTypeIcon = {
    [ActivitiesType.ADMIN_INVITE]: ManageAccountsOutlined,
    [ActivitiesType.DWELLER_INVITE]: PersonAddAlt,



    [ActivitiesType.SETTLEMENT_APPROVE]: ThumbUpAltOutlined,


    [ActivitiesType.APPROVE_USER]: ThumbUpAltOutlined,
    [ActivitiesType.DECLINE_USER]: ThumbDownAltOutlined,
    [ActivitiesType.SUSPEND_USER]: PauseCircleOutlineOutlined,
    [ActivitiesType.RESTORE_USER]: ChangeCircleOutlined,
    [ActivitiesType.CANCEL_INVITE]: CancelScheduleSendOutlined,
    [ActivitiesType.ADD_USER_ZONE]: ChangeCircleOutlined,
    [ActivitiesType.ADD_USER_STREET]: ChangeCircleOutlined,
};

const activityTypeColor = {
    [ActivitiesType.ADMIN_INVITE]: "info",
    [ActivitiesType.DWELLER_INVITE]: "info",



    [ActivitiesType.SETTLEMENT_APPROVE]: "success",
    [ActivitiesType.CREATE_SETTLEMENT]: "info",
    [ActivitiesType.SETTLEMENT_REVIEW]: "info",


    [ActivitiesType.APPROVE_USER]: "success",
    [ActivitiesType.DECLINE_USER]: "error",
    [ActivitiesType.SUSPEND_USER]: "error",
    [ActivitiesType.RESTORE_USER]: "success",
    [ActivitiesType.CANCEL_INVITE]: "error",
    [ActivitiesType.ADD_USER_ZONE]: "info",
    [ActivitiesType.ADD_USER_STREET]: "info",
};

export const parseActivity = ({ 
    activity, 
    user = {}
}) => {
    const {
        inserted_at,
        type,
        actor_user_id,
        object_user_id: { firstname, lastname, email, id }
    } = activity;

    const actorName = actor_user_id.id === user.id ? "You" : `${actor_user_id.firstname} ${actor_user_id.lastname}`;
    const objectName = id === user.id ? "You" : !!firstname ? `${firstname} ${lastname}` : email;
    const description = activityTypeText[type]({ actorName, objectName });

    return {
        description,
        icon: activityTypeIcon[type],
        time: moment(inserted_at).format("MMM Do, h:mma"),
        avatarColor: activityTypeColor[type],
    };
}