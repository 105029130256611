import { lazy } from 'react';
import { formatedRoles } from '../utils/constant';
import AuthGuard from '../guards/AuthGuard';
// layouts
import DashboardLayout from '../layouts/community/adminDashboard';
import { PATH_COMMUNITY_DASHBOARD } from './paths';

const OverviewPage = lazy(() => import('../views/community/adminDashboard/overview'));

const adminRoles = Object.values(formatedRoles).filter(role => role.isAdmin).map(role => role.role);

const Blocks = lazy(() =>
  import('../views/community/adminDashboard/management/zones/Blocks')
);

const overviewRoutes = [
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD?.overview.root,
    component: OverviewPage
  },
];

const residentsRoute = [
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD?.personnel.members,
    permission: adminRoles,
    component: lazy(() => import('../views/community/adminDashboard/personel/members'))
  },
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD?.personnel.member,
    permission: adminRoles,
    component: lazy(() => import('../views/community/adminDashboard/personel/members/member'))
  },
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD.personnel.admins,
    permission: adminRoles,
    component: lazy(() =>
      import('../views/community/adminDashboard/personel/admins')
    )
  },
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD.personnel.admin,
    permission: adminRoles,
    component: lazy(() =>
      import('../views/community/adminDashboard/personel/admins/admin')
    )
  },
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD.personnel.securities,
    permission: adminRoles,
    component: lazy(() =>
      import('../views/community/adminDashboard/personel/securities')
    )
  },
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD.personnel.security,
    permission: adminRoles,
    component: lazy(() =>
      import('../views/community/adminDashboard/personel/securities/security')
    )
  },
];

const businessesRoute = [
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD?.businesses.root,
    component: lazy(() => import('../views/community/adminDashboard/businesses')),
    permission: adminRoles,
  },
  // {
  //   exact: true,
  //   path: PATH_COMMUNITY_DASHBOARD?.business,
  //   component: lazy(() => import('../views/create/business/UpsertBusiness'))
  // },
];

const cashFlowRoute = [
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD?.cashFlow.root,
    component: lazy(() => import('../views/community/adminDashboard/cashFlow'))
  }
];

const activitiesRoute = [
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD?.activities.root,
    component: lazy(() => import('../views/community/adminDashboard/activities'))
  }
];

const announcementsRoute = [
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD?.announcements.root,
    component: lazy(() => import('../views/community/adminDashboard/announcements'))
  },
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD?.announcements.new,
    component: lazy(() => import('../views/community/adminDashboard/announcements/NewAnnouncement'))
  },
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD?.announcements.edit,
    component: lazy(() => import('../views/community/adminDashboard/announcements/EditAnnouncement'))
  },
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD?.announcements.announcement,
    component: lazy(() => import('../views/community/adminDashboard/announcements/Announcement'))
  },
];

// const integrationsRoute = [
//   {
//     exact: true,
//     path: PATH_COMMUNITY_DASHBOARD?.integrations.cctv,
//     component: lazy(() => import('../views/community/adminDashboard/integrations/CCTVs'))
//   },
//   {
//     exact: true,
//     path: PATH_COMMUNITY_DASHBOARD?.integrations.barriers,
//     component: lazy(() => import('../views/community/adminDashboard/integrations/Barriers'))
//   },
//   {
//     exact: true,
//     path: PATH_COMMUNITY_DASHBOARD?.integrations.alarms,
//     component: lazy(() => import('../views/community/adminDashboard/integrations/Alarms'))
//   },
//   {
//     exact: true,
//     path: PATH_COMMUNITY_DASHBOARD?.integrations.readers,
//     component: lazy(() => import('../views/community/adminDashboard/integrations/SmartCardReaders'))
//   },
// ];

const managementRoutes = [
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD.management.zones,
    permission: adminRoles,
    component: lazy(() =>
      import('../views/community/adminDashboard/management/zones/Zones')
    )
  },
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD.management.zone,
    permission: adminRoles,
    component: lazy(() =>
      import('../views/community/adminDashboard/management/zones/Zone')
    )
  },
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD.management.street,
    permission: adminRoles,
    component: lazy(() =>
      import('../views/community/adminDashboard/management/zones/Street')
    )
  },
  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD.management.block,
    permission: adminRoles,
    component: Blocks
  },

  {
    exact: true,
    path: PATH_COMMUNITY_DASHBOARD.management.account,
    permission: adminRoles,
    component: lazy(() =>
      import('../views/community/home/AccountSettings/Account')
    )
  }
];

const mainDashboardRoutes = [
  ...activitiesRoute,
  ...overviewRoutes,
  ...residentsRoute,
  // ...integrationsRoute,
  ...cashFlowRoute,
  ...managementRoutes,
  ...businessesRoute,
  ...announcementsRoute
];

const DashboardRoutes = {
  path: PATH_COMMUNITY_DASHBOARD?.root,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: mainDashboardRoutes
};

export default DashboardRoutes;
