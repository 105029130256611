import { lazy } from 'react';
import BusinessDashboardLayout from '../layouts/business/businessDashboardLayout';
import AuthGuard from '../guards/AuthGuard';

import { PATH_BUSINESS_DASHBOARD } from './paths';

const OverviewPage = lazy(() => import('../views/businessDashboard/overview'));

const overviewRoutes = [
  {
    exact: true,
    path: PATH_BUSINESS_DASHBOARD?.overview.root,
    component: OverviewPage
  },
];

const offeringsRoute = [
  {
    exact: true,
    path: PATH_BUSINESS_DASHBOARD?.offerings.root,
    component: lazy(() => import('../views/businessDashboard/offerings')),
    
  }
];

const communityRoute = [
  {
    exact: true,
    path: PATH_BUSINESS_DASHBOARD?.community.root,
    component: lazy(() => import('../views/businessDashboard/community')),
    
  }
];

const cashFlowRoute = [
  {
    exact: true,
    path: PATH_BUSINESS_DASHBOARD?.cashFlow.root,
    component: lazy(() => import('../views/businessDashboard/cashFlow'))
  }
];

const ordersRoute = [
  {
    exact: true,
    path: PATH_BUSINESS_DASHBOARD?.orders.root,
    component: lazy(() => import('../views/businessDashboard/orders'))
  }
];

const managementRoutes = [
  {
    exact: true,
    path: PATH_BUSINESS_DASHBOARD.management.personels,
    
    component: lazy(() =>
      import('../views/businessDashboard/management/Personels')
    )
  },

  {
    exact: true,
    path: PATH_BUSINESS_DASHBOARD.management.account,
    
    component: lazy(() =>
      import('../views/businessDashboard/management/Account')
    )
  }
];

const businessDashboardRoutes = [
  ...communityRoute,
  ...overviewRoutes,
  ...cashFlowRoute,
  ...managementRoutes,
  ...offeringsRoute,
  ...ordersRoute
];

const BusinessDashboardRoutes = {
  path: PATH_BUSINESS_DASHBOARD?.root,
  guard: AuthGuard,
  layout: BusinessDashboardLayout,
  routes: businessDashboardRoutes
};

export default BusinessDashboardRoutes;
