import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DialogTitle, DialogContent } from '@mui/material';
import { DialogAnimate } from './animate';
import { MobileAppTargetOptions } from './DownloadApp';

export default function MobileAppTarget() {
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const urlTarget = urlParams.get('target');
    const [open, setOpen] = useState("");

    // console.log("urlTargeturlTargeturlTargeturlTargeturlTargeturlTarget ", urlTarget)

    useEffect(() => {
        if (urlTarget) {
            setOpen(`communely://${urlTarget}`);
            window.open(`communely://${urlTarget}`, "_blank")
        }
    }, [urlTarget])

    // http://localhost:3979/community/686e0b82-4a8f-441e-97a3-83323b584591/experiences/kdkdk?target=experience&experienceId=fjjjfjjfjf
    // http://localhost:3979/community/686e0b82-4a8f-441e-97a3-83323b584591/experiences/kdkdk?target=community/686e0b82-4a8f-441e-97a3-83323b584591/experiences/kdkdk


    return (
        <DialogAnimate
            open={!!open}
            onClose={() => {
                setOpen("")
            }}
        >
            <DialogTitle sx={{pb: 1.5, pl: 2.5}}>Open Communely app</DialogTitle>
            <DialogContent sx={{ p: 0, pt: 3, pb: 3, overflowY: 'unset' }}>
                <MobileAppTargetOptions urlScheme={open} />
            </DialogContent>
        </DialogAnimate >
    );
}
