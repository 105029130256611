import PropTypes from 'prop-types';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useQuery, useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
// material
import {
  Box,
  Button,
  Typography,
  Divider, Collapse, CircularProgress, IconButton
} from '@mui/material';
import closeFill from '@iconify/icons-eva/close-fill';

import { fCurrency } from '../../../utils/formatNumber';
import AddUtilityOption from './AddUtilityOption';
import { getPlatformServiceCharges, updatePlatform } from '../../../services/platform';
import useCurrentPlatformDetails from '../../../hooks/useCurrentPlatformDetails';
import { RegistrationStages } from '../../../utils/constant';
import ChargesItem from './ChargesItem';

AccountBillingPaymentMethod.propTypes = {
  chargesType: PropTypes.string,
  children: PropTypes.node
};

export default function AccountBillingPaymentMethod({ 
  children, 
  chargesType
}) {
  const [open, setOpen] = useState(false);
  const { platform, refetchPlatform } = useCurrentPlatformDetails();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [serviceChargeToEdit, setServiceChargeToEdit] = useState(null);

  const { data: { data } = {}, isLoading, refetch } = useQuery({
    queryKey: ['serviceCharges', { 
      settlement_id: platform.id, 
      type: chargesType
    }],
    queryFn: getPlatformServiceCharges,
  });

  const {
    mutate: updatePlatformMutation,
  } = useMutation(updatePlatform, {
    onSuccess: () => {
      refetchPlatform();
      enqueueSnackbar('Update was successful', {
        variant: 'success',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    },
    onError: error => {
      enqueueSnackbar(error.message, {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });

    }
  });

  const charges = data?.data || [];

  const handleSaveOption = () => {
    refetch();
    if (
      !platform.prerequisite_stages?.includes(RegistrationStages.PAYMENT)
    ) {
      updatePlatformStage();
    }
  }

  const handleOpenAddCard = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const updatePlatformStage = () => {
    const updateData = {
      prerequisite_stages: [
        ...(platform.prerequisite_stages || []),
        RegistrationStages.PAYMENT, RegistrationStages.ADD_RESIDENTS
      ]
    };
    updatePlatformMutation({
      platformId: platform.id,
      data: updateData
    });
  };

  const reducer = (accumulator, currentValue) => Number(accumulator) + Number(currentValue.value);
  const utilitySum = charges.reduce(reducer, 0);

  return (
    <Box >
      <Typography variant="h4" sx={{ mb: 2 }}>

        &#8358;{fCurrency(utilitySum)}
        <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
          /
          per month
        </Typography>
      </Typography>

      <Divider sx={{ borderStyle: 'dashed' }} />
      <Box my={3}>


        {charges.map(charge => (<ChargesItem key={charge.id} charge={charge} refetchCharges={refetch} onSelectEdit={(charge) => setServiceChargeToEdit(charge)} />))}
        {isLoading && <Collapse in={isLoading}>
          <Box sx={{ minHeight: 200, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </Collapse>}
      </Box>

      {children}

      <Box mt={3}>
        <Button
          size="small"
          startIcon={<Icon icon={plusFill} />}
          onClick={handleOpenAddCard}
        >
          Add charge
        </Button>
      </Box>

      <AddUtilityOption
        chargesType={chargesType}
        isOpen={open || Boolean(serviceChargeToEdit?.id)}
        serviceChargeToEdit={serviceChargeToEdit}
        onCancel={() => {
          setServiceChargeToEdit(null);
          setOpen(false);
        }}
        onSuccess={handleSaveOption} />
    </Box>
  );
}
