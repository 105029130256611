import homeFill from '@iconify/icons-eva/home-fill';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Button,
  AppBar,
  Hidden,
  Toolbar,
  Container,
  Divider,
  Slide,
  useScrollTrigger
} from '@mui/material';

import { PATH_AUTH, PATH_BUSINESS, ROOTS_COMMUNITY } from '../../routes/paths';
import Logo from '../../components/Logo';
import useAuth from '../../hooks/useAuth';
import NotificationsPopover from '../NotificationsPopover';
import AccountPopover from '../AccountPopover';
import MoreMenu from '../MoreMenu';
import { Roles } from '../../utils/constant';
import AccessControl from '../../components/AccessControl';
import GetStartedButton from '../../components/GetStartedButton';

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const MENU_LINKS = [
  // {
  //   title: 'Explore services',
  //   icon: homeFill,
  //   href: PATH_BUSINESS.busniesses
  // },
];

export const APP_BAR_MOBILE = 64;
export const APP_BAR_DESKTOP = 96;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  '& .isDesktopActive': {
    color: `${theme.palette.text.primary} !important`
  },
  '& .isMobileActive': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.text.primary,
      theme.palette.action.selectedOpacity
    )
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),


  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: { height: APP_BAR_DESKTOP },
}));

export default function HomeNavbar() {
  const { isAuthenticated } = useAuth();
  const { communityCode } = useParams();

  const adminDashboardButton = (
    <AccessControl permitedRoles={[Roles.SETTLEMENT_ADMIN]} hide>
      <Button component={RouterLink} to={`${ROOTS_COMMUNITY}/${communityCode}/admin`}>Admin Dashboard</Button>
    </AccessControl>
  );

  const renderMenuDesktop = (
    <>
      {MENU_LINKS.map((link) => {
        if (link.isAuthenticated && !isAuthenticated) {
          return null;
        };
        return (
          <Link
            exact
            to={link.href}
            key={link.title}
            underline="none"
            variant="subtitle2"
            component={RouterLink}
            activeClassName="isDesktopActive"
            sx={{
              mr: 5,
              transition: "all .3s",
              color: "text.primary",
              '&:hover': {
                textDecoration: "underline"
              }
            }}
          >
            {link.title}
          </Link>
        )
      })}
    </>
  );

  const getActionBtnConfig = () => {
    if (!isAuthenticated) {

      return <><Button
        variant="contained"
        color="inherit"
        size="large"
        component={RouterLink}
        to={`${PATH_AUTH.login}`}
      >
        Login
      </Button>
        <GetStartedButton
          btnTheme="dark"
          sx={{ ml: 2 }}
        />
      </>
    }

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& > *:not(:first-of-type)': {
            ml: { xs: 1.5, sm: 2, lg: 3 }
          }
        }}
      >
        {adminDashboardButton}
        <NotificationsPopover />
        <AccountPopover />
      </Box>
    );
  };

  const getMobileActionBtnConfig = () => {
    if (!isAuthenticated) {

      return (
        <>
          <Button
            variant="contained"
            color="inherit"
            size="large"
            component={RouterLink}
            to={`${PATH_AUTH.login}`}
          >
            Login
          </Button>
          <MoreMenu />
        </>
      )
    }

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& > *:not(:first-of-type)': {
            ml: { xs: 1.5, sm: 2, lg: 3 }
          }
        }}
      >
        {adminDashboardButton}
        <NotificationsPopover />
        <AccountPopover />
      </Box>
    );
  };

  return (
    <HideOnScroll>
      <RootStyle color="transparent">
        <ToolbarStyle
          disableGutters
          sx={{
            height: { md: APP_BAR_DESKTOP - 20 },
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <RouterLink to="/">
              <Logo sx={{ ml: -1, mr: { md: 5 } }} />
            </RouterLink>


            <Hidden smDown>{renderMenuDesktop}</Hidden>
            <Box sx={{ flexGrow: 1 }} />
            <Hidden smUp>{getMobileActionBtnConfig()}</Hidden>
            <Hidden smDown>{getActionBtnConfig()}</Hidden>
          </Container>
        </ToolbarStyle>
        <Divider />
      </RootStyle >
    </HideOnScroll>
  );
}
