/* eslint-disable camelcase */
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
// material
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Button, Divider, CircularProgress
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
// components
import Scrollbar from '../Scrollbar';
import UserListHead from './UserListHead';
import EmptyContent from '../EmptyContent';
import ResidentTableBodyRow from './ResidentTableBodyRow';

export default function ResidentsTable({
  users,
  pagination,
  filterParams,
  onChangePageSize,
  refetchUsers,
  showLessContent,
  baseUrl,
  loading
}) {
  const TABLE_HEAD = [
    { id: 'firstname', isVisible: true, label: 'Full name', alignRight: false },
    { id: 'status', isVisible: !showLessContent, label: 'Status', alignRight: false },
    { id: 'actions', isVisible: true, label: 'Actions', alignRight: true },
  ];

  const handleChangePage = (event, newPage) => {
    onChangePageSize({
      page: newPage + 1
    })
  };

  const handleChangeRowsPerPage = (event) => {
    onChangePageSize({
      page_size: parseInt(event.target.value, 10),
      page: 1
    })
  };

  return (
    <>
      <Scrollbar>
        <TableContainer>
          <Table>
            {!showLessContent && <UserListHead headLabel={TABLE_HEAD} />}

            <TableBody>
              {users.map((row) => {
                const {
                  identification_code,
                  status,
                  user,
                  zone_id,
                  id
                } = row;
 
                return (
                  <ResidentTableBodyRow
                    key={id}
                    avatarTxt={user.display_name}
                    name={user.display_name || user.email}
                    status={status}
                    resident={row}
                    pathUrl={`${baseUrl}/${id}`}
                    refetchUsers={refetchUsers}
                  />
                );
              })}
            </TableBody>
          </Table>
          {loading &&
            <Box sx={{ py: theme => theme.spacing(15), textAlign: "center" }}><CircularProgress /></Box>
          }
          {pagination.total_count === 0 && (
            <EmptyContent title="No member was found" sx={{ py: 5 }} />
          )}
        </TableContainer>
      </Scrollbar>

      {showLessContent ? <>    <Divider /> <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          to="#"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          View all
        </Button>
      </Box>
      </>
        : <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={pagination.total_count}
          rowsPerPage={filterParams.page_size}
          page={filterParams.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />}
    </>
  );
}
