import { useState } from "react";
import {
  Box,
  Grid,
  Card,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import useCurrentPlatformDetails from "../../../hooks/useCurrentPlatformDetails";
import { ROOTS_COMMUNITY } from "../../../routes/paths";
import { ConfirmInformation, ContactInformation, BankInformation } from '../../create/create-platform';
import AdditionalInformation from "./AdditionalInformation";


function TabPanel(props) {
  const { children, value, index, style = {}, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        ...style,
        minHeight: 430
      }}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function AccountGeneral() {
  const [tabValue, setTabValue] = useState(0);
  const { platform } = useCurrentPlatformDetails();
  const theme = useTheme();
  const history = useHistory();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
      sx: {
        mr: 0, '&.MuiTab-root': {
          // mr: isMdDown ? 3 : 0,
          alignItems: isMdDown ? "center" : "flex-start",
          px: 3,
          py: 2
        }
      }
    };
  }

  const handleChange = (event, newtabValue) => {
    setTabValue(newtabValue);
  };

  return (
    <Grid container sx={{ mb: { xs: 15 } }} justifyContent="center">
      <Grid item xs={12} md={4}>
        <Box sx={{
          height: "100%", borderRight: {
            md: `1px solid ${theme.palette.divider}`,
            xs: "none"
          }
        }}>
          <Tabs
            orientation={isMdDown ? "horizontal" : "vertical"}
            variant="scrollable"
            value={tabValue}
            onChange={handleChange}
            aria-label="general settings"
            sx={{ pb: { xs: 3, lg: 5 } }}
          >
            <Tab label="Basic Information" {...a11yProps(0)} />
            <Tab label="Contact Information" {...a11yProps(1)} />
            <Tab label="Bank Information" {...a11yProps(2)} />
            <Tab label="Additional information" {...a11yProps(3)} />
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box >
          <TabPanel value={tabValue} index={0}>
            <ConfirmInformation
              hideTips
              noBackBtn
              onNextStep={(data) => {
                if (data.alias !== platform.alias) {
                  history.push(`${ROOTS_COMMUNITY}/${data.alias}/settings`)
                }
              }}
              actionBtnText="Save" />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ContactInformation hideTips noBackBtn actionBtnText="Save" />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <BankInformation hideTips noBackBtn actionBtnText="Save" leftSideContent={null} />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <AdditionalInformation />
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  );
}
