/* eslint-disable */
import { useState } from 'react';
import { IconButton, Card, Stack, Box, CardContent } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { fieldTypes } from './constants';
import {
  ShortAnswer,
  LongAnswer,
  SingleAnswer,
  MultipleAnswers,
} from './Components.View';
import CustomFormEditMode from './CustomFormEditMode';

const components = {
  [fieldTypes.SHORT_ANSWER]: ShortAnswer,
  [fieldTypes.LONG_ANSWER]: LongAnswer,
  [fieldTypes.SINGLE_ANSWER]: SingleAnswer,
  [fieldTypes.MULTIPLE_ANSWER]: MultipleAnswers,
};

const CustomFormField = ({
  field,
  updateField,
  onDuplicate,
  onDelete,
  setCurrentDraggable
}) => {
  const [editMode, setEditMode] = useState(true);

  const handleClose = () => {
    setEditMode(false);
  };

  if (editMode)
    return (
      <CustomFormEditMode
        onClose={handleClose}
        updateField={updateField}
        field={field}
        onDelete={onDelete}
      />
    );

  const Component = components[field.type];

  return (
    <Card>
      <CardContent sx={{p:2}}>
        <Stack direction="row" spacing={1}>
          <IconButton
            disableRipple
            onMouseEnter={() => {
              setCurrentDraggable(field.id);
            }}
            onMouseLeave={() => setCurrentDraggable('')}
          >
            <DragIndicatorIcon />
          </IconButton>
          <IconButton disableRipple onClick={() => setEditMode(true)}>
            <EditIcon />
          </IconButton>
          <IconButton disableRipple onClick={() => onDuplicate(field)}>
            <ContentCopyIcon />
          </IconButton>
        </Stack>
        <Component field={field} />
      </CardContent>
    </Card>
  );
};

export default CustomFormField;
