import { useHistory } from 'react-router-dom';
import { useState } from "react";
// material
import { Box, Typography, useTheme, IconButton, DialogContent, Button } from '@mui/material';
import { Check as CheckIcon, Clear as CancelIcon } from '@mui/icons-material';
//
import useCurrentPlatformDetails from '../../../hooks/useCurrentPlatformDetails';
import useAuth from '../../../hooks/useAuth';
import { formatedRoles } from '../../../utils/constant';
import { varFadeInUp, MotionInView, DialogAnimate } from '../../animate';
import CopyClipboard from '../../CopyClipboard';
import Label from '../../Label';
import JoinCommunityMainContent from './JoinCommunityMainContent';
import SuccessAnimation from '../../SuccessAnimation';
import { ROOTS_COMMUNITY } from '../../../routes/paths';
// import AcceptInviteModal from '../../residents/AcceptInviteModal';

export default function AcceptInvite({ settlementUser, renderGoBackBtn }) {
  const [openAcceptInvite, setopenAcceptInvite] = useState(false);
  const theme = useTheme();
  const { user, refetchUser } = useAuth();
  const history = useHistory();
  const [joinSuccessful, setJoinSuccessful] = useState(false);
  const { platform } = useCurrentPlatformDetails();
  const userPlatformDetails = user?.community?.filter(el => el.alias?.toLowerCase() === platform.alias)[0];

  const handleSuccess = () => {
    refetchUser();
    setopenAcceptInvite(false);
    setJoinSuccessful(true);
  };

  if (joinSuccessful) {
    return (
      <MotionInView
        variants={varFadeInUp}
        sx={{ height: "100%", width: "100%" }}
      >
        <Box sx={{ textAlign: "center" }} >
          <SuccessAnimation />
          <Typography variant="h3" color="secondary.main">You are all set</Typography>
          <Box my={3} sx={{ textAlign: "center" }}>
            <CopyClipboard
              text={<Typography variant="overline">Member code: {settlementUser.identification_code}</Typography>}
              copy={`${settlementUser.identification_code}`} />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(`${ROOTS_COMMUNITY}/${platform.alias}/member`)}>
            {platform.name}
          </Button>
        </Box>
      </MotionInView>
    )
  }

  return (
    <>
      <Box sx={{ textAlign: 'center', }}>
        <Box
          component="img"
          alt="Pending illustration"
          src="/static/decide.svg"
          sx={{ width: 100, height: 100, margin: "auto" }}
        />
        <Typography variant="h4" sx={{ my: 3 }}>Accept invite</Typography>
        <MotionInView
          variants={varFadeInUp}
          sx={{ height: "100%", width: "100%" }}
        >
          <Box sx={{ textAlign: "center" }} >
            <Typography variant="body2">
              <b>{userPlatformDetails?.name}</b> has invited you to be {formatedRoles[settlementUser?.role].pronoun || "a"} <Label color="primary"><span style={{ fontSize: "1.2em" }}>{formatedRoles[settlementUser?.role].name}</span></Label> in the community.
            </Typography>
            <br />

            <Typography variant="body2">As {formatedRoles[settlementUser?.role].pronoun || "a"} <Label color="primary"><span style={{ fontSize: "1.2em" }}>{formatedRoles[settlementUser?.role].name}</span></Label>, you will be able to execute administrative duties within the community.</Typography>
          </Box>

          <Box mt={3} display="flex" justifyContent="center">
            <IconButton
              onClick={() => history.goBack()}
              variant="outlined"
              title="Decline invite"
              sx={{ border: `1px solid ${theme.palette.grey[300]}`, mr: 1 }}
            >
              <CancelIcon style={{ fontSize: 30, color: theme.palette.error.main }} />
            </IconButton>
            <IconButton
              onClick={() => setopenAcceptInvite(true)}
              variant="outlined"
              title="Accept invite"
              sx={{ border: `1px solid ${theme.palette.grey[300]}` }}
            >
              <CheckIcon style={{ fontSize: 30, color: theme.palette.success.main }} />
            </IconButton>
          </Box>

          {renderGoBackBtn?.()}
        </MotionInView>
      </Box>
      <DialogAnimate
        open={openAcceptInvite}
        onClose={() => setopenAcceptInvite(false)}>
        <DialogContent>
          <JoinCommunityMainContent
            handleSuccess={handleSuccess}
            acceptInvite
            platform={platform}
            settlementUserId={settlementUser.id}
          />
        </DialogContent>
      </DialogAnimate>
    </>
  )
}
