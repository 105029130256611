import { useState } from 'react';
import { Grid, Tabs, Tab, Card, useMediaQuery, useTheme, Box, Alert } from '@mui/material';
import AccountBillingPaymentMethod from './AccountBillingPaymentMethod';
import { ChargesTypes, SettlementBusinessPermissionStatus } from '../../../utils/constant';
import useCurrentPlatformDetails from '../../../hooks/useCurrentPlatformDetails';

function TabPanel(props) {
  const { children, value, index, style = {}, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        ...style,
        minHeight: 430
      }}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function AccountBilling() {
  const [tabValue, setTabValue] = useState(1);
  const { platform } = useCurrentPlatformDetails();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
      sx: {
        mr: 0, '&.MuiTab-root': {
          mr: isMdDown ? 3 : 0,
          alignItems: isMdDown ? "center" : "flex-start",
          px: 3,
          py: 2
        }
      }
    };
  }

  const handleChange = (event, newtabValue) => {
    setTabValue(newtabValue);
  };

  return (
      // <Grid container justifyContent="center">
      //   <Grid item xs={12} md={4}>
      //     <Box sx={{ height: "100%", borderRight: 1, borderColor: 'divider' }}>
      //       <Tabs
      //         orientation={isMdDown ? "horizontal" : "vertical"}
      //         variant="scrollable"
      //         value={tabValue}
      //         onChange={handleChange}
      //         aria-label="charges tab"
      //         sx={{ py: { xs: 3, lg: 5 } }}
      //       >
      //         <Tab label="Service charge" {...a11yProps(0)} />
      //         <Tab label="Promotion charge" {...a11yProps(1)} />
      //       </Tabs>
      //     </Box>
      //   </Grid>
      //   <Grid item xs={12} md={8}>
          <Box sx={{maxWidth: 540}}>
            {/* <TabPanel value={tabValue} index={0}>
              <AccountBillingPaymentMethod chargesType={ChargesTypes.SERVICE}>
                <Alert severity="info" variant="outlined" >
                  <b>Service charges</b> are the bills necessary for maintaining the estate.
                  <br />
                  <br />
                  <b>Residents</b> will see this on joining your estate
                </Alert>
              </AccountBillingPaymentMethod>
            </TabPanel>
            <TabPanel value={tabValue} index={1}> */}
              {platform.promotion !== SettlementBusinessPermissionStatus.ALLOW ? (
                <Box>
                  <Alert severity="error" variant="outlined" sx={{ mb: 3 }} >
                    <b>Promotions is not enabled</b>
                    <br />
                    <br />
                    To enable promotions, click <b>"General"</b> tab, then click <b>"Additional Information"</b> tab
                  </Alert>
                  <Alert severity="info" variant="outlined" >
                    <b>Promotion charges</b> are the bills business and service providers pay to be gain visibility in your estates.
                    <br />
                    <br />
                    <b>Business</b> and <b>service providers</b> will see this before sending a request.
                  </Alert>
                </Box>
              ) : (
                <AccountBillingPaymentMethod
                  chargesType={ChargesTypes.PROMOTION} >

                  <Alert severity="info" variant="outlined" >
                    <b>Promotion charges</b> are the bills business and service providers pay to be gain visibility in your estates.
                    <br />
                    <br />
                    <b>Business</b> and <b>service providers</b> will see this before sending a request.
                  </Alert>
                </AccountBillingPaymentMethod>
              )}
            {/* </TabPanel> */}
          </Box>
      //   </Grid>
      // </Grid>
  );
}
