import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider, QueryClient } from 'react-query';
// material
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// routes
import AppRoute from './routes';
// theme
import ThemeConfig from './theme';
// components
// import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import JwtProvider from './components/authentication/JwtProvider';
import CreatePostProvider from './context/CreatePostContext';
import MobileAppTarget from './components/MobileAppTarget';

// ----------------------------------------------------------------------

const history = createBrowserHistory();

const reactQueryConfig = {
  refetchOnWindowFocus: false,
  retry: false,
};

const defaultOptions = {
  queries: reactQueryConfig,
  mutations: reactQueryConfig
};

const queryClient = new QueryClient({
  defaultOptions
});

export default function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeConfig>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <NotistackProvider>
              <Router history={history}>
                <MobileAppTarget />
                <JwtProvider>
                  <ScrollToTop />
                  <GoogleAnalytics />
                  <CreatePostProvider>
                    <AppRoute />
                  </CreatePostProvider>
                </JwtProvider>
              </Router>
            </NotistackProvider>
          </LocalizationProvider>
        </ThemeConfig>
      </QueryClientProvider>
    </HelmetProvider>
  );
}
