/* eslint-disable */
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import useAuth from './useAuth';

import { getPlatformUserById } from '../services/users';

export default function userCurrentPlatformUserDetails(queryArgs = {}) {
  const { onFetchSuccessful } = queryArgs;
  const { communityCode } = useParams();
  const {
    user: { community = [] }
  } = useAuth();

  const activeSettlement = community.find(
    (community) => community.alias === communityCode
  );

  const { data, isLoading, refetch, remove } = useQuery({
    queryKey: ['settlementUser', { userId: activeSettlement?.community_user_id }],
    enabled: !!activeSettlement?.community_user_id,
    queryFn: getPlatformUserById,
    config: {
      cacheTime: 3600 * 100,
      staleTime: 3600 * 100
    },
    onSuccess: () => {
      onFetchSuccessful?.();
    }
  });

  const userPlatformData = data?.data?.data || {};

  return {
    userPlatformData: {
      user: {},
      ...userPlatformData
    },
    userPlatformLoading: isLoading,
    refetchUserPlatform: refetch,
    removePlatformUserData: remove
  };
}
