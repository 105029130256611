import { parseComplexQueryParams } from '../utils/parseQuery';
import axios from '../utils/axios';

export function createBusiness(data) {
  const response = axios.post('/businesses', data);
  return response;
}

export function updateBusiness({ businessId, data }) {
  const response = axios.patch(`/businesses/${businessId}`, data);
  return response;
}

export function getBusinessById({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/business/${queryParams.businessId}/`);
  return response;
}

export function getBusinesses({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/business/`, {
    params: parseComplexQueryParams(queryParams)
  });
  return response;
}

export function getBusinessAdmins({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/business-profiles`, {
    params: parseComplexQueryParams(queryParams)
  });
  return response;
}

export const getCurrentUserBusinesses = () => {
  const response = axios.get(`/auth/users/business-info/`);
  return response;
}

export function getBusinessAdmin({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/business-profiles/${queryParams.adminId}`);
  return response;
}

export function deleteBusinessAdmin({ adminId }) {
  const response = axios.delete(`/business-profiles/${adminId}`);
  return response;
}

export function createBusinessAdmin(data) {
  const response = axios.post('/business-profiles', data);
  return response;
}

export function updateBusinessAdmin({ adminId, data }) {
  const response = axios.patch(`/business-profiles/${adminId}`, data);
  return response;
}

export function createBusinessOffer(data) {
  const response = axios.post('/business-offerings', data);
  return response;
}

export function updateBusinessOffer({ offerId, data }) {
  const response = axios.patch(`/business-offerings/${offerId}`, data);
  return response;
}

export function getBusinessOfferById({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/business-offerings/${queryParams.offerId}`);
  return response;
}

export function getBusinessOfferings({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/inventory/`, {
    params: parseComplexQueryParams(queryParams)
  });
  return response;
}