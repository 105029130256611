/* eslint-disable */
import { Button, styled, TextField, Radio, Checkbox, FormLabel } from '@mui/material';
// import InputLabel from '../InputLabel';
// import Radio from '../Radio';
// import Checkbox from '../Checkbox';
import { fieldTypes } from './constants';

// =========== SHORT ANSWER ==============
export const ShortAnswer = ({ field }) => (
  <TextField size="small" fullWidth disabled value={field.placeholder} />
);

// =========== LONG ANSWER ==============
export const LongAnswer = ({ field }) => (
  <TextField size="small"
    maxRows={5}
    minRows={4}
    placeholder={field.placeholder}
    style={{ marginBottom: 8 }}
  />
);

// =========== SINGLE & MULTIPLE ANSWERS ==============
export const Option = ({ field, updateField }) => {
  const onAddField = () => {
    updateField({ options: [...field.options, { label: '' }] }, field.id);
  };

  const handleInputChange = (index, value) => {
    const options = field.options.map((option) => {
      if (field.options.indexOf(option) === index) {
        return { label: value };
      }
      return option;
    });

    updateField({ options }, field.id);
  };
  return (
    <>
      <FormLabel>{field.label}</FormLabel>
      {field?.options?.map((option, index) => (
        <OptionsDiv>
          {field.type === fieldTypes.SINGLE_ANSWER ? (
            <Radio checked={false} />
          ) : (
            <Checkbox checked={false} />
          )}
          <TextField size="small"
            placeholder="Answer here..."
            value={option.label}
            onChange={(e) => handleInputChange(index, e.target.value)}
          />
        </OptionsDiv>
      ))}
      <Button color="primary" onClick={onAddField}>
        + Add option
      </Button>
    </>
  );
};

const OptionsDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > *': {
    display: 'block',
    margin: 'auto 0'
  },

  '& .MuiOutlinedInput-root': {
    height: 32,
    width: 418
  },

  '&+ button': {
    color: `${theme.palette.primary.main} !important`,
    textTransform: 'none',
    fontSize: (14),
    display: 'block',
    paddingLeft: 0
  }
}));

const FileUploadWrapper = styled('div')(({ theme }) => ({
  '& > section': {
    display: 'flex',
    width: 364,
    justifyContent: 'space-between',
    paddingTop: '29px',

    '& > div': {
      marginRight: 56
    }
  }
}));
