/* eslint-disable no-empty-pattern */

import { useQuery } from 'react-query';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import { IconButton } from '@mui/material';

import useCurrentPlatformDetails from './useCurrentPlatformDetails';
import { getPlatformUsers } from '../services/users';

export default function usePlatformUsers(queryParams = {}) {
  const { platform } = useCurrentPlatformDetails();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { data: { data } = {}, isLoading, refetch } = useQuery({
    queryKey: ['community_users', { ...queryParams, id: platform.id }],
    enabled: !!platform.id,
    queryFn: getPlatformUsers,
    onError: () => {
      enqueueSnackbar('Unable to fetch users', {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    }
  });

  const { results: users, total } = data || {};

  console.log("users== ", data);

  return {
    users: users || [],
    usersPagination: {
      total_count: total,
    },
    usersLoading: isLoading,
    refetchUsers: refetch
  };
}
