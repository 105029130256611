import axios from "axios";
import { useState } from "react";
import { FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';

import { UploadSingleFileWithAvatar } from "../upload";

export default function AssetUpload({ useFormUtils, name, label }) {
    const [uploadProgress, setUploadProgress] = useState(null);
    const {
        control,
        setValue
    } = useFormUtils;

    const uploadPreset = {
        "display_image": "cover_photo",
        logo: "business_logo"
    }

    const handleDrop = (file) => {
        if (file) {
            try {
                setUploadProgress(0);
                const formData = new FormData();
                formData.append('file', file.file);
                formData.append('upload_preset', uploadPreset[name]);
                formData.append('tags', name)
                formData.append('api_key', process.env.REACT_APP_CLOUDINARY_KEY);
                axios
                    .post(process.env.REACT_APP_CLOUDINARY_URL, formData, {
                        headers: { 'X-Requested-With': 'XMLHttpRequest' },
                        onUploadProgress: event => {
                            const progressRatio = event.loaded / file.size;
                            setUploadProgress(progressRatio * 100)
                        }
                    })
                    .then((response) => {
                        const { data } = response;
                        setValue(name, data.secure_url);
                    });
            } catch (error) {
                // setNetworkError([{
                //     file,
                //     errors: [{
                //         code: "network",
                //         message: error.message
                //     }]
                // }])
            }
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <>
                    <FormHelperText sx={{ mb: 1, mt: 3 }}>{label}</FormHelperText>
                    <UploadSingleFileWithAvatar
                        value={value}
                        uploadProgress={uploadProgress}
                        onChange={(val) => {
                            handleDrop(val);
                            onChange(val);
                        }}
                        caption="(Only *.jpeg and *.png images will be accepted)"
                        error={Boolean(error?.message)}
                    />
                    {Boolean(error?.message) && (
                        <FormHelperText error sx={{ px: 2 }}>
                            {error?.message}
                        </FormHelperText>
                    )}
                </>
            )}
        />
    );
}
