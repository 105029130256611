import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Typography, Box, Button } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2)
}));

// ----------------------------------------------------------------------

EmptyContent.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
  description: PropTypes.string
};

export default function EmptyContent({ title, description, img, action, btnText, ...other }) {
  return (
    <RootStyle {...other}>
      <Box
        component="img"
        alt="empty content"
        src={img || '/static/illustrations/illustration_empty_content.svg'}
        sx={{ height: 240, mb: 3 }}
      />

      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      {description && (
        <Typography variant="body1" sx={{ color: 'text.secondary' }} gutterBottom>
          {description}
        </Typography>
      )}

      {action && (
        <Button color="warning" variant="contained" onClick={action}>{btnText}</Button>
      )}
    </RootStyle>
  );
}
