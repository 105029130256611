/* eslint-disable no-empty-pattern */
import { useQuery } from 'react-query';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import { IconButton } from '@mui/material';

import { getBusinessAdmin } from '../services/business';

export default function useFetchBusinessAdmin({adminId}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { data: { data } = {}, isLoading, refetch } = useQuery({
    queryKey: ['business_admin', { adminId }],
    enabled: !!adminId,
    queryFn: getBusinessAdmin,
    onError: () => {
      enqueueSnackbar('Unable to fetch business admins', {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    }
  });

  return {
    admin: data?.data || {},
    adminLoading: isLoading,
    refetchAdmin: refetch
  };
}
