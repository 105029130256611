import { NavLink as RouterLink, useParams } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Button,
  AppBar,
  Hidden,
  Toolbar,
  Container,
  Divider,
  Avatar,
  Typography,
  Slide,
  useScrollTrigger
} from '@mui/material';

import { PATH_AUTH } from '../../../routes/paths';
import useAuth from '../../../hooks/useAuth';
import NotificationsPopover from '../../NotificationsPopover';
import AccountPopover from '../../AccountPopover';
import MoreMenu from '../../MoreMenu';
import useGetPlatformByCode from '../../../hooks/useGetPlatformByCode';

export const APP_BAR_MOBILE = 64;
export const APP_BAR_DESKTOP = 96;

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  '& .isDesktopActive': {
    color: `${theme.palette.text.primary} !important`
  },
  '& .isMobileActive': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.text.primary,
      theme.palette.action.selectedOpacity
    )
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),


  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: { height: APP_BAR_DESKTOP },
}));

export default function HomeNavbar() {
  const { communityCode } = useParams();
  const { isAuthenticated } = useAuth();
  const { platform } = useGetPlatformByCode({ platformCode: communityCode });

  const getActionBtnConfig = () => {
    if (!isAuthenticated) {

      return <><Button
        variant="contained"
        color="inherit"
        size="large"
        component={RouterLink}
        to={`${PATH_AUTH.login}`}
      >
        Login
      </Button>
        <Button
          variant="contained"
          size="large"
          sx={{
            // boxShadow: (theme) => theme.customShadows.z16,
            color: 'common.white',
            bgcolor: 'common.black',
            ml: 2
          }}
          component={RouterLink}
          to={`${PATH_AUTH.register}`}
        >
          Get started
        </Button>
      </>
    }

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& > *:not(:first-of-type)': {
            ml: { xs: 1.5, sm: 2, lg: 3 }
          }
        }}
      >
        <NotificationsPopover />
        <AccountPopover />
      </Box>
    );
  };

  const getMobileActionBtnConfig = () => {
    if (!isAuthenticated) {
      return <MoreMenu />;
    };

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& > *:not(:first-of-type)': {
            ml: { xs: 1.5, sm: 2, lg: 3 }
          }
        }}
      >
        <NotificationsPopover />
        <AccountPopover />
      </Box>
    );
  };

  return (
    <HideOnScroll>
      <RootStyle color="transparent">
        <ToolbarStyle
          disableGutters
          sx={{
            height: { md: APP_BAR_DESKTOP - 20 },
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Avatar src={platform.display_image} sx={{ mr: 2 }} alt="Settlement image" />
            <Typography variant="subtitle2">{platform.name}</Typography>

            {/* <Hidden smDown>{renderMenuDesktop}</Hidden> */}
            <Box sx={{ flexGrow: 1 }} />
            <Hidden smUp>{getMobileActionBtnConfig()}</Hidden>
            <Hidden smDown>{getActionBtnConfig()}</Hidden>
          </Container>
        </ToolbarStyle>
        <Divider />
      </RootStyle >
    </HideOnScroll>
  );
}
