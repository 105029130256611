import { useRef, useEffect } from 'react';
import {
    SwipeableDrawer,
    Box,
    Button,
    Container,
    Typography,
    IconButton,
    FormHelperText,
    Stack,
    OutlinedInput,
    InputAdornment,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    FormControl,
    Radio
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/edit-2-fill';
import closeFill from '@iconify/icons-eva/close-fill';

import CustomFormBuilder from '../../../FormBuilder';
import { createPlatformCustomForm, updatePlatformCustomForm } from '../../../../services/platform';
import { formatedRoles, Roles } from '../../../../utils/constant';

export default function UpsertCustomForm({
    open,
    onClose,
    settlementId,
    userSettlementId,
    onSuccess,
    formToEdit
}) {
    const { handleSubmit, control, watch, reset } = useForm();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const renameRef = useRef(null);

    const mutationConfig = {
        onSuccess: (data) => {
            onSuccess();
            reset({})
        },
        onError: (error) => {
            enqueueSnackbar(error?.message, {
                variant: 'error',
                action: (key) => (
                    <IconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </IconButton>
                )
            });
        }
    }

    useEffect(() => {
        if (formToEdit?.id) {
            reset({
                ...formToEdit
            });
        }
    }, [formToEdit?.id])

    const {
        mutate: createPlatformCustomFormMutation,
        ...createPlatformCustomFormFeedback
    } = useMutation(createPlatformCustomForm, mutationConfig);

    const {
        mutate: updatePlatformCustomFormMutation,
        ...updatePlatformCustomFormFeedback
    } = useMutation(updatePlatformCustomForm, mutationConfig);

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        if (open === false) {
            onClose();
            reset({});
        }
    };

    const handleUpsertCustomForm = (values) => {
        if (values?.form_fields?.length === 0) {
            enqueueSnackbar('Form fields are required', {
                variant: 'error',
                action: (key) => (
                    <IconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </IconButton>
                )
            });
            return;
        }
        if (formToEdit?.id) {
            updatePlatformCustomFormMutation({
                ...values,
                formId: formToEdit?.id
            })
            return;
        }
        createPlatformCustomFormMutation({
            name: watch().name,
            is_required: watch().is_required,
            community: settlementId,
            ...values
        });
    };

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}>
            <Box sx={{ minHeight: "100vh" }}>
                <Container >
                    <Box sx={{ py: 1.5, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="subtitle1">
                            New form
                        </Typography>
                        <Button
                            color="inherit"
                            onClick={() => {
                                onClose();
                                reset({});
                            }}
                            variant="outlined">
                            Cancel
                        </Button>
                    </Box>
                </Container>
                <Box sx={{ bgcolor: "background.neutral" }}>
                    <Container sx={{ py: 5 }}>
                        <Stack spacing={5}>
                            <Stack direction="row" spacing={5}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: "Form name is required" }}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                        <Stack spacing={1}>
                                            <Typography variant='body2'>Name</Typography>
                                            <OutlinedInput
                                                inputRef={renameRef}
                                                size="small"
                                                value={value}
                                                onChange={onChange}
                                                error={Boolean(error?.message)}
                                                helperText={error?.message || " "}
                                            />
                                        </Stack>
                                    )}
                                />
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                        <Stack spacing={1}>
                                            <Typography variant='body2'>Description</Typography>
                                            <OutlinedInput
                                                inputRef={renameRef}
                                                size="small"
                                                value={value}
                                                onChange={onChange}
                                                error={Boolean(error?.message)}
                                                helperText={error?.message || " "}
                                                sx={{ width: 420}}
                                            />
                                        </Stack>
                                    )}
                                />
                                <Controller
                                    name="is_required"
                                    control={control}
                                    rules={{ required: "Value is required" }}
                                    render={({
                                        field: { value, onChange },
                                        fieldState: { error }
                                    }) => (
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend" sx={{ color: "text.primary", fontSize: t => t.typography.body2.fontSize }}>Is this form compulsory?</FormLabel>
                                            <RadioGroup
                                                aria-label="Is this form compulsory?"
                                                row
                                                name="radio-buttons-group"
                                                value={value}
                                                onChange={event => onChange(event.target.value)}
                                            >
                                                {[
                                                    { value: "yes", label: "Yes" },
                                                    { value: "no", label: "No" },
                                                ].map((option, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={option.value}
                                                        control={<Radio size='small' />}
                                                        label={option.label}
                                                    />
                                                ))}
                                            </RadioGroup>
                                            {!!error?.message && <FormHelperText error>{error?.message}</FormHelperText>}
                                        </FormControl>
                                    )}
                                />
                            </Stack>
                            <Box>
                                <CustomFormBuilder
                                    formFields={formToEdit.form_fields}
                                    onCreateForm={(forms) => handleSubmit(values => handleUpsertCustomForm({ ...values, ...forms }))}
                                    isLoading={createPlatformCustomFormFeedback.isLoading || updatePlatformCustomFormFeedback.isLoading}
                                />
                            </Box>
                        </Stack>
                    </Container>
                </Box>
            </Box>
        </SwipeableDrawer>
    );
}