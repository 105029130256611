/* eslint-disable */
import { 
  FormGroup, 
  Radio, 
  Checkbox, 
  TextField, 
  FormLabel, 
  FormControlLabel, 
  RadioGroup, 
  FormControl 
} from '@mui/material';

export const ShortAnswer = ({ field }) => (
  <TextField fullWidth disabled value={field.placeholder} label={field.label} />
);

export const LongAnswer = ({ field }) => (
  <TextField multiline fullWidth maxRows={3} minRows={3} label={field.label} placeholder={field.placeholder} />
);
export const SingleAnswer = ({ field }) => (
  <>
    <FormControl component="fieldset">
      <FormLabel component="legend">{field.label}</FormLabel>
      <RadioGroup
        aria-label={field.label}
        name="radio-buttons-group"
      >
        {field.options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={index}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  </>
);

export const MultipleAnswers = ({ field }) => (
  <>
    <FormLabel>{field.label}</FormLabel>
    <FormGroup>
      {field.options.map((option, index) => (
        <FormControlLabel key={index} control={<Checkbox />} label={option.label} />
      ))}
    </FormGroup>
  </>
);
