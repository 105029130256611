import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import { PATH_AUTH } from '../routes/paths';
import LoadingScreen from '../components/LoadingScreen';

AuthProtect.propTypes = {
  children: PropTypes.node
};

export default function AuthProtect({ children }) {
  const { isLoading, isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Redirect to={`${PATH_AUTH.login}?redirect=${pathname}`} />;
  }

  return <>{children}</>;
}
