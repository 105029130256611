/* eslint-disable */
import { Button, Stack, FormControlLabel, CardContent, Checkbox, Box, TextField, Card } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { fieldTypes, fieldConfigs } from './constants';
import {
  ShortAnswer,
  LongAnswer,
  Option,

} from './Components.Edit';

const components = {
  [fieldTypes.SHORT_ANSWER]: ShortAnswer,
  [fieldTypes.LONG_ANSWER]: LongAnswer,
  [fieldTypes.SINGLE_ANSWER]: Option,
  [fieldTypes.MULTIPLE_ANSWER]: Option,
};

const CustomFormEditMode = ({ onClose, updateField, field, onDelete }) => {
  const handleInputChange = (name) => (e) => {
    name === 'required'
      ? updateField({ required: e.target.checked }, field.id)
      : updateField({ [name]: e.target.value }, field.id);
  };

  const Component = components[field.type];

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Button
            startIcon={<DeleteOutlineIcon />}
            onClick={() => onDelete(field.id)}
            color="error"
            sx={{maxWidth: "max-content"}}
          >
            Delete
          </Button>
          <TextField autoFocus label="field label" fullWidth value={field.label} onChange={handleInputChange('label')} />

          <Component field={field} updateField={updateField} />

          <Box dislay="flex" alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.required}
                  onChange={handleInputChange('required')} />
              }
              label="Required"
            />
            <Button onClick={onClose} variant="contained" size="small" color="inherit">
              Done
            </Button>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CustomFormEditMode;

