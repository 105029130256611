import { lazy } from 'react';
import AuthGuard from '../guards/AuthGuard';
// layouts
import { PATH_BUSINESS, PATH_CREATE } from './paths';
import HomeLayout from '../layouts/landingPage';

const PlatformInformation = lazy(() =>
  import('../views/create/createEstate')
);

const createRoutes = [
  {
    exact: true,
    path: PATH_CREATE.community,
    component: PlatformInformation
  }
];

const CreateRoutes = {
  path: PATH_CREATE.community,
  guard: AuthGuard,
  layout: HomeLayout,
  routes: createRoutes
};

export default CreateRoutes;
