import { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import {
    Grid, Tabs, Tab, Card, useMediaQuery, useTheme, Box, CircularProgress, Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    IconButton
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { deletePlatformCustomForm, getPlatformCustomForms } from '../../../services/platform';
import useCurrentPlatformDetails from '../../../hooks/useCurrentPlatformDetails';
import EmptyContent from '../../EmptyContent';
import { DialogAnimate } from '../../animate';
import UpsertCustomForm from './upsertCustomForm';
import userCurrentPlatformDetails from '../../../hooks/useCurrentPlatformUserDetails';
import FillFormField from '../../FormBuilder/FillFormField';
import Label from '../../Label';

export default function CustomFormField() {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [openUpsertDrawer, setOpenUpsertDrawer] = useState(false);
    const [refetchForm, setRefetchForm] = useState(false);
    const [formToEdit, setFormToEdit] = useState({});
    const { platform } = useCurrentPlatformDetails();
    const { userPlatformData } = userCurrentPlatformDetails();
    const [tabValue, setTabValue] = useState("");
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const useFormUtils = useForm();

    const { data: { data } = {}, isLoading, refetch } = useQuery({
        queryKey: ['platformCustomForms', {
            settlement_id: platform.id,
        }],
        queryFn: getPlatformCustomForms,
        onSuccess: (data) => {
            setTabValue(data.data.results[0]?.id)
        }
    });

    const {
        mutate: deletePlatformCustomFormMutation,
        ...deletePlatformCustomFormFeedback
    } = useMutation(deletePlatformCustomForm, {
        onSuccess: () => {
            setOpen(false);
            refetch();
            enqueueSnackbar('Form was deleted successfully', {
                variant: 'success',
                action: (key) => (
                    <IconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </IconButton>
                )
            });
        },
        onError: error => {
            enqueueSnackbar(error.message, {
                variant: 'error',
                action: (key) => (
                    <IconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </IconButton>
                )
            });

        }
    });

    const handleCloseDrawer = () => {
        setOpenUpsertDrawer(false);
        setFormToEdit({});
    };

    const handleEdit = form => {
        setFormToEdit(form);
    };

    const handleDeleteCustomForm = () => {
        deletePlatformCustomFormMutation({
            formId: tabValue,
        });
    };

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
            sx: {
                mr: 0, '&.MuiTab-root': {
                    mr: isMdDown ? 3 : 0,
                    alignItems: isMdDown ? "center" : "flex-start",
                    px: 3,
                    py: 2
                }
            }
        };
    }

    const handleChange = (event, newtabValue) => {
        setTabValue(newtabValue);
    };

    if (isLoading) {
        return (
            <Card sx={{ minHeight: 400, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
            </Card>
        )
    };

    return (
        <>
            {data?.total === 0 ? (
                <Card sx={{ minHeight: 400, display: "flex", justifyContent: "center", alignItems: "center", py: 5 }}>
                    <EmptyContent
                        title="No custom form yet"
                        btnText="Create form"
                        description="Custom forms enable you to collect customized informations from members."
                        action={() => {
                            setOpenUpsertDrawer(true)
                        }}
                    />
                </Card>
            ) : (
                <>
                    <Button
                        color="primary"
                        variant="outlined"
                        sx={{ mb: 3 }}
                        onClick={() => {
                            setOpenUpsertDrawer(true)
                        }}
                    >New Form</Button>
                    <Card>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} md={4}>
                                <Box sx={{ height: "100%", borderRight: 1, borderColor: 'divider' }}>
                                    <Tabs
                                        orientation={isMdDown ? "horizontal" : "vertical"}
                                        variant="scrollable"
                                        value={tabValue}
                                        onChange={handleChange}
                                        aria-label="charges tab"
                                        sx={{ py: { xs: 3, lg: 5 } }}
                                    >
                                        {data?.results?.map((form) => (
                                            <Tab
                                                key={form.id}
                                                label={
                                                    <Box>{form.name}{form.status === "draft" && <Label variant="filled">{form.status}</Label>}</Box>
                                                }
                                                value={form.id}
                                                {...a11yProps(form.id)}
                                            />))}
                                    </Tabs>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Box p={{ xs: 3, lg: 5 }}>
                                    <FillFormField
                                        refetchForm={refetchForm}
                                        onRefetchForm={() => setRefetchForm(false)}
                                        formId={tabValue}
                                        useFormUtils={useFormUtils}
                                        onEdit={handleEdit}
                                        onDelete={() => setOpen(true)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                </>
            )}

            <UpsertCustomForm
                open={openUpsertDrawer || Boolean(formToEdit?.id)}
                settlementId={platform.id}
                userSettlementId={userPlatformData.id}
                onClose={handleCloseDrawer}
                formToEdit={formToEdit}
                onSuccess={() => {
                    handleCloseDrawer();
                    refetch();
                    setRefetchForm(true)
                }}
            />
            <DialogAnimate open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Confirm Delete Form</DialogTitle>
                <DialogContent sx={{ pb: 0, mt: 3, overflowY: 'unset' }}>
                    <Typography variant="body2" color="text.secondary">
                        Deleting this form will also delete all responses to it.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        variant="outlined"
                        color="inherit"
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        onClick={handleDeleteCustomForm}
                        variant="contained"
                        color="error"
                        loading={deletePlatformCustomFormFeedback.isLoading}
                    >
                        Delete
                    </LoadingButton>
                </DialogActions>
            </DialogAnimate >
        </>
    );
}
