import { lazy } from 'react';
import HomeLayout from '../layouts/landingPage';
import AuthGuard from '../guards/AuthGuard';

import BusinessLayout from '../layouts/business/home';
import { PATH_BUSINESS } from './paths';

const BusinessRoutes = {
  path: PATH_BUSINESS.root,
  layout: BusinessLayout,
  routes: [
    {
      exact: true,
      path: PATH_BUSINESS.busniesses,
      component: lazy(() => import('../views/businesses'))
    },
    {
      exact: true,
      path: PATH_BUSINESS.createBusiness,
      guard: AuthGuard,
      layout: HomeLayout,
      component: lazy(() => import('../views/create/createBusiness'))
    },
    {
      exact: true,
      path: PATH_BUSINESS.busniess,
      component: lazy(() => import('../views/businesses/Business'))
    },
  ]
};

export default BusinessRoutes;
