import { Icon } from '@iconify/react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
// material
import {
  Box,
  Typography, IconButton,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import closeFill from '@iconify/icons-eva/close-fill';

import { DialogAnimate } from '../../animate';
import { fCurrency } from '../../../utils/formatNumber';
import { deleteServiceCharge } from '../../../services/platform';

export default function ChargesItem({
  charge,
  refetchCharges,
  onSelectEdit
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const {
    mutate: updateCharges,
    isLoading
  } = useMutation(deleteServiceCharge, {
    onSuccess: () => {
      setOpenDeleteDialog(false);
      refetchCharges();
      enqueueSnackbar('Service charge was deleted successfully', {
        variant: 'success',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    },
    onError: error => {
      enqueueSnackbar(error.message, {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });

    }
  });

  const handleUpdateCharge = () => {
    updateCharges({
      chargeId: charge.id,
    });
  }


  const chargesAction = [
    {
      title: "Edit",
      action: (charge) => onSelectEdit(charge),
      icon: editFill
    },
    {
      title: "Delete",
      action: () => setOpenDeleteDialog(true),
      icon: trash2Fill
    }
  ]

  return (
    <Box mb={0.5} display="flex" alignItems="center"  >

      <Box display="flex" alignItems="center" >
        <Typography variant="body2" sx={{ width: 80 }}>
          &#8358;{fCurrency(charge.value)}
        </Typography>
        <Typography mr={2}>&#8212;</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>

          {charge.name}
        </Typography>

      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box display="flex" alignItems="center">
        {chargesAction.map((action, index) => <IconButton
          key={index}
          size="medium"
          variant="contained"
          title={action.title}
          onClick={() => action.action(charge)}
          sx={{ color: theme => theme.palette.grey[500], fontSize: 18 }}
        >
          <Icon icon={action.icon} />
        </IconButton>)}
      </Box>
      <DialogAnimate open={(openDeleteDialog)} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle> Delete "{charge.name}"</DialogTitle>
        <DialogContent sx={{ pb: 0, overflowY: 'unset' }}>
          This service charge will be substracted from the total service charges.
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="outlined"
            color="inherit"
            onClick={() => setOpenDeleteDialog(false)}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            color="error"
            onClick={handleUpdateCharge}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </DialogAnimate>
    </Box>
  );
}
