import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import copyFill from '@iconify/icons-eva/copy-fill';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// material
import {
  Button,
  Typography
} from '@mui/material';

export default function CopyClipboard({ text, copy, disabled, component }) {
  const { enqueueSnackbar } = useSnackbar();

  const onCopy = () => {
    if (disabled) return;
    enqueueSnackbar('Copied', { variant: 'success' });
  };

  return (
    <CopyToClipboard text={copy} onCopy={onCopy}>
      {component || (
        <Button disabled={disabled} endIcon={<Icon icon={copyFill} width={24} height={24} />}>
          <Typography variant="caption" color="inherit">
            {text}
          </Typography>
        </Button>
      )}
    </CopyToClipboard>
  );
}
