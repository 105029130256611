import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useState } from "react";
// material
import {
  Box,
  Button,
  Typography,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { PlatformUserStatus, Roles, formatedRoles } from '../../../utils/constant';
import usePlatformUsers from '../../../hooks/usePlatformUsers';
import { AddPersonelModal } from '../../platform-management';
import CreateWrapper from '../CreateWrapper';

InvitePersonnels.propTypes = {
  onNextStep: PropTypes.func,
};

const BoxStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('all'),
  border: `solid 1px ${theme.palette.grey[500_32]}`
}));


export default function InvitePersonnels({ onNextStep, onBackStep }) {
  const [openPersoneModal, setOpenPersoneModal] = useState(false);

  const allPersonels = usePlatformUsers({
    role: [
      Roles.SETTLEMENT_OWNER,
      Roles.SETTLEMENT_ADMIN,
    ]
  });

  const pendingPersonels = usePlatformUsers({
    status: PlatformUserStatus.PENDING,
    role: [
      Roles.SETTLEMENT_OWNER,
      Roles.SETTLEMENT_ADMIN,
    ]
  });

  const activePersonels = usePlatformUsers({
    status: PlatformUserStatus.ACTIVE,
    role: [
      Roles.SETTLEMENT_OWNER,
      Roles.SETTLEMENT_ADMIN,
    ]
  });

  const handleSuccess = () => {
    onNextStep();
  };

  const handleAddPersonelSuccess = () => {
    pendingPersonels.refetchUsers();
  }

  return (
    <>
      <CreateWrapper
        onSuccessAction={handleSuccess}
        cardTitle="Add admins"
        onBackStep={onBackStep}
        actionBtnAction={onNextStep}
        disableSubmitBtn={!allPersonels.usersLoading && Number(allPersonels.total_count) > 0}
      >
        <Alert sx={{ mb: 3 }} severity="info" variant="outlined">Admins are individuals that play active roles in administrating the
          community</Alert>
        {activePersonels.usersPagination.total_count > 0 && <BoxStyle sx={{ mb: 3 }}>
          <Box mb={1}>< Typography variant="subtitle2" >  Active Personels</Typography></Box>
          {
            activePersonels.users.map(user => (
              <Box mb={2} key={user.id} >

                <Box display="flex" alignItems="center" >
                  <Typography variant="body2">
                    {formatedRoles[user.role].name}
                  </Typography>
                  <Typography mx={2}>&#8212;</Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>

                    {user.user.email}
                  </Typography>

                </Box>
              </Box>
            ))
          }
        </BoxStyle>
        }
        {pendingPersonels.usersPagination.total_count > 0 && <BoxStyle sx={{ mb: 3 }}>
          <Box mb={1}>< Typography variant="subtitle2" > Pending Personels</Typography></Box>
          {
            pendingPersonels.users.map(user => (
              <Box mb={2} key={user.id} >

                <Box display="flex" alignItems="center" >
                  <Typography variant="body2">
                    {formatedRoles[user.role].name}
                  </Typography>
                  <Typography mx={2}>&#8212;</Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>

                    {user.user.email}
                  </Typography>

                </Box>
              </Box>
            ))
          }
        </BoxStyle>
        }



        <Box mt={3}>
          <Button
            size="small"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => setOpenPersoneModal(true)}
          >
            Add  Personel
          </Button>
        </Box>
      </CreateWrapper>
      <AddPersonelModal
        open={openPersoneModal}
        onClose={() => setOpenPersoneModal(false)}
        onSuccess={handleAddPersonelSuccess}
      />
    </>
  );
}