
/* eslint-disable */
export const parseComplexQueryParams = (queryParams) => {
  const params = new URLSearchParams();
  params.set("search", "")
  Object.keys(queryParams).forEach((key) => {
    if (queryParams[key] instanceof Array) {
      queryParams[key].forEach((item) => {
        params.append(`${key}[]`, item);
      });
    } else {
      params.set(key, queryParams[key]);
    }
  });
  return params;

  // const params = new URLSearchParams();
  // Object.keys(queryParams).forEach((key) => {
  //   if (queryParams[key] instanceof Array) {
  //     queryParams[key].forEach((item) => {
  //       params.append(`${key}[]`, item);
  //     });
  //   } else {
  //     if (typeof queryParams[key] === "boolean") {
  //       params.set(key, queryParams[key]);
  //     } else if (queryParams[key]) {
  //       params.set(key, queryParams[key]);
  //     }
  //   }
  // });
  // return params;
};
