import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

import { ROOTS_COMMUNITY } from '../../../routes/paths';
import CreateWrapper from '../CreateWrapper';
import useUpsertPlatform from '../../../hooks/useUpsertPlatform';
import { getPlatformFormResetFields } from '../../../utils/createFormUtils';
import { defaultPlatformFormValues } from '../../../utils/constant';

ConfirmInformation.propTypes = {
  onSuccessAction: PropTypes.func
};

export default function ConfirmInformation({
  onNextStep,
  noBackBtn,
  actionBtnText,
  description,
  communityId
}) {
  const { control, handleSubmit, reset } = useForm({ defaultValues: defaultPlatformFormValues });
  const history = useHistory();

  const {
    onUpsertPlatform,
    platform,
    upsertPlatformError,
    upsertPlatformLoading
  } = useUpsertPlatform({
    onSuccess: (data) => {
      if (!communityId) {
        history.push(`${ROOTS_COMMUNITY}/create?communityId=${data.id}`);
      };
      onNextStep?.(data);
    },
    platformId: communityId
  });

  useEffect(() => {
    reset(getPlatformFormResetFields(platform))
  }, [platform?.alias]);

  const onSubmit = (values) => {
    onUpsertPlatform(values);
  };

  return (
    <CreateWrapper
      error={upsertPlatformError}
      isLoading={upsertPlatformLoading}
      description={description}
      noBackBtn={noBackBtn}
      actionBtnText={actionBtnText}
      actionBtnAction={handleSubmit(onSubmit)}
    >
      <Controller
        name="name"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Your community needs a name'
          },
          validate: {
            nameIsNotEqualtoNew: (value) =>
              value?.trim()?.toLowerCase() !== 'new' ||
              'New is reserved, use another name'
          }
        }}
        render={({ field: { ref, ...fields }, fieldState: { error } }) => (
          <TextField
            {...fields}
            label="What is your community name"
            variant="outlined"
            required
            disabled={!!platform.name}
            fullWidth
            inputRef={ref}
            sx={{ mb: 3 }}
            error={Boolean(error?.message)}
            helperText={
              error?.message ||
              'Can not be changed after submission'
            }
          />
        )}
      />
      <Controller
        name="alias"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Handle is required'
          }
        }}
        render={({
          field: { ref, onChange, ...fields },
          fieldState: { error }
        }) => (
          <TextField
            {...fields}
            disabled={!!platform.alias}
            label="Community handle"
            variant="outlined"
            onChange={(event) => onChange(event.target.value)}
            required
            fullWidth
            inputRef={ref}
            sx={{ mb: 3 }}
            error={Boolean(error?.message)}
            helperText={
              error?.message || 'Only alphanumeric values'
            }
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Tell us about your community'
          },
        }}
        render={({ field: { ref, ...fields }, fieldState: { error } }) => (
          <TextField
            {...fields}
            multiline
            minRows={4}
            fullWidth
            label="Say something about your community"
            inputRef={ref}
            sx={{ mb: 3 }}
            error={Boolean(error?.message)}
            helperText={error?.message}
          />
        )}
      />
    </CreateWrapper>
  );
}
