// routes
import { PATH_BACKOFFICE } from '../../routes/paths';

// ----------------------------------------------------------------------

const sidebarConfig = [
  {
    subheader: 'Back office',
    items: [
      { title: 'Users', href: PATH_BACKOFFICE.users },
      { title: 'Communities', href: PATH_BACKOFFICE.overview },
      { title: 'Businesses', href: PATH_BACKOFFICE.businesses },
    ]
  }
];

export default sidebarConfig;
