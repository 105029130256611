import { parseComplexQueryParams } from '../utils/parseQuery';
import axios from '../utils/axios';

export function createActivity(data) {
  const response = axios.post('/activities', data);
  return response;
}

export function getActivities({ queryKey }) {
  const [, queryParams] = queryKey;
  const response = axios.get(`/activities`, {
    params: parseComplexQueryParams(queryParams)
  });
  return response;
}