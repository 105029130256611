import { useRef, useState } from 'react';
import {
  Menu,
  MenuItem,
  ListItemText,
  IconButton
} from '@mui/material';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/plus-fill';

import { AddUsersModal } from '../../residents';
import { UpserZoneModal, UpsertStreetModal, AddPersonelModal } from '../../platform-management';
import useCurrentPlatformDetails from '../../../hooks/useCurrentPlatformDetails';
import { PlatformStatus } from '../../../utils/constant';

export default function QuickActions({ sx }) {
  const ref = useRef(null);
  const [userType, setUserType] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openUpsertZone, setOpenUpsertZone] = useState(false);
  const [openUpsertStreet, setOpenUpsertStreet] = useState(false);
  const { platform } = useCurrentPlatformDetails();

  const handleAddUser = (type) => {
    setUserType(type);
    setIsOpen(false);
  };

  const handleAddZone = () => {
    setOpenUpsertZone(true);
    setIsOpen(false);
  };

  const handleAddStreet = () => {
    setOpenUpsertStreet(true);
    setIsOpen(false);
  };

  const OPTIONS = [
    {
      text: 'Add member',
      action: () => handleAddUser('Resident')
    },
    {
      text: 'Add Admin',
      action: () => handleAddUser('Personel')
    },
    { text: 'Create zone', action: handleAddZone },
    { text: 'Create street', action: handleAddStreet }
  ];

  return (
    <>
      <IconButton
        variant="outlined"
        ref={ref} 

        onClick={() => setIsOpen(true)}
        sx={sx}
      >
        <Icon icon={menu2Fill} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%', mt: (theme) => theme.spacing(5) }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {OPTIONS.map((item) => (
          <MenuItem
            key={item.text}
            onClick={item.action}
            sx={{ typography: 'body', py: 1.2, px: 2.5 }}
          >
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        ))}
      </Menu>
      <AddUsersModal 
        open={userType === "Resident"}
        onClose={() => setUserType(null)}
        disabled={platform.status !== PlatformStatus.ACTIVE}
      />
      <AddPersonelModal
        open={userType === "Personel"}
        onClose={() => setUserType(null)}
      />
      <UpserZoneModal 
        open={openUpsertZone}
        onClose={() => setOpenUpsertZone(false)}
      />
      <UpsertStreetModal
        open={openUpsertStreet}
        onClose={() => setOpenUpsertStreet(false)}
      />
    </>
  );
}
