/* eslint-disable */
import { useQuery } from 'react-query';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import { IconButton } from '@mui/material';

import { getPlatformByCode } from 'src/services/platform';

export default function useGetPlatformByCode({ platformCode }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { data, refetch, ...fetchPlatformFeedback } = useQuery({
        queryKey: ['platform_by_alias', { alias: platformCode }],
        enabled: !!platformCode,
        queryFn: getPlatformByCode,
        onError: (e) => {
            enqueueSnackbar(e.message || 'Unable to fetch platform', {
                variant: 'error',
                action: (key) => (
                    <IconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </IconButton>
                )
            });
        }
    });

    const platformData = data?.data?.data || {};

    return {
        platform: {
            ...platformData,
            code: platformData.alias?.toLowerCase()
        },
        platformLoading: fetchPlatformFeedback.isLoading,
        platformError: fetchPlatformFeedback.error,
        refetchPlatform: refetch
    };
}
