import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
    Typography, Button, Box, Card, CardContent,
    CardHeader,
    ListItem,
    ListItemAvatar,
    List,
    Avatar,
    ListItemText
} from '@mui/material';
import usePlatformUsers from '../../../hooks/usePlatformUsers';
import { PlatformUserStatus, Roles } from '../../../utils/constant';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.lighter,
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    [theme.breakpoints.up('xl')]: { height: 320 }
}));

// ----------------------------------------------------------------------

ActionsRequired.propTypes = {
    displayName: PropTypes.string
};

export default function ActionsRequired({ displayName }) {
    const { users } = usePlatformUsers({
        role: [Roles.SETTLEMENT_DWELLER],
        page: 1,
        page_size: 3,
        status: PlatformUserStatus.PENDING,
    });

    console.log("hgkjfhkfjhgkfhgf ", users);

    return (
        <>
            <CardHeader title="Action Required"/>
            <List>
                {users.map(({ user }) => (
                    <ListItem key={user.id} sx={{pl: 3}} button >
                        <ListItemAvatar>
                            <Avatar>
                                oa
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                        primaryTypographyProps={{
                            variant: "subtitle2"
                        }}
                            primary={user.firstname ? `${user.firstname} ${user.lastname}` : user.email}
                            secondary={!!user.username && user.username || "@username"}
                        />
                    </ListItem>
                ))}
            </List>
        </>
    );
}
