/* eslint-disable */
import { useMutation } from 'react-query';

import { createActivity } from '../services/activity';
import useAuth from './useAuth';

export default function useUpsertActivity({ platformId }) {
    const { mutate: createActivityMutation } = useMutation(createActivity);

    const {
        user: { settlements = [], id }
    } = useAuth();

    const activeSettlement = settlements.find(
        (settlement) => settlement.id === platformId
    );

    const handleUpsertActivity = ({ settlementId, ...activity }) => {
        createActivityMutation({
            "settlement_id": settlementId || platformId,
            "actor_user_id": id,
            "actor_community_user_id": activeSettlement?.community_user_id,
            ...activity
        });
    };

    return { onUpsertActivity: handleUpsertActivity };
}
