/* eslint-disable */
import { useMutation } from 'react-query';

import { updatePlatform, createPlatform } from '../services/platform';
import useGetPlatformById from './useGetPlatformById';

export default function useUpsertPlatform({ onSuccess, platformId }) {
    const { platform, refetchPlatform } = useGetPlatformById({ platformId })

    const {
        mutate: createPlatformMutation,
        ...createPlatformFeedback
    } = useMutation(createPlatform, {
        onSuccess: (data) => {
            // refetchPlatform();
            onSuccess?.(data.data);
        }
    });

    const {
        mutate: updatePlatformMutation,
        ...updatePlatformFeedback
    } = useMutation(updatePlatform, {
        onSuccess: (data) => {
            refetchPlatform();
            onSuccess?.(data.data);
        }
    });

    const handleUpsertPlatform = newPlatform => {
        if (!!platform.id) {
            updatePlatformMutation({
                data: newPlatform,
                platformId: platform.id
            });
            return;
        };
        createPlatformMutation(newPlatform);
    };

    const isLoading = updatePlatformFeedback.isLoading || createPlatformFeedback.isLoading;
    const error = updatePlatformFeedback.error || createPlatformFeedback.error;

    return {
        platform: platform || {},
        upsertPlatformLoading: isLoading,
        onUpsertPlatform: handleUpsertPlatform,
        upsertPlatformError: error
    };
}
