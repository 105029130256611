/* eslint-disable */
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useCallback, useEffect } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Box, Typography, Avatar, LinearProgress } from '@mui/material';

const DropZoneStyle = styled('div')(({ theme }) => ({

    backgroundColor: theme.palette.background.neutral,
    transition: theme.transitions.create('padding'),
    borderRadius: theme.shape.borderRadius,
    border: `1px dashed ${theme.palette.grey[500_32]}`,
    '&:hover': {
        opacity: 0.72,
        cursor: 'pointer'
    },
}));

UploadSingleFileWithAvatar.propTypes = {
    caption: PropTypes.string,
    error: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    sx: PropTypes.object
};

export default function UploadSingleFileWithAvatar({
    caption,
    error = false,
    value: file,
    onChange: setFile,
    sx,
    hideImage,
    uploadProgress,
    ...other
}) {
    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];

            if (file) {
                setFile({
                    ...file,
                    size: file.size,
                    type: file.type,
                    file,
                    preview: URL.createObjectURL(file)
                });
            }
        },
        [setFile]
    );

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject
    } = useDropzone({
        onDrop: handleDrop,
        multiple: false
    });

    useEffect(
        () => () => {
            if (file) {
                URL.revokeObjectURL(file.preview);
            }
        },
        [file]
    );

    return (
        <Box sx={{ width: '100%', ...sx }} {...other}>
            <DropZoneStyle
                {...getRootProps()}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...((isDragReject || error) && {
                        color: 'error.main',
                        borderColor: 'error.light',
                        bgcolor: 'error.lighter'
                    })
                }}
            >
                <input {...getInputProps()} />
                <Box sx={{
                    outline: 'none',
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                    p: 3,
                }}>
                    <Avatar
                        src={
                            typeof file === "string"
                                ? file
                                : file?.preview
                                    ? file.preview
                                    : "/static/illustrations/illustration_upload.svg"}
                        sx={{ height: 100, width: 100 }}
                    />


                    <Box sx={{ textAlign: hideImage && "center", ml: { sm: 2 } }}>
                        <Typography variant="h5">
                            Drop or select file
                        </Typography>


                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            Drop files here or click&nbsp;
                            <Typography
                                variant="caption"
                                component="span"
                                sx={{ color: 'primary.main' }}
                            >
                                browse
                            </Typography>
                            &nbsp;thorough your machine
                        </Typography>
                    </Box>
                </Box>
                {(file?.preview || typeof file === "string")  && (
                    <LinearProgress
                        variant="determinate"
                        color={uploadProgress >= 100 ? "secondary" : "primary"}
                        value={uploadProgress}
                    />
                )}
            </DropZoneStyle>
        </Box>
    );
}
