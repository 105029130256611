/* eslint-disable */
import {
  useTheme,
  styled
} from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Typography,
  Collapse,
  CardContent,
  Button,
  Box
} from '@mui/material';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import { Icon } from '@iconify/react';
import { useHistory, useLocation } from 'react-router-dom';

import useCurrentPlatformDetails from '../../../hooks/useCurrentPlatformDetails';
import { RegistrationStages } from '../../../utils/constant';
import useZones from '../../../hooks/useZones';
import { ROOTS_COMMUNITY } from 'src/routes/paths';
import { ACCOUNT_TAB_OPTIONS } from 'src/views/community/home/AccountSettings/Account';

const PlatformRegistrationStageItem = ({
  title,
  subtitle,
  isCompleted,
  href,
  btnText,
  isStart
}) => {
  const theme = useTheme();

  const renderActionBtn = () => (
    <Button
      disabled={!isStart}
      to={href}
      size="medium"
      variant="contained"
      color="inherit"
      component={RouterLink}
    >
      {btnText}
    </Button>
  );

  return (
    <CardAccordionStyle sx={{ opacity: isStart ? 1 : 0.7 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          display="flex"
          flex={1}
          sx={{
            [theme.breakpoints.up('sm')]: {
              mr: theme.spacing(2)
            }
          }}
        >
          <Box>
            <Icon
              icon={checkmarkCircle2Fill}
              style={{
                fontSize: theme.spacing(3),
                color: isCompleted
                  ? theme.palette.primary.main
                  : theme.palette.grey[500_48],
                marginTop: theme.spacing(1)
              }}
            />
          </Box>
          <Box ml={2}>
            <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
              {title}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: theme.palette.text.secondary }}
            >
              {subtitle}
            </Typography>
            {btnText.toLowerCase() !== "edit" && (
              <Box
                sx={{
                  mt: theme.spacing(2),
                  [theme.breakpoints.up('sm')]: {
                    display: 'none'
                  }
                }}
              >
                {renderActionBtn()}
              </Box>
            )}
          </Box>
        </Box>
        {btnText.toLowerCase() !== "edit" && (
          <Box
            sx={{
              [theme.breakpoints.down('sm')]: {
                display: 'none'
              }
            }}
          >
            {renderActionBtn()}
          </Box>
        )}
      </Box>
    </CardAccordionStyle>
  );
};

export default function PlatformRegistrationStage() {
  const { pathname } = useLocation();
  const history = useHistory();
  const { platform } = useCurrentPlatformDetails();
  const { zones } = useZones();

  return (
    <Card>
      <CardContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex"
            alignItems="center" sx={{ ml: -2 }}>
            <Box sx={{ ml: 2 }}>
              <Typography variant="h4">Important Next Steps</Typography>
              <Typography variant="subtitle2" sx={{ textTransform: "none" }}>
                Finish setting up your community
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
      <Collapse in>
        {getStages({
          pathname,
          history,
          platform,
          zones,
          handleOpenZoneModal: () => setOpenUpsertZone(true),
          handleOpenStreetModal: () => setOpenUpsertStreet(true)
        }).map((stage, index) => (
          <PlatformRegistrationStageItem
            key={index}
            title={stage.title}
            index={index}
            subtitle={stage.subtitle}
            href={stage.href}
            isCompleted={stage.isCompleted}
            isStart={stage.isStart}
            btnText={stage.isCompleted ? 'Edit' : stage.btnText}
          />
        ))}
      </Collapse>
    </Card>
  );
}

const getStages = ({ pathname, history, platform, zones }) => [
  {
    title: 'Create community',
    isCompleted: platform.prerequisite_stages?.includes(
      RegistrationStages.BASIC_INFORMATION
    ),
    isStart: true,

    key: RegistrationStages.BASIC_INFORMATION,
    btnText: platform.name ? 'Continue' : 'Start',
    href: `${pathname}/create?communityCode=${platform.alias}`,
    subtitle:
      'What should people know about your community, and how would you like to be contacted'
  },
  {
    title: 'Manage your community',
    btnText: !platform.prerequisite_stages?.includes(
      RegistrationStages.BASIC_INFORMATION
    )
      ? 'Start'
      : !zones.length
        ? 'Create zone'
        : 'Create street',
    isCompleted: platform.prerequisite_stages?.includes(
      RegistrationStages.MANAGEMENT
    ),
    isStart: platform.prerequisite_stages?.includes(
      RegistrationStages.BASIC_INFORMATION
    ),
    href: !zones.length 
        ? `${ROOTS_COMMUNITY}/${(platform.alias)}/admin/management/zones?create=zone`
        :`${ROOTS_COMMUNITY}/${(platform.alias)}/admin/management/zones/${zones[0].id}?create=street`,
    subtitle: 'Create zones and streets in your community'
  },
  {
    title: 'Fee and service charges',
    btnText: 'Start',
    isCompleted: platform.prerequisite_stages?.includes(
      RegistrationStages.PAYMENT
    ),
    isStart: platform.prerequisite_stages?.includes(
      RegistrationStages.MANAGEMENT
    ),
    href: `${ROOTS_COMMUNITY}/${(platform.alias)}/admin/management/account?tab=${ACCOUNT_TAB_OPTIONS.SERVICE_CHARGES}`,
    subtitle: 'Add service charges, necessary for sustaining your community.'
  },
  {
    title: 'Invite members',
    isCompleted: platform.prerequisite_stages?.includes(
      RegistrationStages.ADD_RESIDENTS
    ),
    isStart: platform.prerequisite_stages?.includes(RegistrationStages.PAYMENT),
    btnText: 'Start',
    href: `${pathname}/add-dweller`,
    subtitle: 'Invite members to the community and start engaging'
  }
];

const CardAccordionStyle = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  '&:not(:last-child)': {
    borderBottom: `solid 1px ${theme.palette.grey[500_16]}`
  }
}));
