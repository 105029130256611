import { Icon } from '@iconify/react';
import { capitalCase } from 'change-case';
import { useState, useEffect } from 'react';
import roundReceipt from '@iconify/icons-ic/round-receipt';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
import editNoteIcon from '@iconify/icons-ic/sharp-edit-note';
import { useLocation } from 'react-router-dom';
// material
import { Container, Tab, Box, Tabs, Typography } from '@mui/material';
// components
import Page from '../../../../components/Page';
import {
  AccountGeneral,
  AccountBilling
} from '../../../../components/platform-management/account';
import CustomFormField from '../../../../components/platform-management/account/CustomFormField';
import CustomNavbar from '../CustomNavbar';
import FullWidthCustomNavBar from '../FullWidthCustomNavBar';

export const ACCOUNT_TAB_OPTIONS = {
  GENERAL: "general",
  SERVICE_CHARGES: "charges",
  FORMS: "forms"
}

export default function UserAccount() {
  const { search } = useLocation();
  const [currentTab, setCurrentTab] = useState(ACCOUNT_TAB_OPTIONS.GENERAL);

  const urlParams = new URLSearchParams(search);
  const _tab = urlParams.get('tab');

  const ACCOUNT_TABS = [
    {
      value: 'general',
      id: ACCOUNT_TAB_OPTIONS.GENERAL,
      icon: <Icon icon={roundAccountBox} width={20} height={20} />,
      component: <AccountGeneral />
    },
    {
      value: 'Promotion charge',
      id: ACCOUNT_TAB_OPTIONS.SERVICE_CHARGES,
      icon: <Icon icon={roundReceipt} width={20} height={20} />,
      component: <AccountBilling />
    },
    {
      value: 'Forms',
      id: ACCOUNT_TAB_OPTIONS.FORMS,
      icon: <Icon icon={editNoteIcon} width={20} height={20} />,
      component: <CustomFormField />
    }
  ];

  useEffect(() => {
    if (_tab)
      setCurrentTab(_tab);
    // eslint-disable-next-line
  }, []);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Page title="Account Settings | communely">
  
      <Container>
        <Box sx={{minHeight: 84}} />
      <FullWidthCustomNavBar />
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.id}
              label={
                <Box display="flex" alignItems="center">
                  {tab.icon}
                  <Typography variant="subtitle2" ml={.5}>{capitalCase(tab.value)}</Typography>
                </Box>
              }
              value={tab.id}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.id === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
