import PropTypes from 'prop-types';
import { useEffect } from 'react';
// material
import {
  Box,
  Grid,
  Button,
  Collapse,
  TextField,
  InputAdornment,
  IconButton
} from '@mui/material';
import { useMutation } from 'react-query';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';

import { addServiceCharge, updateServiceCharge } from '../../../services/platform';
import useCurrentPlatformDetails from '../../../hooks/useCurrentPlatformDetails';

AddUtilityOption.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
};

const defaultValues = {
  name: "",
  value: ""
}

export default function AddUtilityOption({
  isOpen,
  onCancel,
  onSuccess,
  serviceChargeToEdit, 
  chargesType
}) {
  const { platform } = useCurrentPlatformDetails();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { handleSubmit, control, reset } = useForm(
    {
      defaultValues
    }
  );

  useEffect(() => {
    if (serviceChargeToEdit?.id) {
      reset(serviceChargeToEdit);
    }
    // eslint-disable-next-line
  }, [serviceChargeToEdit])

  const {
    mutate,
    isLoading,
  } = useMutation(addServiceCharge, {
    onSuccess: (data) => {
      onSuccess(data);
      reset(defaultValues);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 10000,
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    }
  });

  const {
    mutate: updateMutation,
    isLoading: updateLoading,
  } = useMutation(updateServiceCharge, {
    onSuccess: (data) => {
      onSuccess(data);
      reset(defaultValues);

    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 10000,
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </IconButton>
        )
      });
    }
  });

  const onSubmit = (values) => {
    if (serviceChargeToEdit?.id) {
      updateMutation({
        chargeId: serviceChargeToEdit?.id,
        name: values.name,
        value: values.value
      })
      return;
    }
    mutate({ 
      ...values, 
      type: chargesType, 
      settlement_id: platform.id 
    })
  };

  return (
    <Collapse in={isOpen}>
      <Box
        sx={{
          padding: 3,
          marginTop: 3,
          borderRadius: 1,
          bgcolor: 'background.neutral'
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} sx={{ mt: 1 }}>     <Controller
              name="name"
              control={control}
              rules={{
                required: { value: true, message: 'Utility name is required' }
              }}
              render={({ field: { ...fields }, fieldState: { error } }) => (
                <TextField {...fields} autoFocus={isOpen} size="small" fullWidth label="Utility" error={Boolean(error?.message)}
                  helperText={error?.message} />
              )}
            />
            </Grid>

            <Grid item xs={12} md={4} sx={{ mt: 1 }}> <Controller
              name="value"
              control={control}
              rules={{
                required: { value: true, message: 'Amount is required' }
              }}
              render={({ field: { value, ...fields }, fieldState: { error } }) => (
                <TextField {...fields} value={(value)} size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">&#8358;</InputAdornment>
                    )
                  }}
                  fullWidth
                  label="Cost of utility"
                  error={Boolean(error?.message)}
                  helperText={error?.message} />
              )}
            />
            </Grid>


          </Grid>

          <Box
            sx={{
              mt: 3,
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              type="button"
              color="inherit"
              variant="outlined"
              onClick={() => {
                reset(defaultValues);
                onCancel()
              }}
              sx={{ mr: 1.5 }}
            >
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isLoading || updateLoading}>
              Save
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Collapse >
  );
}
