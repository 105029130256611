export const isDevelopmentEnv = process.env.NODE_ENV === 'development';

export const PlatformTypes = {
  ESTATE: 'estate'
};

export const ChargesTypes = {
  SERVICE: 'service',
  PROMOTION: 'promotion'
};

export const ActivitiesScope = {
  SETTLEMENT: 'settlement',
  BUSINESS: 'business'
};

export const ActivitiesType = {
  ADD_HOUSEHOLD: 'add_household',
  REMOVE_HOUSEHOLD: 'remove_household',

  DWELLER_INVITE: 'dweller_invite',
  ADMIN_INVITE: 'admin_invite',
  SECURITY_INVITE: 'security_invite',
  CREATE_SETTLEMENT: 'create_settlement',
  SETTLEMENT_REVIEW: 'settlement_review',
  SETTLEMENT_APPROVE: 'settlement_approve',


  APPROVE_USER: 'approve_user',
  DECLINE_USER: 'decline_user',
  SUSPEND_USER: 'suspend_user',
  RESTORE_USER: 'restore_user',
  CANCEL_INVITE: 'cancel_invite',
  ADD_USER_ZONE: 'add_user_zone',
  ADD_USER_STREET: 'add_user_street',
};

export const SettlementBusinessStatus = {
  MAKE_PAYMENT: 'make_payment',
  DECLINED: "declined",
  PENDING: 'pending',
  ACTIVE: "active",
  IN_ACTIVE: 'in_active',
};

export const SettlementBusinessPermissionStatus = {
  ALLOW: 'allow',
  DISALLOW: 'disallow'
};

export const BusinessStatus = {
  ACTIVE: 'active',
  IN_ACTIVE: 'in_active',
  PENDING: 'pending',
};

export const BusinessAdminStatus = {
  ACTIVE: 'active',
  IN_ACTIVE: 'in_active',
  PENDING: 'pending',
};

export const PlatformStatus = {
  ACTIVE: 'active',
  IN_ACTIVE: 'in_active',
  REVIEW: 'review',
  PENDING: 'pending',
  DECLINE: 'decline',
  SUSPEND: 'suspend'
};

export const PlatformUserStatus = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  IN_ACTIVE: 'INACTIVE'
};

export const PaymentStatus = {
  DUE: 'due',
  NOT_DUE: 'not_due',
  PAID: 'paid'
};

export const Roles = {
  SUPER_ADMIN: 'superadmin',
  SETTLEMENT_OWNER: 'settlement_owner',
  SETTLEMENT_ADMIN: 'ADMIN',
  SETTLEMENT_SECURITY: 'settlement_security',
  SETTLEMENT_DWELLER: 'MEMBER',
  SETTLEMENT_HOUSEHOLD: 'settlement_household',


  BUSINESS_ADMIN: 'business_admin',
};

export const formatedRoles = {
  [Roles.SUPER_ADMIN]: {
    name: 'Super admin',
    description: 'Only for communely',
    role: Roles.SUPER_ADMIN
  },
  [Roles.SETTLEMENT_OWNER]: {
    isAdmin: true,
    accessAdminDashboard: true,
    name: 'Admin',
    description:
      'This role spear heads the activities in the estate, and have unlimited permissions within the estate',
    role: Roles.SETTLEMENT_OWNER
  },
  [Roles.SETTLEMENT_ADMIN]: {
    isAdmin: true,
    accessAdminDashboard: true,
    name: 'Admin',
    pronoun: "an",
    description:
      'This role oversee activities within the estate, and have as much permissions as the Owner',
    role: Roles.SETTLEMENT_ADMIN
  },
  [Roles.SETTLEMENT_SECURITY]: {
    // isAdmin: true,
    accessAdminDashboard: false,
    name: 'Security',
    description: 'This role secures the estate',
    role: Roles.SETTLEMENT_SECURITY
  },
  [Roles.SETTLEMENT_DWELLER]: {
    isDweller: true,
    name: 'Member',
    role: Roles.SETTLEMENT_DWELLER
  },
};

export const RegistrationStages = {
  BASIC_INFORMATION: 'BASIC_INFORMATION',
  MANAGEMENT: 'MANAGEMENT',
  PAYMENT: 'PAYMENT',
  ADD_RESIDENTS: 'ADD_RESIDENTS'
};

const personelKey = Date.now();
export const defaultPlatformFormValues = {
  // personels: { [personelKey]: { key: personelKey, role: '', email: '' } },
  // bank: null,
  name: '',
  // account_number: '',
  alias: '',
  state: '',
  lga: '',
  direction: '',
  phone_number: '',
  description: '',
  status: '',
  promotion:''
};

export const defaultBusinessFormValues = {
  bank: null,
  name: '',
  account_number: '',
  type: '',
  state: '',
  lga: '',
  email: '',
  address: '',
  phone_number: '',
  tags: [],
  description: '',
  display_image: '',
  logo: '',
  category: '',
  whatsapp_number: '',
};

export const BusinessOfferStatus = {
  AVAILABLE: "available",
  UNAVAILABLE: "UNavailable"
};

export const defaultOfferFormValues = {
  name: "",
  description: "",
  display_image: "",
  price: "",
  duration: ""
};

export const durationPer ={
  kg: {
    name: "kg",
    value: "kg"
  },  
  once: {
    name: "One time",
    value: "once"
  },
  loaf: {
    name: "loaf",
    value: "loaf"
  },
  person: {
    name: "person",
    value: "person"
  },
  hour: {
    name: "hour",
    value: "hour"
  },
  day: {
    name: "day",
    value: "day"
  },
  week: {
    name: "week",
    value: "week"
  },
  month: {
    name: "month",
    value: "month"
  }
}