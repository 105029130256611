/* eslint-disable */
import { useQuery } from 'react-query';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import { IconButton } from '@mui/material';

import { getBusinessById } from '../services/business';

export default function useBusiness({ businessId }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { data: { data } = {}, refetch, ...fetchBusinessFeedback } = useQuery({
        queryKey: ['business', { businessId }],
        enabled: !!businessId,
        queryFn: getBusinessById,
        onError: (e) => {
            enqueueSnackbar(e.message || 'Unable to fetch business', {
                variant: 'error',
                action: (key) => (
                    <IconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </IconButton>
                )
            });
        }
    });

    const businessData = data || {};

    return {
        business: businessData,
        businessLoading: fetchBusinessFeedback.isLoading,
        businessError: fetchBusinessFeedback.error,
        refetchBusiness: refetch
    };
}
