import PropTypes from 'prop-types';
import { useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { TextField, InputAdornment, Grid } from '@mui/material';

import { stateAndLga } from '../../../utils/stateAndLga';
import CreateWrapper from '../CreateWrapper';
import useUpsertPlatform from '../../../hooks/useUpsertPlatform';
import { getPlatformFormResetFields } from '../../../utils/createFormUtils';
import { defaultPlatformFormValues } from '../../../utils/constant';

ContactInformation.propTypes = {
  onSuccessAction: PropTypes.func
};

export default function ContactInformation({
  noBackBtn,
  actionBtnText,
  onBackStep,
  description,
  onRegistrationComplete,
  communityId
}) {
  const { control, handleSubmit, watch, reset } = useForm({ defaultValues: defaultPlatformFormValues });
  const formValues = watch();
  const {
    onUpsertPlatform,
    platform,
    upsertPlatformError,
    upsertPlatformLoading
  } = useUpsertPlatform({
    onSuccess: (data) => {
      onRegistrationComplete(data);
    },
    platformId: communityId
  });

  useEffect(() => {
    reset(getPlatformFormResetFields(platform))
  }, [platform?.alias]);

  const onSubmit = (values) => {
    onUpsertPlatform(values);
  };

  return (
    <CreateWrapper
      error={upsertPlatformError}
      isLoading={upsertPlatformLoading}
      description={description}
      noBackBtn={noBackBtn}
      onBackStep={onBackStep}
      actionBtnText={actionBtnText}
      actionBtnAction={handleSubmit(onSubmit)}
    >
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="state"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Which state is your community located'
              }
            }}
            render={({ field: { ref, ...fields }, fieldState: { error } }) => (
              <TextField
                {...fields}
                select
                fullWidth
                label="State within Nigeria"
                SelectProps={{ native: true }}
                inputRef={ref}
                error={Boolean(error?.message)}
                helperText={error?.message}
              >
                <option value="" />
                {Object.keys(stateAndLga).map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="lga"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Community Lga is required'
              }
            }}
            render={({ field: { ref, ...fields }, fieldState: { error } }) => (
              <TextField
                {...fields}
                select
                fullWidth
                label="Select your LGA"
                SelectProps={{ native: true }}
                inputRef={ref}
                error={Boolean(error?.message)}
                helperText={error?.message}
              >
                <option value="" />
                {stateAndLga[formValues.state]?.locals?.map(({ name }) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </TextField>
            )}
          />
        </Grid>
      </Grid>
      <Controller
        name="phone_number"
        control={control}
        rules={{
          required: 'A phone number to contact the community is needed'
        }}
        render={({ field: { ref, ...fields }, fieldState: { error } }) => (
          <TextField
            {...fields}
            fullWidth
            label="Community phone number"
            placeholder="80 1234 5678"
            inputRef={ref}
            sx={{ mb: 3 }}
            error={Boolean(error?.message)}
            helperText={error?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+234</InputAdornment>
              )
            }}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        rules={{
          required: { value: true, message: 'Email address is required' },
          pattern: {
            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: 'Provide a valid email address'
          }
        }}
        render={({ field: { ref, ...fields }, fieldState: { error } }) => (
          <TextField
            {...fields}
            fullWidth
            label="Community email address"
            placeholder="community.mail@mail.com"
            inputRef={ref}
            sx={{ mb: 3 }}
            error={Boolean(error?.message)}
            helperText={error?.message}
          />
        )}
      />
      <Controller
        name="address"
        control={control}
        render={({ field: { ref, ...fields } }) => (
          <TextField
            {...fields}
            multiline
            minRows={4}
            minLength={600}
            fullWidth
            label="Community address"
            inputRef={ref}
            sx={{ mb: 3 }}
          />
        )}
      />
    </CreateWrapper >
  );
}
