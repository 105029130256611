// import { useCallback } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// // redux
// import { switchMode, switchDirection } from '../redux/slices/settings';

// ----------------------------------------------------------------------

export default function useSettings() {
  // const dispatch = useDispatch();
  // const { themeMode, themeDirection } = useSelector((state) => state.settings);
  // const isLight = true; // themeMode === 'light';

  const handleToggleTheme = () => null;

  const handleChangeTheme = () => null;

  const handleChangeDirection = () => null;

  return {
    // Mode
    themeMode: "light",
    toggleMode: handleToggleTheme,
    selectMode: handleChangeTheme,
    // Direction
    themeDirection: "ltr",
    selectDirection: handleChangeDirection
  };
}
