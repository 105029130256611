// ----------------------------------------------------------------------

export default function Container(theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
          }
        }
      }
    }
  };
}
