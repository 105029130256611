import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// layouts
import DocsLayout from '../layouts/backOffice';
import { PATH_BACKOFFICE } from './paths';

// ----------------------------------------------------------------------

const DocsRoutes = {
  path: PATH_BACKOFFICE.root,
  layout: DocsLayout,
  exact: false,
  routes: [
    // GETTING STARTED
    {
      exact: true,
      path: PATH_BACKOFFICE.overview,
      component: lazy(() => import('../views/back-office/overview'))
    },
    {
      exact: true,
      path: PATH_BACKOFFICE.users,
      component: lazy(() => import('../views/back-office/users'))
    },
    {
      exact: true,
      path: PATH_BACKOFFICE.businesses,
      component: lazy(() => import('../views/back-office/businesses'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default DocsRoutes;
