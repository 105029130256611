/* eslint-disable */
import { useEffect } from "react";
import { useQuery } from 'react-query';
import { Stack, Box, Typography, Divider, Button, CircularProgress } from '@mui/material';
import { Controller } from "react-hook-form";
import moment from "moment";

import { fieldTypes } from './constants';
import {
    InputShortAnswer,
    InputLongAnswer,
    InputSingleAnswer,
    InputMultipleAnswers,
} from './FillFormInputField';
import { getPlatformCustomForm } from 'src/services/platform';
import Label from '../Label';
import { formatedRoles } from 'src/utils/constant';

const components = {
    [fieldTypes.SHORT_ANSWER]: InputShortAnswer,
    [fieldTypes.LONG_ANSWER]: InputLongAnswer,
    [fieldTypes.SINGLE_ANSWER]: InputSingleAnswer,
    [fieldTypes.MULTIPLE_ANSWER]: InputMultipleAnswers,
};

const FillFormField = ({
    formId,
    useFormUtils,
    onEdit,
    refetchForm,
    onRefetchForm,
    onDelete
}) => {
    const { control } = useFormUtils;;
    const { data: { data } = {}, refetch, isLoading } = useQuery({
        queryKey: ['platformCustomForm', { formId }],
        queryFn: getPlatformCustomForm,
        onSuccess: () => {
            onRefetchForm?.()
        }
    });

    useEffect(() => {
        if (refetchForm) {
            refetch()
        }
    }, [refetchForm])

    return isLoading ? (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 200,
                h: 1
            }}>
            <CircularProgress />
        </Box>
    ) : (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column"
            }}>
            <Stack sx={{ flex: 1 }} spacing={3}>
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Box>
                        <Typography variant="h6">{data?.name}</Typography>
                        <Typography variant="caption" color="text.secondary">{moment(data?.inserted_at).format("MMM Do, h:mma")}</Typography>
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={() => onEdit(data)}
                        >Edit form</Button>
                    </Box>
                </Box>
                <Divider />
                <Box>
                <Label color={data?.status === "PUBLISHED" ? "success" : undefined} style={{ textTransform: "capitalize" }}>{data?.status?.toLowerCase()}</Label>
                </Box>

                {data?.form_fields?.map(field => {
                    const Component = components[field.type];
                    return (
                        <Controller
                            name={field.id}
                            control={control}
                            rules={{
                                required: {
                                    value: field.is_required === "yes",
                                    message: "This field is required"
                                }
                            }}
                            render={({
                                field: { value, onChange },
                                fieldState: { error }
                            }) => (
                                <Component
                                    field={field}
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                />
                            )}
                        />
                    )
                })}
            </Stack>
            <Box mt={3}>
                <Button
                    color="error"
                    onClick={onDelete}
                    variant="outlined">
                    Delete
                </Button>
            </Box>
        </Box>
    );
};

export default FillFormField;
